import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import {
  listShipping,
  activatePause,
  massiveActivate,
  massivePause,
  massiveIncrease,
  changeDeliveryTime,
} from "../cruds/shipping.crud";

export const actionTypes = {
  LIST_SHIPPING: "LIST_SHIPPING",
  LIST_SHIPPING_WITH_RESULT: "LIST_SHIPPING_WITH_RESULT",
  FILL_SHIPPING_LIST: "FILL_SHIPPING_LIST",
  FILL_SHIPPING_PAGINATION_DATA: "FILL_SHIPPING_PAGINATION_DATA",
  ACTIVATE_PAUSE: "ACTIVATE_PAUSE",
  MASSIVE_PAUSE: "MASSIVE_PAUSE",
  MASSIVE_ACTIVATE: "MASSIVE_ACTIVATE",
  MASSIVE_INCREASE: "MASSIVE_INCREASE",
  CHANGE_DELIVERY_TIME: "CHANGE_DELIVERY_TIME",
  SET_RESULT: "SET_RESULT",
  SET_DELIVERY_TIME_RESULT: "SET_DELIVERY_TIME_RESULT",
  LIST_DETAIL_SHIPPING: "LIST_DETAIL_SHIPPING",
  FILL_LIST_DETAIL_SHIPPING: "FILL_LIST_DETAIL_SHIPPING",
};

const initialState = {
  shipping: [],
  loading: false,
  shippingPagination: {
    currentPage: 1,
    perPage: 20,
    totalPages: 0,
    totalRows: 0,
  },
  result: null,
  deliveryTimeResult: null,
  activatePauseLoading: false,
};

export const reducer = persistReducer(
  { storage, key: "demo1-orders", whitelist: ["asd"] },
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.LIST_SHIPPING: {
        return {
          ...state,
          loading: true,
        };
      }
      case actionTypes.LIST_SHIPPING_WITH_RESULT: {
        return {
          ...state,
          loading: false,
        };
      }

      case actionTypes.FILL_SHIPPING_LIST: {
        return {
          ...state,
          shipping: action.payload,
          loading: false,
        };
      }

      case actionTypes.FILL_SHIPPING_PAGINATION_DATA: {
        return {
          ...state,
          shippingPagination: action.payload,
          loading: false,
        };
      }

      case actionTypes.ACTIVATE_PAUSE: {
        return {
          ...state,
          activatePauseLoading: true,
        };
      }

      case actionTypes.MASSIVE_PAUSE: {
        return {
          ...state,
          loading: false,
        };
      }
      case actionTypes.MASSIVE_ACTIVATE: {
        return {
          ...state,
          loading: false,
        };
      }
      case actionTypes.MASSIVE_INCREASE: {
        return {
          ...state,
          loading: false,
        };
      }
      case actionTypes.CHANGE_DELIVERY_TIME: {
        return {
          ...state,
          loading: false,
        };
      }
      case actionTypes.SET_RESULT: {
        return {
          ...state,
          result: action.payload,
          loading: false,
        };
      }

      case actionTypes.SET_DELIVERY_TIME_RESULT: {
        return {
          ...state,
          deliveryTimeResult: action.payload,
          loading: false,
        };
      }

      case actionTypes.LIST_DETAIL_SHIPPING: {
        return {
          ...state,
          loading: true,
        };
      }

      case actionTypes.FILL_LIST_DETAIL_SHIPPING: {
        return {
          ...state,
          shipping: action.payload,
          loading: false,
        };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  listShipping: (value) => ({
    type: actionTypes.LIST_SHIPPING,
    payload: value,
  }),
  listShippingWithResult: (value) => ({
    type: actionTypes.LIST_SHIPPING_WITH_RESULT,
    payload: value,
  }),
  fillShippingList: (value) => ({
    type: actionTypes.FILL_SHIPPING_LIST,
    payload: value,
  }),
  fillShippingPaginationData: (value) => ({
    type: actionTypes.FILL_SHIPPING_PAGINATION_DATA,
    payload: value,
  }),
  activatePause: (value) => ({
    type: actionTypes.ACTIVATE_PAUSE,
    payload: value,
  }),
  massivePause: (value) => ({
    type: actionTypes.MASSIVE_PAUSE,
    payload: value,
  }),
  massiveActivate: (value) => ({
    type: actionTypes.MASSIVE_ACTIVATE,
    payload: value,
  }),
  massiveIncrease: (value) => ({
    type: actionTypes.MASSIVE_INCREASE,
    payload: value,
  }),
  changeDeliveryTime: (value) => ({
    type: actionTypes.CHANGE_DELIVERY_TIME,
    payload: value,
  }),
  result: (value) => ({
    type: actionTypes.SET_RESULT,
    payload: value,
  }),
  setDeliveryTimeResult: (value) => ({
    type: actionTypes.SET_DELIVERY_TIME_RESULT,
    payload: value,
  }),
  listDetailShipping: (value) => ({
    type: actionTypes.LIST_DETAIL_SHIPPING,
    payload: value,
  }),
  fillListDetailShipping: (value) => ({
    type: actionTypes.FILL_LIST_DETAIL_SHIPPING,
    payload: value,
  }),
};

export const getState = (state) => state;

export function* saga() {
  yield takeLatest(actionTypes.LIST_SHIPPING, function* listShippingSaga({
    payload,
  }) {
    try {
      const response = yield listShipping(payload);

      const pagination = response.data.message.pagination || {};
      const data = response.data.message;

      if (response.status === 200) {
        yield put(actions.result(false));
        yield put(actions.fillShippingList(data));
        yield put(actions.fillShippingPaginationData(pagination));
      }
    } catch (err) {
      console.log(err);
      yield put(actions.result(false));
    }
  });

  yield takeLatest(
    actionTypes.LIST_SHIPPING_WITH_RESULT,
    function* listShippingWithResultSaga({ payload }) {
      try {
        const response = yield listShipping(payload);

        const pagination = response.data.message.pagination || {};
        const data = response.data.message;

        if (response.status === 200) {
          yield put(actions.setDeliveryTimeResult(false));
          yield put(actions.fillShippingList(data));
          yield put(actions.fillShippingPaginationData(pagination));
        }
      } catch (err) {
        console.log(err);
        yield put(actions.setDeliveryTimeResult(false));
      }
    }
  );

  yield takeLatest(actionTypes.ACTIVATE_PAUSE, function* activatePauseSaga({
    payload,
  }) {
    try {
      const { data } = yield activatePause(payload);
      if (data.code === 200) {
        yield put(actions.result(true));
      } else {
        yield put(actions.result(false));
      }
    } catch (e) {
      yield put(actions.result(false));
    }
  });

  yield takeLatest(actionTypes.MASSIVE_ACTIVATE, function* massiveActivateSaga({
    payload,
  }) {
    try {
      const { data } = yield massiveActivate(payload);
      if (data.code === 200) {
        yield put(actions.result(true));
      } else {
        yield put(actions.result(false));
      }
    } catch (e) {
      yield put(actions.result(false));
    }
  });
  yield takeLatest(actionTypes.MASSIVE_PAUSE, function* massivePauseSaga({
    payload,
  }) {
    try {
      const { data } = yield massivePause(payload);
      if (data.code === 200) {
        yield put(actions.result(true));
      } else {
        yield put(actions.result(false));
      }
    } catch (e) {
      yield put(actions.result(false));
    }
  });
  yield takeLatest(actionTypes.MASSIVE_INCREASE, function* massiveIncreaseSaga({
    payload,
  }) {
    try {
      const { data } = yield massiveIncrease(payload);
      if (data.code === 200) {
        yield put(actions.result(true));
      } else {
        yield put(actions.result(false));
      }
    } catch (e) {
      yield put(actions.result(false));
    }
  });
  yield takeLatest(
    actionTypes.CHANGE_DELIVERY_TIME,
    function* changeDeliveryTimeSaga({ payload }) {
      try {
        const { data } = yield changeDeliveryTime(payload);
        if (data.code === 200) {
          yield put(actions.setDeliveryTimeResult(data.message.id));
        } else {
          yield put(actions.setDeliveryTimeResult(false));
        }
      } catch (e) {
        yield put(actions.setDeliveryTimeResult(false));
      }
    }
  );
}
