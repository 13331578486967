import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";
import { BekeuIcon } from "../BekeuIcon/BekeuIcon";
import rejected from "../../design-system/icons/Shipping/close-circle.svg";
import messageRemove from "../../design-system/icons/Shipping/message-remove.svg";
import tickCircle from "../../design-system/icons/Shipping/tick-circle.svg";
import truckTick from "../../design-system/icons/Shipping/truck-tick.svg";
import { MyOrdersClaimModal } from "../MyOrdersClaimModal/MyOrdersClaimModal";
import { MyOrdersConfirmRejectModal } from "../MyOrdersConfirmRejectModal/MyOrdersConfirmRejectModal";
import moment from "moment";
import { useHistory } from "react-router";
import { HistoryItemModal } from "../HistoryItemModal/HistoryItemModal";

export const HistoryItem = ({
  msg,
  handleConfirmOrderCart,
  handleClaimOrderCart,
  handlePagination,
  orderItem,
  setClaim,
  claim,
  product,
  providerName,
  newDeliveryArray,
  setFileNameInput,
  fileNameInput,
  sapAvailable,
  dispatchNoteAvailable,
  ...props
}) => {
  const history = useHistory();

  return (
    <Flex px="24px" {...props}>
      <Box
        borderTopRadius={"12px"}
        borderWidth="1px"
        borderColor={"rgba(189, 189, 189, 0.5)"}
        h="94px"
        w="100%"
        boxShadow={"lg"}
        mb="-12px"
        background={"white"}
      >
        <Flex alignItems={"center"} justifyContent={"space-between"} p="24px">
          <Flex alignItems={"center"} gap="16px">
            <Flex
              w="44px"
              h="44px"
              background={"brand.background"}
              borderRadius="10px"
              justifyContent={"center"}
              alignItems="center"
            >
              <BekeuIcon
                src={
                  msg?.state === 1
                    ? rejected
                    : msg?.state === 2
                    ? messageRemove
                    : msg?.state === 3
                    ? tickCircle
                    : msg?.state === 4 && truckTick
                }
              />
            </Flex>
            <Text
              fontSize={"16px"}
              color="brand.contentSecondary"
              lineHeight={"22px"}
            >
              {moment(msg?.updated_at).format("DD/MM/YYYY")}
            </Text>
            <Text
              fontSize={"16px"}
              color="brand.contentPrimary"
              lineHeight={"22px"}
            >
              {history.location.pathname.includes("/my-orders/") ? (
                // copies comprador:
                msg?.state === 1 ? (
                  <Flex gap={1}>
                    Confirmaste la recepción de la compra correspondiente al nro
                    de remito
                    <HistoryItemModal
                      data={msg}
                      productsArr={newDeliveryArray?.days_delivery_time_block}
                    >
                      {msg?.dispatch_note_number}
                    </HistoryItemModal>
                  </Flex>
                ) : msg?.state === 2 ? (
                  `${providerName} canceló el pedido correspondiente al bloque de plazo máximo de entrega de ${msg?.delivery_days_block} días.`
                ) : msg?.state === 3 ? (
                  <Flex gap={1}>
                    {providerName} realizó una entrega con Nro de remito
                    <HistoryItemModal
                      data={msg}
                      productsArr={newDeliveryArray?.days_delivery_time_block}
                    >
                      {msg?.dispatch_note_number}
                    </HistoryItemModal>
                  </Flex>
                ) : (
                  msg?.state === 4 && (
                    <Flex gap={1}>
                      Reclamaste sobre la entrega correspondiente al Nro de
                      remito{" "}
                      <HistoryItemModal
                        data={msg}
                        productsArr={newDeliveryArray?.days_delivery_time_block}
                      >
                        {msg?.dispatch_note_number}
                      </HistoryItemModal>
                    </Flex>
                  )
                )
              ) : // copies vendedor:
              msg?.state === 1 ? (
                `El comprador confirmó la recepción de ${product?.combination?.product?.name}.`
              ) : msg?.state === 2 ? (
                `Cancelaste el pedido correspondiente al bloque de plazo máximo de entrega de ${msg?.delivery_days_block} días.`
              ) : msg?.state === 3 ? (
                <Flex gap={1}>
                  Enviaste {product?.combination?.product?.name} con el número
                  de remito
                  <HistoryItemModal
                    data={msg}
                    productsArr={newDeliveryArray?.days_delivery_time_block}
                  >
                    {msg?.dispatch_note_number}
                  </HistoryItemModal>
                </Flex>
              ) : (
                msg?.state === 4 && (
                  <Flex gap={1}>
                    El comprador reclamó la entrega correspondiente al Nro de
                    remito
                    <HistoryItemModal
                      data={msg}
                      productsArr={newDeliveryArray?.days_delivery_time_block}
                    >
                      {msg?.dispatch_note_number}
                    </HistoryItemModal>
                  </Flex>
                )
              )}
            </Text>
          </Flex>
          {msg?.state === 4 && props.myOrders && (
            <Flex alignItems={"center"} gap={2}>
              <Box
                color={"brand.warning"}
                background={"rgba(255, 152, 0, 0.2)"}
                padding={"8px"}
                px={"12px"}
                borderRadius={"12px"}
              >
                Reclamada
              </Box>
              {(orderItem?.state === "4" || orderItem?.state === "8" || orderItem?.state === "9") && (
                <MyOrdersConfirmRejectModal
                  orderItem={orderItem}
                  minW={"fit-content"}
                  handleConfirmOrderCart={handleConfirmOrderCart}
                  setFileNameInput={setFileNameInput}
                  handlePagination={handlePagination}
                  dispatchNoteNumber={msg?.dispatch_note_number}
                  fileNameInput={fileNameInput}
                  sapAvailable={sapAvailable}
                  dispatchNoteAvailable={dispatchNoteAvailable}
                >
                  Confirmar recepción
                </MyOrdersConfirmRejectModal>
              )}
            </Flex>
          )}
          {msg?.state === 3 && props.myOrders && (
            <Flex alignItems={"center"} gap={2}>
              {(orderItem?.state === "2" ||
                orderItem?.state === "4" ||
                orderItem?.state === "9") && (
                <MyOrdersClaimModal
                  minW={"fit-content"}
                  setClaim={setClaim}
                  claim={claim}
                  handleClaimOrderCart={handleClaimOrderCart}
                  handlePagination={handlePagination}
                  orderItem={orderItem}
                  dispatchNoteNumber={msg?.dispatch_note_number}
                >
                  Reclamar
                </MyOrdersClaimModal>
              )}

              {(orderItem?.state === "4" || orderItem?.state === "9") && (
                <MyOrdersConfirmRejectModal
                  orderItem={orderItem}
                  minW={"fit-content"}
                  handleConfirmOrderCart={handleConfirmOrderCart}
                  setFileNameInput={setFileNameInput}
                  fileNameInput={fileNameInput}
                  handlePagination={handlePagination}
                  dispatchNoteNumber={msg?.dispatch_note_number}
                  sapAvailable={sapAvailable}
                  dispatchNoteAvailable={dispatchNoteAvailable}
                >
                  Confirmar recepción
                </MyOrdersConfirmRejectModal>
              )}
            </Flex>
          )}
        </Flex>
      </Box>
    </Flex>
  );
};
