import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import {
  listCategories,
  createCategory,
  updateCategory,
  deleteCategory,
} from "../cruds/categories.crud";

export const actionTypes = {
  LIST_CATEGORIES: "LIST_CATEGORIES",
  CREATE_CATEGORY: "CREATE_CATEGORY",
  FILL_RESULT_CATEGORY: "FILL_RESULT_CATEGORY",
  LIST_PRODUCTSGRID: "LIST_PRODUCTSGRID",
  LIST_FILL_PRODUCTSGRID: "LIST_FILL_PRODUCTSGRID",
  GET_CATEGORY: "GET_CATEGORY",
  FILL_ONE_CATEGORY: "FILL_ONE_CATEGORY",
  FILL_LIST_CATEGORIES: "FILL_LIST_CATEGORIES",
  FILL_CATEGORIES_PAGINATION_DATA: "FILL_CATEGORIES_PAGINATION_DATA",
  UPDATE_CATEGORY: "UPDATE_CATEGORY",
  DELETE_CATEGORY: "DELETE_CATEGORY",
  FILL_PRODUCTS_GRID_PAGINATION_DATA: "FILL_PRODUCTS_GRID_PAGINATION_DATA",
};

const initialState = {
  categories: [
    {
      id: 1,
      category_name: "Electrodomésticos",
      sub_categories: [
        {
          id: 1,
          category_name: "Climatización",
          sub_category_items: [
            { id: 1, category_name: "Aires Acondicionados" },
            { id: 2, category_name: "Calefacción" },
            { id: 3, category_name: "Salamandras y Calefactores a Leña" },
            { id: 4, category_name: "Ventiladores" },
          ],
        },
        {
          id: 2,
          category_name: "Cocinas y Hornos",
          sub_category_items: [
            { id: 1, category_name: "Anafes" },
            { id: 2, category_name: "Cocinas" },
            { id: 3, category_name: "Extractores y Purificadores" },
            { id: 4, category_name: "Hornos" },
            { id: 5, category_name: "Microondas" },
          ],
        },
      ],
    },
    {
      id: 2,
      category_name: "Seguridad Industrial",
      sub_categories: [
        {
          id: 1,
          category_name: "Detección y Notificación",
          sub_category_items: [
            { id: 1, category_name: "Alarmas, Cámaras y Sensores" },
          ],
        },
        {
          id: 2,
          category_name: "Equipos contra Incendios",
          sub_category_items: [
            { id: 1, category_name: "Accesorios y Complementos" },
            { id: 2, category_name: "Extintores" },
          ],
        },
        {
          id: 3,
          category_name: "Indumentaria",
          sub_category_items: [
            { id: 1, category_name: "Calzado" },
            { id: 2, category_name: "Ropa de Seguridad" },
          ],
        },
      ],
    },
  ],
  category: null,
};

export const reducer = persistReducer(
  { storage, key: "categories", whitelist: ["categories"] },
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.CREATE_CATEGORY: {
        return {
          ...state,
          loading: true,
        };
      }
      case actionTypes.FILL_RESULT_CATEGORY: {
        return {
          ...state,
          loading: false,
          result: action.payload,
        };
      }
      case actionTypes.LIST_CATEGORIES: {
        return {
          ...state,
          loading: true,
        };
      }
      case actionTypes.FILL_LIST_CATEGORIES: {
        return {
          ...state,
          categories: action.payload,
          loading: false,
        };
      }
      case actionTypes.FILL_ONE_CATEGORY: {
        return {
          ...state,
          category: action.payload,
          loading: false,
        };
      }
      case actionTypes.UPDATE_CATEGORY: {
        return {
          ...state,
          loading: true,
        };
      }
      case actionTypes.DELETE_CATEGORY: {
        return {
          ...state,
          loading: true,
        };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  createCategory: (value) => ({
    type: actionTypes.CREATE_CATEGORY,
    payload: value,
  }),
  fillResultCategory: (value) => ({
    type: actionTypes.FILL_RESULT_CATEGORY,
    payload: value,
  }),
  listCategories: (value) => ({
    type: actionTypes.LIST_CATEGORIES,
    payload: value,
  }),
  getCategory: (value) => ({ type: actionTypes.GET_CATEGORY, payload: value }),
  fillOneCategory: (value) => ({
    type: actionTypes.FILL_ONE_CATEGORY,
    payload: value,
  }),
  fillListCategories: (value) => ({
    type: actionTypes.FILL_LIST_CATEGORIES,
    payload: value,
  }),
  updateCategory: (value) => ({
    type: actionTypes.UPDATE_CATEGORY,
    payload: value,
  }),
  deleteCategory: (value) => ({
    type: actionTypes.DELETE_CATEGORY,
    payload: value,
  }),
};

export const getState = (state) => state;

export function* saga() {
  //----------------------------------CATEGORIES------------------------------------------
  yield takeLatest(actionTypes.LIST_CATEGORIES, function* listCategoriesSaga({
    payload,
  }) {
    try {
      const data = yield listCategories(payload);
      if (data.status === 200) {
        yield put(actions.fillListCategories(data?.data));
      } else {
        yield put(actions.fillListCategories([]));
      }
    } catch (e) {
      console.log(e);
      yield put(actions.fillListCategories([]));
      // yield put(actions.fillListCategories("generalError"));
    }
  });

  yield takeLatest(actionTypes.CREATE_CATEGORY, function* createCategorySaga({
    payload,
  }) {
    try {
      const { data } = yield createCategory(payload);
      if (data.code === 201) {
        yield put(actions.fillResultCategory(true));
      } else {
        yield put(actions.fillResultCategory(false));
      }
    } catch (e) {
      yield put(actions.fillResultCategory(false));
    }
  });

  yield takeLatest(actionTypes.UPDATE_CATEGORY, function* updateCategorySaga({
    payload,
  }) {
    try {
      const { data } = yield updateCategory(payload);
      if (data.code === 200) {
        yield put(actions.fillResultCategory(true));
      } else {
        yield put(actions.fillResultCategory(false));
      }
    } catch (e) {
      yield put(actions.fillResultCategory(false));
    }
  });

  yield takeLatest(actionTypes.DELETE_CATEGORY, function* deleteCategorySaga({
    payload,
  }) {
    try {
      const data = yield deleteCategory(payload);
      if (data.status === 204) {
        yield put(actions.fillResultCategory(true));
      } else {
        yield put(actions.fillResultCategory(false));
      }
    } catch (e) {
      yield put(actions.fillResultCategory(false));
    }
  });
}
