import { Textarea } from "@chakra-ui/react";
import React from "react";

export const TextAreaBekeu = ({ ...props }) => (
  <Textarea
    p={4}
    fontWeight={300}
    fontFamily={"Open Sans"}
    fontSize={"1rem"}
    borderRadius={"0.938rem"}
    borderWidth={"1px"}
    borderColor={"brand.border"}
    isRequired
    focusBorderColor="brand.hover"
    {...props}
  />
);
