import React from "react";
import Logo from "../Logo/Logo";
import Searcher from "../Searcher/Searcher";
import MainMenu from "../MainMenu/MainMenu";
import MiniCarrito from "../MiniCarrito/MiniCarrito";
import "./Header.scss";
import { Flex } from "@chakra-ui/react";
import NewMenuCategories from "../MenuCategories/NewMenuCategories";
import Profile from "../Profile/Profile";
import { Link } from "react-router-dom";

const Header = ({ history, userType }) => {
  return (
    <Flex bg="brand.primary" justifyContent={"center"}>
      <Flex flexDir={"column"} w={"75rem"}>
        <Flex
          justifyContent={"space-between"}
          alignItems={"center"}
          pt={"24px"}
          pb={"16px"}
        >
          <Link to="/" _focus={{ boxShadow: "rgba(0,0,0,0)" }}>
            <Logo />
          </Link>
          {userType === "2" && <Searcher history={history} />}
          {userType === "2" && <MiniCarrito />}
        </Flex>
        <Flex alignItems={"center"} justifyContent="space-between" py={"16px"}>
          {userType === "2" && (
            <NewMenuCategories ml={3}>Categorías</NewMenuCategories>
          )}
          <MainMenu userType={userType} />
          <Profile history={history} userType={userType} />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Header;
