import {
  Flex,
  Img,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { ButtonBekeu } from "../../design-system/forms/ButtonBekeu";
import { H5 } from "../../design-system/headings/H5";
import success from "../../design-system/icons/Arrows/success-circle-fill.svg";
import { useHistory } from "react-router-dom";
import { TextAreaBekeu } from "../../design-system/inputs/TextAreaBekeu";
import { useSelector } from "react-redux";
import { ButtonBekeuLink } from "../../design-system/forms/ButtonBekeuLink";

const Confirmation = ({ handleConfirm, setClaim, loading, claim }) => (
  <Flex flexDir={"column"} alignItems={"center"} p={"48px"}>
    <ModalCloseButton _focus={{ boxShadow: "rgba(0,0,0,0)" }} />
    <H5 textAlign="center" py={2}>
      ¿Cuál es el motivo del reclamo?
    </H5>
    <TextAreaBekeu
      minH={"125px"}
      placeholder={"Comentanos el motivo del reclamo..."}
      onChange={(e) => setClaim(e.target.value)}
      maxLength={190}
      onKeyPress={(e) => {
        if (e.key === "Enter") {
          handleConfirm();
        }
      }}
    />
    <Flex alignItems={"center"} justifyContent={"space-between"}>
      <ButtonBekeu
        onClick={handleConfirm}
        isLoading={loading}
        isDisabled={!claim}
        px="80px"
      >
        Enviar
      </ButtonBekeu>
    </Flex>
  </Flex>
);
const UpdateChanges = ({ onClose, handleUpdateChanges }) => (
  <Flex flexDir={"column"} alignItems={"center"} p={"48px"}>
    <Img src={success} />
    <H5 textAlign="center" py={2}>
      Reclamo realizado con éxito
    </H5>
    <ButtonBekeu
      w={"75%"}
      mt={4}
      onClose={onClose}
      onClick={handleUpdateChanges}
    >
      Aceptar
    </ButtonBekeu>
  </Flex>
);

export const MyOrdersClaimModal = ({
  isDisabled,
  children,
  claim,
  setClaim,
  orderItem,
  handleClaimOrderCart,
  handlePagination,
  dispatchNoteNumber,
  ...props
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [update, setUpdate] = useState(true);
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const mainState = useSelector((state) => state);

  const handleConfirm = () => {
    setLoading(true);
    handleClaimOrderCart(orderItem, dispatchNoteNumber);
  };

  useEffect(() => {
    if (mainState.orders.result) {
      setUpdate(false);
      setLoading(false);
    }
  }, [mainState.orders.result]);

  function handleUpdateChanges() {
    setUpdate(false);
    handlePagination();
  }

  return (
    <>
      <ButtonBekeuLink
        ml="12px"
        onClick={onOpen}
        isDisabled={isDisabled}
        {...props}
      >
        {children}
      </ButtonBekeuLink>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        size="lg"
        onCloseComplete={() => mainState.orders.result && handleUpdateChanges()}
      >
        <ModalOverlay />
        <ModalContent borderRadius={"12px"}>
          <ModalCloseButton _focus={{ boxShadow: "rgba(0,0,0,0)" }} />
          <ModalBody>
            {update ? (
              <Confirmation
                onClose={onClose}
                handleConfirm={handleConfirm}
                history={history}
                setClaim={setClaim}
                claim={claim}
                handleClaimOrderCart={handleClaimOrderCart}
                loading={loading}
              />
            ) : (
              <UpdateChanges
                onClose={onClose}
                history={history}
                handleUpdateChanges={handleUpdateChanges}
              />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
