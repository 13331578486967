import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { ButtonBekeu } from "../../design-system/forms/ButtonBekeu";
import { ButtonBekeuOutline } from "../../design-system/forms/ButtonBekeuOutline";
import { H5 } from "../../design-system/headings/H5";
import { Paragraphs } from "../../design-system/headings/Paragraphs";

export const SalesRejectModal = ({
  handleRejectOrder,
  sellerComments,
  setSellerComments,
  handlePagination,
  deliveryTime,
  itemsState,
  item,
  handleFillResult,
  enableRejectOrder,
  ...props
}) => {
  const [isDeclined, setIsDeclined] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(false);
  const mainState = useSelector((state) => state);

  const handleReject = () => {
    setLoading(true);
    setIsDeclined(!isDeclined);
    handleRejectOrder(item.id, itemsState);
    setSellerComments("");
  };

  return (
    <>
      <ButtonBekeuOutline
        onClick={onOpen}
        w="fit-content"
        px="52px"
        isDisabled={!enableRejectOrder}
        title={
          !enableRejectOrder &&
          "Recordá que podrás rechazar una vez que el comprador haya confirmado la recepción de lo enviado previamente."
        }
      >
        Rechazar pedido
      </ButtonBekeuOutline>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setLoading(false);
        }}
        onCloseComplete={() => {
          if (mainState.orders && mainState.orders.result === true) {
            handlePagination();
          }
        }}
        isCentered
        size="xl"
      >
        <ModalOverlay />
        <ModalContent borderRadius={"12px"} w="540px">
          <ModalCloseButton _focus={{ boxShadow: "rgba(0,0,0,0)" }} />
          <ModalBody>
            {mainState.orders && mainState.orders.result === true ? (
              <Flex
                flexDir={"column"}
                justifyContent="center"
                padding={"48px"}
                alignItems="center"
                gap="12px"
                className="animate__animated animate__fadeIn"
              >
                <H5 textAlign="center">Cancelaste el pedido con éxito</H5>
                <ButtonBekeu
                  w="fit-content"
                  px="64px"
                  onClick={() => {
                    onClose();
                    handlePagination();
                  }}
                >
                  Aceptar
                </ButtonBekeu>
              </Flex>
            ) : (
              <Flex
                flexDir={"column"}
                justifyContent="center"
                padding={"48px"}
                className="animate__animated animate__fadeIn"
              >
                <H5 textAlign="center">
                  ¿Estás seguro que querés cancelar esta parte del pedido?
                </H5>
                <Paragraphs textAlign="center" lineHeight="22px" mt="16px">
                  {` Se cancelará el pedido correspondiente al bloque con plazo de
                  entrega de ${deliveryTime} días.`}
                </Paragraphs>

                <Flex alignItems="center" mt={"32px"}>
                  <ButtonBekeuOutline onClick={onClose} mr={4}>
                    No, volver atras
                  </ButtonBekeuOutline>
                  <ButtonBekeu
                    onClick={handleReject}
                    isLoading={loading}
                    my={0}
                  >
                    Si, cancelar
                  </ButtonBekeu>
                </Flex>
              </Flex>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
