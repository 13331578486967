import { Flex, Button } from "@chakra-ui/react";
import React from "react";
import { useHistory } from "react-router-dom";
import arrowLeft from "../../design-system/icons/Arrows/arrow-left.svg";
import arrowRight from "../../design-system/icons/Arrows/white-arrow-right.svg";
import useQuery from "../../hooks/useQuery";
import { BekeuIcon } from "../BekeuIcon/BekeuIcon";
import { usePagination } from "@material-ui/lab/Pagination";

export const Paginator = ({ customOnClick, ...props }) => {
  //hooks
  const history = useHistory();
  const query = useQuery();

  //useQuery variables
  const page = Number(query.get("page"));
  const perPage = query.get("per_page");
  const term = query.get("term");
  const state = Number(query.get("state"));
  const category_id = Number(query.get("category_id"));

  // localStorage variables
  const storageFiltersVar = localStorage.getItem("storageFilters");
  const globalStorageVar = localStorage.getItem("globalStorage");
  const globalStorage = JSON.parse(globalStorageVar);
  const storageFilters = {
    isFirstLoad: true,
    categories: {
      category_name: storageFiltersVar
        ? JSON.parse(storageFiltersVar).categories.category_name
        : [],
      category_id: storageFiltersVar
        ? JSON.parse(storageFiltersVar).categories.category_id
        : [],
    },
    brands: {
      brand_name: storageFiltersVar
        ? JSON.parse(storageFiltersVar).brands.brand_name
        : [],
      brand_id: storageFiltersVar
        ? JSON.parse(storageFiltersVar).brands.brand_id
        : [],
    },
    delivery_zones: {
      delivery_zone_name: storageFiltersVar
        ? JSON.parse(storageFiltersVar).delivery_zones.delivery_zone_name
        : [],
      delivery_zone_id: storageFiltersVar
        ? JSON.parse(storageFiltersVar).delivery_zones.delivery_zone_id
        : [],
    },
    price: storageFiltersVar ? JSON.parse(storageFiltersVar).price : [],
    delivery_days: storageFiltersVar
      ? JSON.parse(storageFiltersVar).delivery_days
      : [],
  };

  // setting global state to null. Affects products grid filter box
  function setGlobalStorage() {
    localStorage.setItem(
      "globalStorage",
      JSON.stringify({
        isFirstLoad: true,
        page: null,
      })
    );
  }

  // function for scrolling to top
  function setScrollTo() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }

  // function for setting storage filters
  function setStorageFilters() {
    localStorage.setItem("storageFilters", JSON.stringify(storageFilters));
  }

  function handleClick() {
    setScrollTo();
    setGlobalStorage();
    setStorageFilters();
  }

  const { items } = usePagination({
    count: props.totalItems,
    defaultPage: page,
  });

  // Concat query params variables
  let searchLeft = ``;

  if (page) {
    searchLeft += `?page=${page - 1}`;
  }

  if (perPage) {
    searchLeft += `&per_page=${perPage}`;
  }

  if (state) {
    searchLeft += `&state=${state}`;
  }

  if (term) {
    searchLeft += `&term=${term}`;
  }

  if (category_id) {
    searchLeft += `&category_id=${category_id}`;
  }

  let searchRight = ``;

  if (page) {
    searchRight += `?page=${page + 1}`;
  }

  if (perPage) {
    searchRight += `&per_page=${perPage}`;
  }

  if (state) {
    searchRight += `&state=${state}`;
  }

  if (term) {
    searchRight += `&term=${term}`;
  }

  if (category_id) {
    searchRight += `&category_id=${category_id}`;
  }

  return (
    <Flex w={"100%"} justifyContent="center" {...props}>
      <ul
        style={{
          listStyle: "none",
          padding: 10,
          margin: 0,
          display: "flex",
        }}
      >
        {items.map(({ page, type, selected, ...item }, index) => {
          let children = null;
          let search = `?page=${page}`;

          if (perPage) {
            search += `&per_page=${perPage}`;
          }

          if (state) {
            search += `&state=${state}`;
          }

          if (term) {
            search += `&term=${term}`;
          }
          if (category_id) {
            search += `&category_id=${category_id}`;
          }

          if (type === "start-ellipsis" || type === "end-ellipsis") {
            children = (
              <Button
                mx={"8px"}
                _active={{ bg: "brand.primary", color: "white" }}
                size={"sm"}
                isActive={query.get("page") === `${page}`}
                _focus={{
                  boxShadow:
                    "0 0 1px 1px rgba(122, 57, 162, 1), 0 1px 1px rgba(122, 57, 162, 1)",
                }}
                cursor="context-menu"
                isDisabled
              >
                ...
              </Button>
            );
          } else if (type === "page") {
            children = (
              <Button
                mx={"8px"}
                _active={{ bg: "brand.primary", color: "white" }}
                size={"sm"}
                isActive={
                  globalStorage?.page === page ||
                  (!globalStorage?.page && query.get("page") === `${page}`)
                }
                _focus={{
                  boxShadow:
                    "0 0 1px 1px rgba(122, 57, 162, 1), 0 1px 1px rgba(122, 57, 162, 1)",
                }}
                {...item}
                onClick={() => {
                  if (!customOnClick) {
                    handleClick();
                    query.get("page") !== `${page}` && history.push(search);
                  } else {
                    handleClick();
                    query.get("page") !== `${page}` && customOnClick(page);
                  }
                }}
              >
                {page}
              </Button>
            );
          } else {
            children =
              type === "previous" ? (
                <Button
                  mx={"8px"}
                  bg="brand.primary"
                  color="white"
                  size={"sm"}
                  _focus={{
                    boxShadow:
                      "0 0 1px 1px rgba(122, 57, 162, 1), 0 1px 1px rgba(122, 57, 162, 1)",
                  }}
                  _hover={{ bg: "brand.hover" }}
                  {...item}
                  onClick={() => {
                    handleClick();
                    history.push(searchLeft);
                  }}
                >
                  <BekeuIcon src={arrowLeft} />
                </Button>
              ) : (
                <Button
                  mx={"8px"}
                  bg="brand.primary"
                  color="white"
                  size={"sm"}
                  _focus={{
                    boxShadow:
                      "0 0 1px 1px rgba(122, 57, 162, 1), 0 1px 1px rgba(122, 57, 162, 1)",
                  }}
                  _hover={{ bg: "brand.hover" }}
                  {...item}
                  onClick={() => {
                    handleClick();
                    history.push(searchRight);
                  }}
                >
                  <BekeuIcon src={arrowRight} />
                </Button>
              );
          }

          return <li key={index}>{children}</li>;
        })}
      </ul>
    </Flex>
  );
};
