import {
  Box,
  Divider,
  Flex,
  Img,
  Spinner,
  Text,
  useToast,
} from "@chakra-ui/react";
import { connect, useSelector } from "react-redux";
import { injectIntl } from "react-intl";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ApproveOrdersBreadcrumb } from "../../components/ApproveOrdersBreadcrumb/ApproveOrdersBreadcrumb";
import DelegateOrdersModal from "../../components/DelegateOrdersModal/DelegateOrdersModal";
import { ButtonBekeuOutline } from "../../design-system/forms/ButtonBekeuOutline";
import { H5 } from "../../design-system/headings/H5";
import { Paragraphs } from "../../design-system/headings/Paragraphs";
import { InputBekeu } from "../../design-system/inputs/InputBekeu";
import { TextAreaBekeu } from "../../design-system/inputs/TextAreaBekeu";
import { BoxBekeu } from "../../design-system/layout/BoxBekeu";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { Select } from "@chakra-ui/react";
import { EditDelegateDeleteModal } from "../../components/EditDelegateDeleteModal/EditDelegateDeleteModal";
import { EditDelegateModal } from "../../components/EditDelegateModal/EditDelegateModal";
import errCircle from "../../design-system/icons/Arrows/close-circle.svg";
import * as delegate from "../../pages/delegateOrdersPage/ducks/delegateOrders.duck";
import ReactDatePicker from "react-datepicker";

export const DelegateOrdersPage = (props) => {
  const mainState = useSelector((state) => state);
  let history = useHistory();
  const [delegateUser, setDelegateUser] = useState("");
  const [startDate, setStartDate] = useState(moment("now"));
  const [endDate, setEndDate] = useState(moment("now"));
  const [reason, setReason] = useState("");
  const [editable, setEditable] = useState(true);
  const [edition, setEdition] = useState(false);
  const [success, setSuccess] = useState(null);
  const [successEdit, setSuccessEdit] = useState(null);
  const toast = useToast();

  useEffect(() => {
    props.getClients();
  }, []);

  useEffect(() => {
    if (
      mainState.delegation.delegation &&
      mainState.delegation.delegation.delegated_id &&
      mainState.delegation.delegation.date_from &&
      mainState.delegation.delegation.date_to
    ) {
      setEditable(false);
      setEdition(true);
      handleChangeDelegateUser(mainState.delegation.delegation.delegated_id);
      handleChangeStartDate(
        moment(mainState.delegation.delegation.date_from).format("YYYY-MM-DD")
      );
      handleChangeEndDate(
        moment(mainState.delegation.delegation.date_to).format("YYYY-MM-DD")
      );
      setReason(mainState.delegation.delegation.reason);
    }
  }, [mainState.delegation.delegation]);

  useEffect(() => {
    if (mainState.delegation.resultDeleteDelegation !== null) {
      if (mainState.delegation.resultDeleteDelegation === true) {
        setSuccess(true);
      } else {
        setSuccess(false);
      }
      setTimeout(() => {
        setSuccess(null);
        props.setResultDeleteDelegation(null);
        props.fillDelegation({});
      }, 5000);
    }
  }, [mainState.delegation.resultDeleteDelegation]);

  useEffect(() => {
    if (mainState.delegation.resultEditDelegation) {
      if (mainState.delegation.resultEditDelegation) {
        setSuccessEdit(true);
      } else {
        setSuccessEdit(false);
      }
      setTimeout(() => {
        setSuccessEdit(null);
        props.setResultEditDelegation(null);
        props.fillDelegation({});
      }, 5000);
    }
  }, [mainState.delegation.resultEditDelegation]);

  const handleChangeDelegateUser = (event) => {
    if (typeof event === "number") {
      setDelegateUser(event);
    } else {
      setDelegateUser(JSON.parse(event.target.value).user.id);
    }
  };
  const handleChangeStartDate = (event) => {
    let value = null;
    if (typeof event === "string") {
      setStartDate(event);
    } else {
      value = event.target.value;
      const currentDate = moment();
      if (
        moment(value)
          .startOf("day")
          .isBefore(currentDate.startOf("day"))
      ) {
        toast({
          title: "ERROR, la fecha inicial no puede ser previa al día actual",
          position: "bottom-right",
          status: "error",
          duration: 4000,
          render: () => (
            <Box
              color="brand.primary"
              height={"48px"}
              p={3}
              bg="#F44336"
              borderRadius={"2px"}
              borderTopLeftRadius={"12px"}
            >
              <Flex>
                <Img src={errCircle} px={"8px"} filter={"invert(100%)"} />
                <Text px={"8px"} color="white">
                  Error: la fecha inicial no puede ser previa al día actual
                </Text>
              </Flex>
            </Box>
          ),
        });
      } else {
        setStartDate(value);
      }
    }
  };

  const handleChangeEndDate = (event) => {
    let value = null;
    if (typeof event === "string") {
      setEndDate(event);
    } else {
      value = event.target.value;
      const startDateTmp = moment(startDate);
      const endDateTmp = moment(value);
      if (
        moment(startDateTmp)
          .startOf("day")
          .isAfter(endDateTmp.startOf("day"))
      ) {
        toast({
          title: "Error: la fecha final no puede ser previa a la inicial",
          position: "bottom-right",
          status: "error",
          duration: 4000,
          render: () => (
            <Box
              color="brand.primary"
              height={"48px"}
              p={3}
              bg="#F44336"
              borderRadius={"2px"}
              borderTopLeftRadius={"12px"}
            >
              <Flex>
                <Img src={errCircle} px={"8px"} filter={"invert(100%)"} />
                <Text px={"8px"} color="white">
                  Error: la fecha final no puede ser previa a la inicial
                </Text>
              </Flex>
            </Box>
          ),
        });
      } else {
        setEndDate(value);
      }
    }
  };

  const handleChangeReason = (event) => {
    setReason(event.target.value);
  };

  const handleSetEditable = (value) => {
    setEditable(value);
  };

  const handleDelegateAction = () => {
    if (edition) {
      props.delegateEdit({
        delegation_id: mainState.delegation.delegation.id_delegation,
        authorizer_id: mainState.auth.user.client.id,
        delegated_id: delegateUser,
        from: startDate,
        to: endDate,
        reason: reason,
      });
    } else {
      props.delegate({
        authorizer_id: mainState.auth.user.client.id,
        delegated_id: delegateUser,
        from: startDate,
        to: endDate,
        reason: reason,
      });
    }
  };

  const handleRemoveDelegation = () => {
    props.removeDelegation(mainState.delegation.delegation.id_delegation);
  };
  const [email, setEmail] = useState("");

  return (
    <Flex justifyContent={"center"}>
      <Flex
        minH={"100vh"}
        flexDir={"column"}
        py={"40px"}
        bg={"brand.background"}
        w="75rem"
        {...props}
      >
        <ApproveOrdersBreadcrumb />
        <H5 my={"32px"}>Delegar aprobación</H5>
        <BoxBekeu>
          <form>
            <Flex flexDir={"row"} height={"100%"}>
              <Flex flexDir={"column"} mx={"12px"} w={"40%"} height={"100%"}>
                <Paragraphs>Delegado</Paragraphs>
                <Select
                  padding={"8px, 12px, 8px, 16px"}
                  height={"56px"}
                  fontFamily="Open Sans"
                  fontWeight={"300px"}
                  fontSize="16px"
                  borderRadius={"15px"}
                  borderColor={"brand.border"}
                  borderWidth="1px"
                  focusBorderColor="brand.hover"
                  placeholder={
                    mainState.delegation.loading
                      ? "Cargando..."
                      : email === ""
                      ? "Seleccioná persona a delegar"
                      : email
                  }
                  isDisabled={!editable}
                  value={delegateUser}
                  onChange={(event) => {
                    handleChangeDelegateUser(event);
                    setDelegateUser(JSON.parse(event.target.value).user.id);
                    setEmail(JSON.parse(event.target.value).user.email);
                  }}
                  mt={"8px"}
                  mb={0}
                  bg={!editable ? "rgba(245, 245, 245, 1)" : "rgba(0,0,0,0)"}
                >
                  {mainState.delegation.clients &&
                    mainState.delegation.clients.length > 0 &&
                    mainState.delegation.clients.map((item, key) => {
                      return (
                        <option key={key} value={JSON.stringify(item)}>
                          {item.user.email}
                        </option>
                      );
                    })}
                </Select>
              </Flex>
              <Flex w={"232px"} flexDir={"column"} mx={"12px"}>
                <Paragraphs>Fecha de inicio</Paragraphs>

                <InputBekeu
                  type="date"
                  isDisabled={!editable}
                  bg={!editable ? "rgba(245, 245, 245, 1)" : "rgba(0,0,0,0)"}
                  value={startDate}
                  mt={"8px"}
                  isRequired
                  onChange={handleChangeStartDate}
                  onKeyDown={(e) => e.preventDefault()}
                />
              </Flex>
              <Flex w={"232px"} flexDir={"column"} mx={"12px"}>
                <Paragraphs>Fecha de fin</Paragraphs>
                <InputBekeu
                  type="date"
                  isDisabled={!editable}
                  bg={!editable ? "rgba(245, 245, 245, 1)" : "rgba(0,0,0,0)"}
                  value={endDate}
                  mt={"8px"}
                  isRequired
                  onChange={handleChangeEndDate}
                  onKeyDown={(e) => e.preventDefault()}
                />
              </Flex>
            </Flex>
            <Divider mt={"16px"} />
            <Flex flexDir={"column"} mx={"12px"}>
              <Paragraphs mt={"12px"}>Motivo</Paragraphs>
              <TextAreaBekeu
                placeholder="Escriba su motivo"
                isDisabled={!editable}
                bg={!editable ? "rgba(245, 245, 245, 1)" : "rgba(0,0,0,0)"}
                value={reason}
                mt={"8px"}
                maxLength={100}
                isRequired
                onChange={handleChangeReason}
              />
            </Flex>
            <Divider mt={"16px"} />
            {editable && !edition ? (
              <Flex justifyContent={"flex-end"}>
                <Flex alignItems={"center"} width="40%">
                  <ButtonBekeuOutline onClick={() => history.goBack()}>
                    Cancelar
                  </ButtonBekeuOutline>
                  <DelegateOrdersModal
                    email={email}
                    handleDelegateAction={handleDelegateAction}
                    isDisabled={
                      delegateUser === "" ||
                      reason === "" ||
                      endDate === "" ||
                      startDate === ""
                    }
                  />
                </Flex>
              </Flex>
            ) : (
              <Flex
                justifyContent={"flex-end"}
                alignItems={"center"}
                width="100%"
              >
                {editable && (
                  <ButtonBekeuOutline
                    style={{ width: "15%" }}
                    onClick={() => handleSetEditable(false)}
                  >
                    Cancelar
                  </ButtonBekeuOutline>
                )}
                {!editable && (
                  <EditDelegateDeleteModal
                    handleRemoveDelegation={handleRemoveDelegation}
                    success={success}
                  />
                )}
                {edition && (
                  <EditDelegateModal
                    isEnabled={editable}
                    handleDelegateAction={handleDelegateAction}
                    successEdit={successEdit}
                    onClick={() => handleSetEditable(true)}
                  />
                )}
              </Flex>
            )}
          </form>
        </BoxBekeu>
      </Flex>
    </Flex>
  );
};

export default injectIntl(connect(null, delegate.actions)(DelegateOrdersPage));
