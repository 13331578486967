import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { LastLocationProvider } from "react-router-last-location";
import { Routes } from "./app/router/Routes";
import { I18nProvider } from "./_toronto";
import { ChakraProvider } from "@chakra-ui/react";
import { theme } from "./bekeuTheme";
import TagManager from "react-gtm-module";
import "@fontsource/open-sans";
import { gtmModule } from "./config";

import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './AuthConfig';

const msalInstance = new PublicClientApplication(msalConfig);


const TagManagerArgs = {
  gtmId: gtmModule.gtm_id,
};

TagManager.initialize(TagManagerArgs);
TagManager.dataLayer({ dataLayer: { event: "pageview", path: "/home/" } });

export default function App({ store, persistor, basename }) {
  return (
    <BrowserRouter basename={basename}>
      <Provider store={store}>
        <PersistGate persistor={persistor} loading={<h1>loading...</h1>}>
          <React.Suspense fallback={<h1>...</h1>}>
            <LastLocationProvider>
              <I18nProvider>
                <ChakraProvider theme={theme}>
                  <MsalProvider instance={msalInstance}>
                    <Routes />
                  </MsalProvider>
                </ChakraProvider>
              </I18nProvider>
            </LastLocationProvider>
          </React.Suspense>
        </PersistGate>
      </Provider>
    </BrowserRouter>
  );
}
