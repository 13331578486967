import React, { useEffect, useState } from "react";
import "./ProductGridPage.scss";
import CardItem from "../../components/CardItem/CardItem";
import { Flex, Heading, Progress, SimpleGrid } from "@chakra-ui/react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { useSelector } from "react-redux";
import { ProductsGridFilterBox } from "../../components/ProductsGridFilterBox/ProductsGridFilterBox";
import * as productsGrid from "../productGridPage/ducks/productsGrid.duck";
import { useHistory, useParams } from "react-router-dom";
import { Paginator } from "../../components/Paginator/Paginator";
import useQuery from "../../hooks/useQuery";
import TagFilters from "../../components/ProductsGridTagFilters/TagFilters";
import { BekeuIcon } from "../../components/BekeuIcon/BekeuIcon";
import { H5 } from "../../design-system/headings/H5";
import { ButtonBekeuLink } from "../../design-system/forms/ButtonBekeuLink";
import warning from "../../design-system/icons/Shapes/warning-2.svg";
import { ProductsGridBreadcrumb } from "../../components/ProductsGridBreadrcumb/ProductsGridBreadcrumb";
import CardItemLoader from "../../components/CardItem/CardItemLoader";

const ProductGridPage = ({
  listProductsGrid,
  // listProductsGridByTerm,
  listProductsGridFilters,
  className,
}) => {
  // Main state
  const mainState = useSelector((state) => state);
  const storageFiltersVar = localStorage.getItem("storageFilters");
  // const globalStorageVar = localStorage.getItem("globalStorage");

  // Hooks
  const query = useQuery();
  const { term: search } = useParams();
  const history = useHistory();

  // useQuery variables
  const searchTerm = query.get("term");
  const page = query.get("page");
  const perPage = query.get("per_page");
  const category_id = query.get("category_id");

  // useState
  const [totalStates, setTotalStates] = useState({
    category_id:
      (storageFiltersVar && JSON.parse(storageFiltersVar))?.categories
        .category_id || [],
    brand_id:
      (storageFiltersVar && JSON.parse(storageFiltersVar))?.brands.brand_id ||
      [],
    delivery_zone:
      (storageFiltersVar && JSON.parse(storageFiltersVar))?.delivery_zones
        .delivery_zone_id || [],
    page: 1,
    perPage,
    term: searchTerm,
  });

  // falsy values variable + Event listener for first load and filters
  const storageFilters = {
    isFirstLoad: true,
    categories: { category_name: [], category_id: [] },
    brands: { brand_name: [], brand_id: [] },
    delivery_zones: { delivery_zone_name: [], delivery_zone_id: [] },
    price: [],
    delivery_days: [],
  };
  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, []);

  const alertUser = (e) => {
    e.preventDefault();
    localStorage.setItem("storageFilters", JSON.stringify(storageFilters));
  };

  const storageValidation = JSON.parse(storageFiltersVar);

  // Axios API calls
  const handleMainSearch = (customPage) => {
    listProductsGrid({
      term: searchTerm,
      page: customPage ? customPage : page,
      perPage,
      category_id:
        (totalStates?.category_id.length !== 0 && [totalStates?.category_id]) ||
        (category_id && [category_id]),
      brand_id: [totalStates?.brand_id],
      delivery_zone: [totalStates?.delivery_zone],
    });
    listProductsGridFilters({
      term: searchTerm,
      category_id:
        (totalStates?.category_id.length !== 0 && [totalStates?.category_id]) ||
        (category_id && [category_id]),
      brand_id: [totalStates?.brand_id],
      delivery_zone: [totalStates?.delivery_zone],
    });
  };

  useEffect(() => {
    if (storageValidation?.isFirstLoad) {
      handleMainSearch();
    }
  }, [category_id, searchTerm, page, perPage]);

  const arr = [
    ...new Array(
      Math.ceil(
        mainState.productsGrid.productsGridPagination.totalRows /
          mainState.productsGrid.productsGridPagination.perPage
      )
    ),
  ];

  const [filterResult, setFilterResult] = useState(true);

  useEffect(() => {
    mainState?.productsGrid?.filterResult
      ? setFilterResult(false)
      : setFilterResult(true);
  }, [filterResult, mainState.productsGrid.filterResult]);

  const skeletonLoading = filterResult;
  const gridLoading = mainState.productsGrid.loading;

  const [tagItem, setTagItem] = useState("");

  function handleRemoveTag(item, type) {
    type === "category" &&
      setTagItem({
        id: item.category_id[0],
        name: item.category_name[0],
        type: "category",
      });
    type === "brand" &&
      setTagItem({
        id: item.brand_id[0],
        name: item.brand_name[0],
        type: "brand",
      });
    type === "delivery_zone" &&
      setTagItem({
        id: item.delivery_zone_id[0],
        name: item.delivery_zone_name[0],
        type: "delivery_zone",
      });
    type === "delivery_days" &&
      setTagItem({
        type: "delivery_days",
      });
    type === "price" &&
      setTagItem({
        type: "price",
      });
  }

  return (
    <Flex justifyContent={"center"}>
      <Flex
        w="75rem"
        minH={"50vh"}
        justifyContent="space-between"
        py="72px"
        bg={"brand.background"}
        className={className}
      >
        <Flex flexDir={"column"}>
          {mainState?.productsGrid?.products?.length !== 0 && (
            <Flex h="65px" w={"307px"}>
              <Flex>
                {!skeletonLoading && (
                  <ProductsGridBreadcrumb
                    product={
                      mainState?.productsGrid?.productsGridFilters
                        ?.categorias[0]
                    }
                  />
                )}
              </Flex>
            </Flex>
          )}
          <ProductsGridFilterBox
            tagItem={tagItem}
            isVisible={
              !mainState.productsGrid.loading &&
              mainState.productsGrid.resultGrid &&
              mainState.productsGrid.products.length === 0
            }
            skeletonLoading={skeletonLoading}
            listProductsGrid={listProductsGrid}
            listProductsGridFilters={listProductsGridFilters}
            listProductsGridsFilters={
              mainState.productsGrid.productsGridFilters
            }
            listProductsGridsFiltersResult={mainState.productsGrid.filterResult}
          />
        </Flex>
        <Flex flexDir={"column"} w={"100%"} justifyContent={"space-between"}>
          <Flex flexDir={"column"} w={"100%"} h={"100%"}>
            <Flex justifyContent={"space-between"} h="65px">
              {(mainState?.productsGrid?.products?.length > 0 ||
                mainState.productsGrid.loading) && (
                <Heading fontSize={"24px"} fontWeight="semibold" mb="24px">
                  Resultados
                </Heading>
              )}
            </Flex>

            {mainState.productsGrid.loading ? (
              <SimpleGrid spacing={4} columns={4}>
                {[1, 2, 3, 4, 5, 6, 7, 8].map((data, key) => {
                  return (
                    <CardItemLoader
                      key={key}
                      data={data}
                      search={search}
                      gridLoading={gridLoading}
                    />
                  );
                })}
              </SimpleGrid>
            ) : (
              <>
                {mainState?.productsGrid?.products?.length !== 0 && (
                  <Flex
                    flexDirection="row"
                    flexWrap="wrap"
                    className="animate__animated animate__fadeIn"
                  >
                    {storageValidation?.categories?.categoriesTag?.map(
                      (tags, id) => (
                        <TagFilters
                          key={id}
                          tagName={tags.category_name}
                          tags={tags}
                          handleRemoveTag={() =>
                            handleRemoveTag(tags, "category")
                          }
                        />
                      )
                    )}
                    {storageValidation?.brands?.brandsTag?.map((tags, i) => (
                      <TagFilters
                        key={i}
                        tagName={tags.brand_name}
                        tags={tags}
                        handleRemoveTag={() => handleRemoveTag(tags, "brand")}
                      />
                    ))}
                    {storageValidation?.delivery_zones?.delivery_zone_tag?.map(
                      (tags, i) => (
                        <TagFilters
                          key={i}
                          tags={tags}
                          tagName={tags.delivery_zone_name}
                          handleRemoveTag={() =>
                            handleRemoveTag(tags, "delivery_zone")
                          }
                        />
                      )
                    )}
                    {storageValidation?.price?.map((tags, i) => (
                      <TagFilters
                        key={i}
                        tags={tags}
                        tagName={`De $${tags.min} hasta $${tags.max}`}
                        handleRemoveTag={() => handleRemoveTag(tags, "price")}
                      />
                    ))}
                    {storageValidation?.delivery_days?.map((tags, i) => (
                      <TagFilters
                        key={i}
                        tags={tags}
                        tagName={`${tags.min} a ${tags.max} días`}
                        handleRemoveTag={() =>
                          handleRemoveTag(tags, "delivery_days")
                        }
                      />
                    ))}
                  </Flex>
                )}
                {mainState.productsGrid.products &&
                mainState.productsGrid.products.length > 0 ? (
                  <SimpleGrid spacing={4} columns={4}>
                    {mainState.productsGrid.products.map((data, key) => {
                      return (
                        <CardItem
                          key={key}
                          data={data}
                          search={search}
                          gridLoading={gridLoading}
                        />
                      );
                    })}
                  </SimpleGrid>
                ) : (
                  <Flex
                    bg={"brand.background"}
                    justifyContent="center"
                    width="100%"
                    height="100%"
                    className="animate__animated animate__fadeIn"
                  >
                    <Flex
                      flexDir="column"
                      alignItems={"center"}
                      justifyContent="center"
                    >
                      <BekeuIcon src={warning} w={"75px"} mb={4} />
                      <H5 mb={2}>
                        No se encontraron resultados para la búsqueda
                      </H5>
                      <ButtonBekeuLink onClick={() => history.push("/")}>
                        Volver al inicio
                      </ButtonBekeuLink>
                    </Flex>
                  </Flex>
                )}
              </>
            )}
          </Flex>
          {!mainState.productsGrid.loading &&
            mainState.productsGrid.products &&
            mainState.productsGrid.productsGridPagination.totalPages > 1 && (
              <Paginator
                itemsArray={arr}
                totalItems={Math.ceil(
                  mainState.productsGrid.productsGridPagination.totalRows /
                    mainState.productsGrid.productsGridPagination.perPage
                )}
                mt={"10px"}
              />
            )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default injectIntl(connect(null, productsGrid.actions)(ProductGridPage));
