import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Flex,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  SimpleGrid,
  useDisclosure,
  Progress,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { H6 } from "../../design-system/headings/H6";
import { H5 } from "../../design-system/headings/H5";
import MyQuotesItem from "../../components/MyQuotesItem/MyQuotesItem";
import { MyQuotesBreadcrumb } from "../../components/MyQuotesBreadcrumb/MyQuotesBreadcrumb";
import { BoxBekeu } from "../../design-system/layout/BoxBekeu";
import { MenuBekeu } from "../../design-system/overlay/MenuBekeu";
import useQuery from "../../hooks/useQuery";
import { connect, useSelector } from "react-redux";
import { injectIntl } from "react-intl";
import { actions as quoteActions } from "./ducks/myQuotes.duck";
import MyQuotesImportModal from "../../components/MyQuotesImportModal/MyQuotesImportModal";
import { ButtonBekeu } from "../../design-system/forms/ButtonBekeu";
import { Paginator } from "../../components/Paginator/Paginator";
import moment from "moment";
import { useHistory, useLocation } from "react-router-dom";
import MyQuotesMenuCategories from "../../components/MyQuotesMenuCategories/MyQuotesMenuCategories";
import { ButtonBekeuOutline } from "../../design-system/forms/ButtonBekeuOutline";
import successCircle from "../../design-system/icons/Arrows/success-circle-fill.svg";
import { BekeuIcon } from "../../components/BekeuIcon/BekeuIcon";
import { Paragraphs } from "../../design-system/headings/Paragraphs";
import MyQuotesItemLoader from "../../components/MyQuotesItem/MyQuotesItemLoader";

const MyQuotesPage = ({
  getRegions,
  fadeIn,
  listQuotes,
  listCurrencies,
  getCategories,
  getExportQuote,
  pauseState,
  enableState,
  ...props
}) => {
  // main states
  const mainState = useSelector((state) => state);

  // Hooks
  const query = useQuery();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenModalMasive,
    onOpen: onOpenModalMasive,
    onClose: onCloseModalMasive,
  } = useDisclosure();

  const ref = useRef();
  const history = useHistory();
  const { pathname } = useLocation();

  // useState variables
  const [force, setForce] = useState(0);
  const [exportLoading, setExportLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [quotesStates, setQuoteStates] = useState("");

  // queries states
  const state = query.get("state");
  const currency_id = query.get("currency_id");
  const region_id = query.get("region_id");
  const page = query.get("page");
  const perPage = query.get("per_page");
  const category_id = query.get("category_id");
  const subcategory_id = query.get("subcategory_id");
  const sub_category_item = query.get("sub_category_item");
  const brand_id = query.get("brand_id");
  const d = query.get("d");
  const from = query.get("from");
  const to = query.get("to");

  //useEffect
  useEffect(() => {
    listQuotes({
      state,
      page,
      perPage,
      region_id,
      currency_id,
      category_id,
      subcategory_id,
      sub_category_item,
      brand_id,
      from,
      to,
    });
    listCurrencies();
  }, [
    state,
    page,
    perPage,
    region_id,
    currency_id,
    category_id,
    subcategory_id,
    sub_category_item,
    brand_id,
    from,
    to,
    listQuotes,
    listCurrencies,
  ]);

  // handle actions
  const handleConfirmExport = () => {
    setExportLoading(true);
    getExportQuote({
      state,
      region_id,
      currency_id,
      category_id,
      subcategory_id,
      sub_category_item,
      brand_id,
      from,
      to,
    });
  };

  function handleConfirmImport() {
    listQuotes({
      state,
      page,
      perPage,
      region_id,
      currency_id,
      category_id,
      subcategory_id,
      sub_category_item,
      brand_id,
      from,
      to,
    });
  }

  const handleForceState = () => {
    setForce(force + 1);
  };

  useEffect(() => {
    if (mainState.quotes.result === "exportResult") {
      setExportLoading(false);
      ref && ref.current && ref.current.click();
    }
    mainState.quotes.result && setLoading(false);
  }, [mainState.quotes.result]);

  const dataItems = mainState.quotes.quotes;

  let currentDate = new Date();
  let year = currentDate.toLocaleString("default", { year: "numeric" });
  let month = currentDate.toLocaleString("default", { month: "2-digit" });
  let day = currentDate.toLocaleString("default", { day: "2-digit" });

  let formattedDate = year + "-" + month + "-" + day;

  const setMoment = (item) => {
    let newDate = "";
    if (item.d === 0) {
      newDate = moment()
        .add(-24, "M")
        .format("YYYY-MM-DD");
    }
    if (item.d === 1) {
      newDate = moment()
        .add(-1, "M")
        .format("YYYY-MM-DD");
    }
    if (item.d === 3) {
      newDate = moment()
        .add(-3, "M")
        .format("YYYY-MM-DD");
    }
    if (item.d === 6) {
      newDate = moment()
        .add(-6, "M")
        .format("YYYY-MM-DD");
    }
    if (item.d === 12) {
      newDate = moment()
        .add(-12, "M")
        .format("YYYY-MM-DD");
    }
    item?.date &&
      history.push(
        `${pathname}?state=${state}&page=1&per_page=20&from=${newDate}&to=${item.to}&d=${item.d}`
      );
  };

  const handleActiveState = () => {
    onOpenModalMasive();
    setQuoteStates("pause");
  };

  const handleEnableState = () => {
    onOpenModalMasive();
    setQuoteStates("enable");
  };

  const handleSuccess = () => {
    listQuotes({
      state,
      page,
      perPage,
      region_id,
      currency_id,
      category_id,
      subcategory_id,
      sub_category_item,
      brand_id,
    });
  };

  const handleRefreshItems = () => {
    listQuotes({ state, page, perPage });
    onClose();
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  return (
    <Flex justifyContent={"center"}>
      <Flex
        minH={"100vh"}
        flexDir={"column"}
        py={"40px"}
        bg={"brand.background"}
        w="75rem"
        {...props}
      >
        <Box mb="24px" overflow="unset" zIndex={2}>
          <MyQuotesBreadcrumb />
          <H5 mt="24px">Mis cotizaciones</H5>
          <SimpleGrid mt={"32px"} minChildWidth="200px" spacing={4}>
            <MyQuotesMenuCategories>Categorias</MyQuotesMenuCategories>
            {/* <MenuBekeu
            height={"56px"}
            mr={"16px"}
            items={mainState.quotes.categories}
            category_id
            scroll
          >
            {mainState.quotes.categories?.find(
              (a) => a.id === Number(category_id)
            )?.name || "Categoría"}
          </MenuBekeu> */}
            {/* <InputBekeu
            isDisabled
            placeholder={"Buscar"}
            bg={"white"}
            lefticon={<BekeuIcon src={search} mr={4} />}
            w={"100%"}
          /> */}
            <MenuBekeu
              scroll
              height={"56px"}
              mr={"16px"}
              items={[
                { name: "Publicación activa", state: "1" },
                { name: "Publicación pausada", state: "2" },
                { name: "Publicación cancelada", state: "3" },
              ]}
            >
              {state && state === "1"
                ? "Publicación activa"
                : state === "2"
                ? "Publicación pausada"
                : state === "3" && "Publicación cancelada"}
            </MenuBekeu>
            {/* <MenuBekeu
            height={"56px"}
            mr={"16px"}
            items={mainState.quotes.regions}
            region_id
          >
            {mainState.quotes.regions?.find((a) => a.id === Number(region_id))
              ?.name || "Zonas"}
          </MenuBekeu> */}
            <Flex>
              <MenuBekeu
                height={"56px"}
                items={[
                  ...mainState.quotes.currencies,
                  { name: "Todas las monedas", currency_id_reset: true },
                ]}
                currency_id
                scroll
              >
                {mainState.quotes.currencies?.find(
                  (a) => a.id === Number(currency_id)
                )?.name || "Moneda"}
              </MenuBekeu>
              {/* <MenuBekeu
              
              height={"56px"}
              mr={"16px"}
              onClick={onToggleFilters}
            >
              Mas filtros
            </MenuBekeu> */}
            </Flex>

            <MenuBekeu
              height={"56px"}
              leftIcon
              scroll
              setMoment={setMoment}
              state={state}
              items={[
                {
                  name: "Todas",
                  date: "Todas",
                  to: formattedDate,
                  d: 0,
                },
                {
                  name: "Este mes",
                  date: "Este mes",
                  to: formattedDate,
                  d: 1,
                },
                {
                  name: "Últimos 3 meses",
                  date: "Últimos 3 meses",
                  to: formattedDate,
                  d: 3,
                },
                {
                  name: "Últimos 6 meses",
                  date: "Últimos 3 meses",
                  to: formattedDate,
                  d: 6,
                },
                {
                  name: "Últimos 12 meses",
                  date: "Últimos 3 meses",
                  to: formattedDate,
                  d: 12,
                },
              ]}
            >
              {d
                ? d === "0"
                  ? "Todas"
                  : d === "1"
                  ? "Este mes"
                  : d === "3"
                  ? "Últimos 3 meses"
                  : d === "6"
                  ? "Últimos 6 meses"
                  : d === "12" && "Últimos 12 meses"
                : "Todas"}
            </MenuBekeu>
          </SimpleGrid>
          {/* Implementar cuando este en endpoint */}
          {/* <Collapse
          in={isOpenFilters}
          animateOpacity
          style={{ overflow: `${isOpenFilters ? "unset" : "hidden"}` }}
        >
          <BoxBekeu mt="32px">
            <SimpleGrid minChildWidth="400px" spacing={4}>
              <Flex flexDir="column">
                <H6 mb="12px">Categorías</H6>
                <MenuBekeu
                  height={"56px"}
                  mr={"16px"}
                  items={mainState.quotes.categories}
                  category_id
                >
                  {mainState.quotes.categories?.find(
                    (a) => a.id === Number(category_id)
                  )?.name || "Seleccionar"}
                </MenuBekeu>
              </Flex>
              <Flex flexDir="column">
                <Flex>
                  <H6 mb="12px">Subcategorías </H6> 
                  <H6 mb="12px" color="brand.content3">
                    {" "}
                    - Nivel 1
                  </H6>
                </Flex>
                <MenuBekeu
                  height={"56px"}
                  mr={"16px"}
                  items={mainState.quotes.categories.map(
                    (cat) => cat.sub_categories.map((c) => c)[0]
                  )}
                >
                  {console.log(
                    mainState.quotes.categories.map((cat) =>
                      cat.sub_categories.map((c) => c)
                    )
                  )}
                  Seleccionar
                </MenuBekeu>
              </Flex>
              <Flex flexDir="column">
                <Flex>
                  <H6 mb="12px">Subcategorías </H6> 
                  <H6 mb="12px" color="brand.content3">
                    {" "}
                    - Nivel 2
                  </H6>
                </Flex>
                <MenuBekeu
                  height={"56px"}
                  mr={"16px"}
                  items={[
                    { name: "Sub-categoría 1", sub_category_item: "1" },
                    { name: "Sub-categoría 2", sub_category_item: "2" },
                    { name: "Sub-categoría 3", sub_category_item: "3" },
                  ]}
                >
                  Seleccionar
                </MenuBekeu>
              </Flex>
              <Flex flexDir="column">
                <H6 mb="12px">Marcas</H6>
                <MenuBekeu
                  height={"56px"}
                  mr={"16px"}
                  items={[
                    { name: "Marca 1", brand: "1" },
                    { name: "Marca 2", brand: "2" },
                    { name: "Marca 3", brand: "3" },
                  ]}
                >
                  Seleccionar
                </MenuBekeu>
              </Flex>
            </SimpleGrid>
          </BoxBekeu>
        </Collapse> */}
        </Box>
        {mainState.quotes.loading ? (
          <BoxBekeu p="0" w="100%" className={fadeIn}>
            <Flex
              px="24px"
              alignItems="center"
              justifyContent="space-between"
              height={"96px"}
            >
              <H6>Información de la publicación</H6>
              <Box>
                {dataItems?.length > 0 && (
                  <ButtonBekeu w="fit-content" mr="24px">
                    Exportar
                  </ButtonBekeu>
                )}

                {mainState.quotes?.exportedData && (
                  <a
                    href={mainState.quotes.exportedData.temporary_signed_url}
                    rel="noopener noreferrer"
                    target="_blank"
                    ref={ref}
                  >
                    {" "}
                  </a>
                )}
                <ButtonBekeu w="fit-content" mr="14px">
                  Importar
                </ButtonBekeu>
                {dataItems?.length > 0 && (
                  <Menu>
                    <MenuButton
                      as={Button}
                      bg="#FFFFFF"
                      border="2px solid"
                      borderRadius="5px"
                      borderColor={"brand.contentSecondary"}
                      color="brand.contentSecondary"
                      m="0 12px"
                      height="20px"
                      minWidth="20px"
                      padding="0 0 9px 0"
                      transform="rotate(90deg)"
                      _focus={{ boxShadow: "rgba(0, 0, 0, 0)" }}
                    >
                      ...
                    </MenuButton>
                  </Menu>
                )}
              </Box>
            </Flex>

            {[1, 2].map((dataItem, index) => (
              <MyQuotesItemLoader
                key={index}
                image={dataItem.imagen}
                productName={dataItem.prod_name}
                sku={dataItem.comb_sku}
                size={dataItem.size}
                stateItem={dataItem.state}
                state={dataItem.state}
                isActive={dataItem.state}
                priceState={dataItem.price_state}
                currency={dataItem.moneda}
                currencyId={dataItem.currency_id}
                price={dataItem.comb_price}
                validityDate={dataItem.fecha_vigencia}
                priceId={dataItem.price_id}
                dateFrom={dataItem.date_from}
                providerId={dataItem.provider_id}
                handleForceState={handleForceState}
              />
            ))}
          </BoxBekeu>
        ) : (
          dataItems?.length > 0 && (
            <BoxBekeu p="0" w="100%" className={fadeIn}>
              <Flex
                px="24px"
                alignItems="center"
                justifyContent="space-between"
                height={"96px"}
              >
                <H6>Información de la publicación</H6>
                <Box>
                  {dataItems?.length > 0 && (
                    <ButtonBekeu
                      w="fit-content"
                      mr="24px"
                      onClick={handleConfirmExport}
                      isLoading={exportLoading}
                    >
                      Exportar
                    </ButtonBekeu>
                  )}

                  {mainState.quotes?.exportedData && (
                    <a
                      href={mainState.quotes.exportedData.temporary_signed_url}
                      rel="noopener noreferrer"
                      target="_blank"
                      ref={ref}
                    >
                      {" "}
                    </a>
                  )}

                  <ButtonBekeu
                    w="fit-content"
                    onClick={() => onOpen()}
                    mr="14px"
                  >
                    Importar
                  </ButtonBekeu>
                  <MyQuotesImportModal
                    onClose={onClose}
                    isOpen={isOpen}
                    handleConfirmImport={handleConfirmImport}
                  />
                  {dataItems?.length > 0 && (
                    <Menu>
                      <MenuButton
                        as={Button}
                        bg="#FFFFFF"
                        border="2px solid"
                        borderRadius="5px"
                        borderColor={"brand.contentSecondary"}
                        color="brand.contentSecondary"
                        m="0 12px"
                        height="20px"
                        minWidth="20px"
                        padding="0 0 9px 0"
                        transform="rotate(90deg)"
                        _focus={{ boxShadow: "rgba(0, 0, 0, 0)" }}
                      >
                        ...
                      </MenuButton>
                      <MenuList bg={"white"} borderRadius={"15px"} py={"12px"}>
                        <MenuItem
                          _focus={{ bg: "brand.transparent" }}
                          p={"12px 24px"}
                          _hover={{ color: "brand.hover", bg: "rgba(0,0,0,0)" }}
                          isDisabled={state === "3" || state === "2"}
                          onClick={() => handleActiveState()}
                        >
                          Pausar masivamente
                        </MenuItem>
                        <MenuItem
                          _focus={{ bg: "brand.transparent" }}
                          p={"12px 24px"}
                          _hover={{ color: "brand.hover", bg: "rgba(0,0,0,0)" }}
                          isDisabled={state === "3" || state === "1"}
                          onClick={() => handleEnableState()}
                        >
                          Activar masivamente
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  )}
                </Box>
              </Flex>
              <Modal
                isOpen={isOpenModalMasive}
                onClose={onCloseModalMasive}
                isCentered
                size={"xl"}
                onCloseComplete={() => {
                  mainState.quotes.result && handleRefreshItems();
                }}
              >
                <ModalOverlay />
                <ModalContent borderRadius={"12px"}>
                  <ModalCloseButton _focus={{ boxShadow: "rgba(0,0,0,0)" }} />
                  {mainState?.quotes?.result ? (
                    <ModalBody p={"48px"}>
                      <Flex
                        flexDir={"column"}
                        justifyContent="center"
                        alignItems={"center"}
                        gap={"12px"}
                      >
                        <BekeuIcon w={"54px"} src={successCircle} />
                        <H5 textAlign="center">
                          Los cambios fueron realizados con exito!
                        </H5>
                        <ButtonBekeu
                          my={0}
                          w={"fit-content"}
                          onClick={handleRefreshItems}
                        >
                          Aceptar
                        </ButtonBekeu>
                      </Flex>
                    </ModalBody>
                  ) : (
                    <ModalBody p={"48px"}>
                      <Flex flexDir={"column"} justifyContent="center">
                        <H5 textAlign="center">
                          {quotesStates === "pause"
                            ? "¿Estás seguro que querés pausar todas tus publicaciones?"
                            : quotesStates === "enable" &&
                              "¿Estás seguro que querés activar todas tus publicaciones?"}
                        </H5>
                        <Paragraphs
                          textAlign="center"
                          lineHeight={"22px"}
                          m={"25px"}
                        >
                          {quotesStates === "pause"
                            ? "Al pausar tus publicaciones tus productos no estarán disponibles para comprar."
                            : quotesStates === "enable" &&
                              "Al activar tus publicaciones tus productos estarán disponibles para comprar."}
                        </Paragraphs>
                        <Flex w="100%" mt="12px">
                          <ButtonBekeuOutline
                            mr="12px"
                            onClick={onCloseModalMasive}
                          >
                            No, cancelar
                          </ButtonBekeuOutline>
                          <ButtonBekeu
                            my="0"
                            ml="12px"
                            onClick={() => {
                              setLoading(true);
                              quotesStates === "pause"
                                ? pauseState()
                                : quotesStates === "enable" && enableState();
                            }}
                            isLoading={loading}
                          >
                            Si, confirmar
                          </ButtonBekeu>
                        </Flex>
                      </Flex>
                    </ModalBody>
                  )}
                </ModalContent>
              </Modal>
              {dataItems?.length > 0 &&
                dataItems.map((dataItem, index) => (
                  <MyQuotesItem
                    key={index}
                    image={dataItem.imagen}
                    productName={dataItem.prod_name}
                    sku={dataItem.comb_sku}
                    size={dataItem.size}
                    stateItem={dataItem.state}
                    state={dataItem.state}
                    isActive={dataItem.state}
                    priceState={dataItem.price_state}
                    currency={dataItem.moneda}
                    currencyId={dataItem.currency_id}
                    price={dataItem.comb_price}
                    validityDate={dataItem.fecha_vigencia}
                    priceId={dataItem.price_id}
                    dateFrom={dataItem.date_from}
                    providerId={dataItem.provider_id}
                    handleForceState={handleForceState}
                  />
                ))}
            </BoxBekeu>
          )
        )}
        {!mainState.quotes.loading && dataItems?.length === 0 && (
          <Text mt="24px">No se encontraron resultados</Text>
        )}
        {!mainState.quotes.loading &&
          mainState.quotes &&
          mainState.quotes.quotesPagination?.totalPages > 1 && (
            <Paginator
              totalItems={mainState.quotes.quotesPagination.totalPages}
              perPage={5}
              mt={"24px"}
            />
          )}
      </Flex>
    </Flex>
  );
};

export default injectIntl(connect(null, quoteActions)(MyQuotesPage));
