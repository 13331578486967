import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import {
  getUserByToken,
  checkToken,
  passwordUpdate,
  passwordChange,
  logout,
  getFeatures
} from "../crud/auth.crud";
import * as routerHelpers from "../../../router/RouterHelpers";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  CleanLogout: "[CleanLogout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  CHECK_TOKEN: "CHECK_TOKEN",
  FILL_VALIDATE_TOKEN_RESPONSE: "FILL_VALIDATE_TOKEN_RESPONSE",
  PASSWORD_UPDATE: "PASSWORD_UPDATE",
  PASSWORD_CHANGE: "PASSWORD_CHANGE",
  FILL_UPDATE_PASSWORD_RESULT: "FILL_UPDATE_PASSWORD_RESULT",
  FILL_CHANGE_PASSWORD_RESULT: "FILL_CHANGE_PASSWORD_RESULT",
  SET_RESULT: "SET_RESULT",
  GET_FEATURES: "GET_FEATURES",
  SET_FEATURES: "SET_FEATURES",
  FILL_FEATURES_RESULT: "FILL_FEATURES_RESULT"
};

const initialAuthState = {
  user: undefined,
  authToken: undefined,
  resetPassword: false,
  resetToken: null,
  loading: false,
  tokenValid: null,
  resultPassword: null,
  userType: null,
  resultChangePassword: null,
  features: null,
};

export const reducer = persistReducer(
  {
    storage,
    key: "demo1-auth",
    whitelist: ["user", "authToken", "resetPassword"],
  },

  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { authToken, resetPassword, resetToken, user } = action.payload;
        return {
          authToken,
          resetPassword,
          resetToken,
          user,
        };
      }

      case actionTypes.Register: {
        const { authToken } = action.payload;

        return { authToken, user: undefined };
      }

      case actionTypes.CleanLogout: {
        routerHelpers.forgotLastLocation();
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;

        return { ...state, user };
      }

      case actionTypes.CHECK_TOKEN: {
        return {
          ...state,
          loading: true,
        };
      }

      case actionTypes.FILL_VALIDATE_TOKEN_RESPONSE: {
        return {
          ...state,
          tokenValid: action.payload,
        };
      }

      case actionTypes.FILL_UPDATE_PASSWORD_RESULT: {
        let resetPassword = false;
        if (action.payload === true) {
          resetPassword = false;
        } else {
          resetPassword = true;
        }
        return {
          ...state,
          resultPassword: action.payload,
          resetPassword: resetPassword,
        };
      }

      case actionTypes.FILL_CHANGE_PASSWORD_RESULT: {
        return {
          ...state,
          resultChangePassword: action.payload,
        };
      }

      case actionTypes.SET_RESULT: {
        return {
          ...state,
          resultPassword: action.payload,
          resultChangePassword: action.payload,
        };
      }

      case actionTypes.GET_FEATURES: {
        return {
          ...state,
          loading: true,
        };
      }
      
      case actionTypes.FILL_FEATURES_RESULT: {
        return {
          ...state,
          loading: false,
          features: action.payload
        };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: (value) => ({ type: actionTypes.Login, payload: value }),
  checkToken: (value) => ({ type: actionTypes.CHECK_TOKEN, payload: value }),
  fillValidateTokenResponse: (value) => ({ type: actionTypes.FILL_VALIDATE_TOKEN_RESPONSE, payload: value}),
  register: (authToken) => ({type: actionTypes.Register, payload: { authToken }}),
  logout: () => ({ type: actionTypes.Logout }),
  cleanLogout: () => ({ type: actionTypes.CleanLogout }),
  requestUser: (user) => ({ type: actionTypes.UserRequested, payload: { user } }),
  fulfillUser: (user) => ({ type: actionTypes.UserLoaded, payload: { user } }),
  passwordUpdate: (value) => ({ type: actionTypes.PASSWORD_UPDATE, payload: value }),
  fillUpdatePasswordResult: (value) => ({ type: actionTypes.FILL_UPDATE_PASSWORD_RESULT, payload: value }),
  passwordChange: (value) => ({ type: actionTypes.PASSWORD_CHANGE, payload: value }),
  fillChangePasswordResult: (value) => ({ type: actionTypes.FILL_CHANGE_PASSWORD_RESULT, payload: value }),
  setResult: (value) => ({ type: actionTypes.SET_RESULT, payload: value }),
  getFeatures: (value) => ({ type: actionTypes.GET_FEATURES, payload: value }),
  fillFeatures: (value) => ({ type: actionTypes.FILL_FEATURES_RESULT, payload: value }),
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    // obtiene todo lo que tenga que ver con configuracion inicial remota del usuario
    try {
      const response = yield getFeatures();
      if (response.status === 200) {
        // console.log('pase por aca y traje los features', response.data.message);
        yield put(actions.fillFeatures(response.data.message));
      } else {
        yield put(actions.fillFeatures(false));
      }
    } catch (error) {
      yield put(actions.fillFeatures(false));
    }
  });

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.Logout, function* registerSaga() {
    try {
      const response = yield logout();
      yield put(actions.cleanLogout());
    } catch (error) {
      console.log(error);
    }
  });

  yield takeLatest(actionTypes.CHECK_TOKEN, function* checkTokenSaga({ payload }) {
    // const response = yield checkToken(payload);
    // yield put(
    //   actions.fillValidateTokenResponse(response.data.message.validate)
    // );
    try {
      const response = yield checkToken(payload);
      if (response.status === 200) {
        yield put(
          actions.fillValidateTokenResponse(response.data.message.validate)
        );
      }
    } catch (error) {
      yield put(actions.fillValidateTokenResponse(false));
    }
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const { data: user } = yield getUserByToken();
    yield put(actions.fulfillUser(user));
  });

  yield takeLatest(actionTypes.PASSWORD_UPDATE, function* passwordUpdateSaga({ payload }) {
    try {
      const response = yield passwordUpdate(payload);
      if (response.status === 201) {
        yield put(actions.fillUpdatePasswordResult(true));
      }
    } catch (error) {
      yield put(actions.fillUpdatePasswordResult(false));
    }
  });

  yield takeLatest(actionTypes.PASSWORD_CHANGE, function* passwordChangeSaga({ payload }) {
    try {
      const response = yield passwordChange(payload);
      if (response.status === 200) {
        yield put(actions.fillChangePasswordResult(true));
      } else {
        yield put(actions.fillChangePasswordResult(false));
      }
    } catch (error) {
      yield put(actions.fillChangePasswordResult(false));
    }
  });
  
  yield takeLatest(actionTypes.GET_FEATURES, function* getFeaturesSaga({ payload }) {
    try {
      const response = yield getFeatures(payload);
      if (response.status === 200) {
        // console.log('pase por aca y traje los features333', response.data.message);
        yield put(actions.fillFeatures(response.data.message));
      } else {
        yield put(actions.fillFeatures(false));
      }
    } catch (error) {
      yield put(actions.fillFeatures(false));
    }
  });
}
