import { Box } from "@chakra-ui/react";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";

export const ApproveOrdersBadge = (props) => {
  const initialState = {
    color: "",
    background: "",
    message: "",
  };

  const [type, setType] = useState(initialState);
  const mainState = useSelector((state) => state);
  useEffect(() => {
    switch (props.type) {
      case "0":
        setType({
          ...type,
          ...{
            color: "brand.warning",
            background: "rgba(255, 152, 0, 0.2)",
            message: "Pendiente de aprobación",
          },
        });
        break;

      case "1":
        setType({
          ...type,
          ...{
            color: "brand.warning",
            background: "rgba(255, 152, 0, 0.2)",
            message: "Pendiente de aprobación",
          },
        });
        break;

      case "2":
        setType({
          ...type,
          ...{
            color: "brand.success",
            background: "rgba(76, 175, 80, 0.2)",
            message: "Aprobado",
          },
        });
        break;

      case "3":
        setType({
          ...type,
          ...{
            color: "brand.error",
            background: "rgba(244, 67, 54, 0.2)",
            message: "Rechazado",
          },
        });
        break;

      default:
        setType({
          ...type,
          ...{
            color: "brand.warning",
            background: "rgba(255, 152, 0, 0.2)",
          },
        });
        break;
    }
  }, []);

  return (
    <Box
      {...props}
      color={type.color}
      background={type.background}
      padding={"8px"}
      borderRadius={"12px"}
      w="fit-content"
    >
      {type.message}
    </Box>
  );
};
