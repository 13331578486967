import React, { useState } from "react";
import { Input, InputRightElement, InputGroup, Button } from "@chakra-ui/react";
import search from "../icons/Search/search.svg";
import { BekeuIcon } from "../../components/BekeuIcon/BekeuIcon";

export const SearchBarBekeu = (props) => {
  const [value, setValue] = useState("");
  return (
    <InputGroup {...props}>
      <Input
        value={value}
        placeholder={props.placeholder}
        onChange={(event) => {
          setValue(event.target.value);
        }}
        bg={"brand.white"}
        h={50}
        borderRadius={12}
        focusBorderColor="brand.primary"
      />
      <InputRightElement
        bg={"brand.acents"}
        borderRightRadius={12}
        h={50}
        w={"fit-content"}
        p={0}
        m={0}
        focusBorderColor="brand.primary"
      >
        <Button
          h={"100%"}
          variant={"ghost"}
          _hover={{ bg: "brand.transparent" }}
          _focus={{
            boxShadow:
              "0 0 1px 2px rgba(122, 57, 162, 1), 0 1px 1px rgba(122, 57, 162, 1)",
          }}
        >
          <BekeuIcon src={search} />
        </Button>
      </InputRightElement>
    </InputGroup>
  );
};
