import React from "react";
import "./CardItem.scss";
import {
  Box,
  Divider,
  Flex,
  Skeleton,
  SkeletonText,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const CardItemLoader = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  function handleScrollTo() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }

  return (
    <Box
      borderRadius={12}
      overflow="hidden"
      boxShadow={"lg"}
      mb={"12px"}
      bg={"white"}
      className="animate__animated animate__fadeIn"
      onClick={() => {
        handleScrollTo();
      }}
    >
      <Flex justifyContent={"center"} p={"12px"} h={"156px"}>
        <Flex overflow="hidden" justifyContent={"center"} alignItems="center">
          <Spinner color="gray.300" size="sm" />
        </Flex>
      </Flex>
      <Divider />
      <Flex
        flexDir={"column"}
        justifyContent="space-between"
        px={"12px"}
        pb={"10px"}
      >
        <SkeletonText noOfLines={1} spacing="1" width={"100%"} mt={"4"} />
        <SkeletonText
          noOfLines={1}
          spacing="1"
          width={`${parseInt(Math.random() * (10 + 50))}%`}
          mt={"2"}
        />
        <Text
          fontSize="14px"
          lineHeight={"19px"}
          color={"#000000"}
          fontWeight="semibold"
          my={"3px"}
        >
          <SkeletonText noOfLines={1} spacing="2" width={"50%"} mt={"4"} />
        </Text>

        <Skeleton
          noOfLines={1}
          spacing="2"
          mt={"4"}
          height="20px"
          borderRadius={"12px"}
        />
      </Flex>
    </Box>
  );
};

export default CardItemLoader;
