import React, { useState } from "react";
import {
  Button,
  Divider,
  Flex,
  Img,
  Text,
  Box,
  useDisclosure,
} from "@chakra-ui/react";
import { H6 } from "../../design-system/headings/H6";
import trash from "../../design-system/icons/Cart/trash.svg";
import { useEffect } from "react";
import { BekeuIcon } from "../BekeuIcon/BekeuIcon";
import { useHistory } from "react-router-dom";
import { ProductDetailModalSeller } from "../ProductDetailModalSeller/ProductDetailModalSeller";
import { InputBekeu } from "../../design-system/inputs/InputBekeu";
import notFoundImage from "../../design-system/images/not-found.png";
import { useSelector } from "react-redux";
import { ErrorModal } from "../ErrorModal/ErrorModal";
import MenuCeco from "./MenuCeco";

export const CartItem = ({
  product,
  onQuantityChange,
  handleRemoveCartItem,
  handleSelectedCeco,
  handleDeleteCeco,
  handleDisabledCeco,
  setEnablePercentage,
  handleApplyAllCecos,
  allCecos,
  index,
  hasApplyAllCecos,
  sapAvailable
}) => {
  //useState
  const [quantity, setQuantity] = useState(product.quantity);
  const [quantityInputEnabled, setQuantityInputEnabled] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ceco, setCeco] = useState("");
  const [userData, setUserData] = useState("");

  //hooks
  const history = useHistory();
  const mainState = useSelector((state) => state);
  const { isOpen, onOpen, onClose } = useDisclosure();

  //variables
  const hasError = `${mainState.cart.result}`;

  //functions
  function handleQuantityChange(e) {
    if (e.target.value === "0") {
      return setQuantity(1);
    } else {
      let validate = e.target.value.replace(/[^0-9]+/g, "");
      return setQuantity((quantity) => (quantity = validate));
    }
  }

  function handleNoProductCart() {
    return history.push(`/detail/${product.sku}`);
  }

  const handleSelectedCecoItem = (obj) => {
    setCeco(obj);
    handleSelectedCeco(obj, product);
  };

  const handleDeleteCecoItem = (obj) => {
    handleDeleteCeco(obj, product);
  };

  // useEffect
  useEffect(() => {
    quantityInputEnabled
      ? onQuantityChange(product, Number(quantity))
      : setQuantityInputEnabled(true);
  }, [quantity]);

  useEffect(() => {
    hasError.includes("error") && onOpen();
  }, [hasError, onOpen]);

  useEffect(() => {
    mainState.cart.result && setLoading(false);
  }, [mainState.cart.result]);

  useEffect(() => {
    setUserData(mainState?.auth?.user?.client);
  }, [mainState.auth.user.client]);

  return (
    <Flex flexDir={"column"}>
      <Divider />
      <ErrorModal isOpen={isOpen} onClose={onClose} codeError={hasError} />
      <Flex>
        <Flex
          w={"100%"}
          justifyContent={"space-between"}
          alignItems="center"
          p={"24px"}
        >
          <Flex w={"50%"} alignItems="center">
            <Box h={"80px"} w={"80px"} mr={6}>
              {product?.error ? (
                <Img
                  p={"10px"}
                  w={"90px"}
                  src={imageError ? notFoundImage : product?.image_source}
                  draggable={"false"}
                  onError={() => setImageError(true)}
                />
              ) : (
                <Img
                  p={"10px"}
                  w={"90px"}
                  src={imageError ? notFoundImage : product?.image_source}
                  draggable={"false"}
                  cursor={"pointer"}
                  onClick={() => handleNoProductCart()}
                  onError={() => setImageError(true)}
                />
              )}
            </Box>
            <Flex flexDir={"column"} w={"80%"} justifyContent={"space-evenly"}>
              {product?.error ? (
                <H6 w={"90%"} transition="300ms ease all">
                  {product?.title}
                </H6>
              ) : (
                <H6
                  w={"90%"}
                  cursor="pointer"
                  transition="300ms ease all"
                  onClick={() => handleNoProductCart()}
                >
                  {product?.title}
                </H6>
              )}
              <ProductDetailModalSeller
                sellerName={product?.fantasy_name}
                email={product?.provider_email}
                phone={product?.provider_phone}
              />
              {product?.error && (
                <Text
                  fontSize={"sm"}
                  mb={4}
                  className="animate__animated animate__fadeIn"
                >
                  Este producto ya no se encuentra disponible para comprar
                </Text>
              )}
            </Flex>
          </Flex>
          <Flex w={"50%"} justifyContent={"space-between"} alignItems="center">
            <Flex gap="24px" w="50%">
              <Flex flexDir={"column"}>
                <Text mb={"12px"}>Cantidad</Text>
                {product?.error ? (
                  <InputBekeu
                    placeholder={1}
                    w={"110px"}
                    type="text"
                    value={quantity}
                    borderColor={"gray"}
                    focusBorderColor="gray.hover"
                    min="0"
                    isReadOnly
                  />
                ) : (
                  <InputBekeu
                    placeholder={1}
                    w={"110px"}
                    type="text"
                    value={quantity}
                    min="0"
                    onChange={handleQuantityChange}
                  />
                )}
              </Flex>
              <MenuCeco
                cecoList={mainState.cart.costCenter}
                product={product}
                cecoSelected={product.cost_centers}
                handleSelectedCeco={handleSelectedCecoItem}
                handleDeleteCecoItem={handleDeleteCecoItem}
                handleDisabledCeco={handleDisabledCeco}
                setEnablePercentage={setEnablePercentage}
                handleApplyAllCecos={handleApplyAllCecos}
                allCecos={allCecos}
                index={index}
                hasApplyAllCecos={hasApplyAllCecos}
                sapAvailable={sapAvailable}
              />
            </Flex>

            <Flex
              justifyContent={"space-between"}
              alignItems="center"
              gap="24px"
              w={"50%"}
            >
              <H6>
                {product?.currency_code} {product?.subtotal} + Imp
              </H6>
              <Button
                variant={"ghost"}
                borderRadius={"12px"}
                m={0}
                mr={2}
                p={0}
                _focus={{
                  boxShadow:
                    "0 0 1px 2px rgba(122, 57, 162, 1), 0 1px 1px rgba(122, 57, 162, 1)",
                }}
                onClick={() => {
                  setLoading(true);
                  handleRemoveCartItem(product);
                }}
                isLoading={loading}
              >
                <BekeuIcon src={trash} />
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
