import { Collapse, Divider, Flex, Img, Spinner, Text } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { H5 } from "../../design-system/headings/H5";
import { H6 } from "../../design-system/headings/H6";
import { Paragraphs } from "../../design-system/headings/Paragraphs";
import { BoxBekeu } from "../../design-system/layout/BoxBekeu";
import { useHistory } from "react-router-dom";
import moment from "moment";
import "moment/locale/es-mx";
import { connect, useSelector } from "react-redux";
import { injectIntl } from "react-intl";
import * as product from "../../pages/productGridPage/ducks/productsGrid.duck";
import * as auth from "../../pages/login/ducks/auth.duck";
import { MyOrdersOrderId } from "../MyOrdersOrderId/MyOrdersOrderId";
import { MyOrdersDetail } from "../MyOrdersDetail/MyOrdersDetail";
import { MyOrdersBadge } from "../MyOrdersBadge/MyOrdersBadge";
import { ProductDetailModalSeller } from "../ProductDetailModalSeller/ProductDetailModalSeller";
import { useState } from "react";
import { MyordersItemBadge } from "../MyOrdersItemBadge/MyOrdersItemBadge";
import { HistoryItem } from "../HistoryItem/HistoryItem";
import { ButtonBekeuLink } from "../../design-system/forms/ButtonBekeuLink";
import arrowDown from "../../design-system/icons/Arrows/arrow-down.svg";
import { MaxDeliveryTimeBadge } from "../MaxDeliveryTimeBadge/MaxDeliveryTimeBadge";
import notFoundImg from "../../design-system/images/not-found.png";

const DetailItem = ({
  orderItem,
  setClaim,
  claim,
  handleConfirmOrderCart,
  handleClaimOrderCart,
  handlePagination,
  noProduct,
  handleNoProduct,
  getHistory,
  item,
  itemArr,
  setFileNameInput,
  fileNameInput,
  getFeatures
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const mainState = useSelector((state) => state);
  const [itemsState, setItemsState] = useState([]);
  const [imageError, setImageError] = useState(false);
  const [sapAvailable, setSapAvailable] = useState(false);
  const [dispatchNoteAvailable, setDispatchNoteAvailable] = useState(false);

  function handleGetHistory() {
    setIsOpen(!isOpen);
    !isOpen && getHistory({ order_id: orderItem.id });
  }

  useEffect(() => {
    if(!mainState.auth.features) getFeatures();
  }, []);

  useEffect(() => {
    // console.log('---', mainState);
    // console.log(mainState?.auth?.features?.find((str) => str === 'SAP'));
    if(mainState?.auth?.features?.find((str) => str === 'SAP')){
      setSapAvailable(true);
    }
    if(mainState?.auth?.features?.find((str) => str === 'dispatch_note')){
      setDispatchNoteAvailable(true);
    }
  }, [mainState.auth.features])

  useEffect(() => {
    setItemsState(
      itemArr &&
        itemArr[0]?.buy_order_items?.map((orderItemVal) => {
          return {
            buy_order_item_id: orderItemVal?.id,
            qty_sent: orderItemVal?.quantity - orderItemVal?.quantity_sent,
            delivery_time: orderItemVal?.days_delivery_time_block,
          };
        })
    );
  }, [itemArr]);

  let newDeliveryArray = [];

  if (orderItem && orderItem.buy_order_items) {
    orderItem.buy_order_items.forEach((x, i) => {
      if (!newDeliveryArray.hasOwnProperty(x.days_delivery_time_block)) {
        newDeliveryArray[x.days_delivery_time_block] = {
          days_delivery_time_block: [],
        };
      }

      newDeliveryArray[
        x.days_delivery_time_block
      ].days_delivery_time_block.push({
        ...x,
        days_delivery_time_block: x.days_delivery_time_block,
      });
    });
  }

  const parsedArs = parseInt(orderItem?.total_ARP) !== 0;
  const parsedUsd = parseInt(orderItem?.total_USD) !== 0;
  const parsedShippingArs = parseInt(orderItem?.shipping_cost_ARP) === 0;
  const parsedShippingUsd = parseInt(orderItem?.shipping_cost_USD) === 0;

  return (
    <Flex flexDir={"column"} justifyContent={"center"} alignItems="center">
      <BoxBekeu
        width={"85%"}
        my={"48px"}
        boxShadow="none"
        border="0.5px solid"
        borderColor="brand.border"
        p={0}
      >
        <Flex flexDir={"column"}>
          <Flex alignItems={"center"} justifyContent={"space-between"} p="24px">
            <ProductDetailModalSeller
              sellerName={orderItem.provider?.fantasy_name}
              email={orderItem.provider?.email}
              phone={orderItem.provider?.phone}
            />
            <MyordersItemBadge item={orderItem}>
              {orderItem?.state === "2"
                ? "Entrega en proceso"
                : orderItem?.state === "3"
                ? "Rechazada"
                : orderItem?.state === "4"
                ? "Entregada"
                : orderItem?.state === "5"
                ? "Cancelada"
                : orderItem?.state === "7"
                ? "Finalizada"
                : orderItem?.state === "9"
                ? "Entrega en proceso"
                : orderItem?.state === "10"
                ? "Finalizada"
                : orderItem?.state === "8" && "Reclamada"}
            </MyordersItemBadge>
          </Flex>
          <Divider />
          <Flex
            alignItems={"center"}
            justifyContent={"space-between"}
            px="24px"
          >
            {orderItem?.currency_code && (
              <Text
                fontWeight={400}
                fontSize={"16px"}
                color="brand.contentSecondary"
                py="24px"
              >
                Importe en {orderItem?.currency_code}
              </Text>
            )}
            {orderItem?.erp_number && (
              <Text
                fontWeight={400}
                fontSize={"16px"}
                border="1px solid"
                borderColor={"brand.contentSecondary"}
                padding={"8px"}
                borderRadius={"12px"}
                color="brand.contentSecondary"
              >
                Nro de Pedido SAP: {orderItem?.erp_number}
              </Text>
            )}
            {orderItem?.internal_code && (
              <Text
                fontWeight={400}
                fontSize={"16px"}
                border="1px solid"
                borderColor={"brand.contentSecondary"}
                padding={"8px"}
                borderRadius={"12px"}
                color="brand.contentSecondary"
              >
                Código interno: {orderItem?.internal_code}
              </Text>
            )}
          </Flex>
          {newDeliveryArray.map((newDeliveryArray, id) => (
            <Flex flexDir={"column"} key={id}>
              <Divider />
              <MaxDeliveryTimeBadge
                days={
                  newDeliveryArray?.days_delivery_time_block[0]
                    ?.days_delivery_time_block
                }
                m="24px"
              />
              {newDeliveryArray?.days_delivery_time_block?.map((i, id) => (
                <div key={id}>
                  <Divider />
                  <Flex justifyContent={"space-between"} p="24px" gap="12px">
                    <Flex>
                      <Flex
                        w="88px"
                        h="88px"
                        mr="20px"
                        border="1px solid"
                        borderColor={"brand.border"}
                        borderRadius={"12px"}
                        overflow="hidden"
                        justifyContent={"center"}
                        alignItems="center"
                        gap="24px"
                      >
                        <Img
                          src={
                            imageError
                              ? notFoundImg
                              : i?.combination?.media?.image_source ||
                                i?.data_combination?.image_source
                          }
                          onError={() => setImageError(true)}
                          draggable={"false"}
                          objectFit="cover"
                          cursor={imageError ? "not-allowed" : "pointer"}
                          onClick={() => handleNoProduct(i)}
                          title={
                            imageError
                              ? "Imagen no disponible"
                              : i?.combination?.title ||
                                i?.data_combination?.title
                          }
                          h={imageError ? "50%" : "100%"}
                        />
                      </Flex>
                      <Flex flexDir={"column"} gap="12px">
                        <Paragraphs
                          lineHeight={"22px"}
                          _hover={{ color: "brand.hover" }}
                          transition="300ms ease all"
                          onClick={() => handleNoProduct(i)}
                          noOfLines={2}
                          cursor="pointer"
                          w={"325px"}
                        >
                          {i?.combination?.title || i?.data_combination?.title}
                        </Paragraphs>
                        <H6>
                          {i?.currency_code} {i?.price_unit} + Imp
                        </H6>
                        <Flex flexDir={"column"}>
                          <Paragraphs
                            lineHeight={"22px"}
                            mb="3px"
                            fontWeight={600}
                          >
                            Centros de costo:
                          </Paragraphs>
                          {i?.cost_centers?.map((item, key) => (
                            <Flex
                              key={key}
                              justifyContent={"space-between"}
                              w="80%"
                            >
                              <Paragraphs lineHeight={"22px"}>
                                {item?.cost_center}
                              </Paragraphs>
                              <Paragraphs lineHeight={"22px"}>
                                {item?.percentage}%
                              </Paragraphs>
                            </Flex>
                          ))}
                        </Flex>
                      </Flex>
                    </Flex>
                    <Flex flexDir={"column"} gap="12px" lineHeight={"22px"}>
                      <Text>Cantidad comprada</Text>
                      <Text>{i?.quantity}</Text>
                    </Flex>
                    <Flex flexDir={"column"} gap="12px" lineHeight={"22px"}>
                      <Text>Cantidad enviada</Text>
                      <Text>{i?.quantity_sent}</Text>
                    </Flex>
                    <Flex flexDir={"column"} gap="12px" lineHeight={"22px"}>
                      <Text>Cantidad confirmada</Text>
                      <Text>{i?.quantity_confirmed}</Text>
                    </Flex>
                    <Flex flexDir={"column"} gap="12px" lineHeight={"22px"}>
                      <Text>Cantidad cancelada</Text>
                      <Text>{i?.quantity_cancel}</Text>
                    </Flex>
                  </Flex>
                  <Flex justifyContent={"flex-end"} px="24px" pb="24px">
                    <ButtonBekeuLink
                      onClick={handleGetHistory}
                      rightIcon={
                        <Img
                          style={{
                            transform: `rotate(${isOpen ? "180deg" : "0deg"})`,
                          }}
                          src={arrowDown}
                        />
                      }
                    >
                      Ver historial
                    </ButtonBekeuLink>
                  </Flex>
                  {/* )} */}
                  <Collapse
                    in={isOpen}
                    animateOpacity
                    style={{
                      overflow: `${isOpen ? "unset" : "hidden"}`,
                      zIndex: 999,
                    }}
                  >
                    {mainState?.carts?.myOrdersHistoryLoading ? (
                      <Flex justifyContent={"center"} alignItems="center">
                        <Spinner marginBottom="44px" />
                      </Flex>
                    ) : (
                      <div
                        style={{
                          marginBottom: "44px",
                        }}
                      >
                        {mainState?.carts?.myOrdersHistory
                          ?.filter(
                            (filterItem) =>
                              filterItem.buy_order_item_id === i.id
                          )
                          .map((note, id) => (
                            <HistoryItem
                              msg={note}
                              key={id}
                              handleConfirmOrderCart={handleConfirmOrderCart}
                              setFileNameInput={setFileNameInput}
                              fileNameInput={fileNameInput}
                              handlePagination={handlePagination}
                              orderItem={orderItem}
                              setClaim={setClaim}
                              handleClaimOrderCart={handleClaimOrderCart}
                              providerName={orderItem.provider?.fantasy_name}
                              claim={claim}
                              myOrders
                              product={i}
                              newDeliveryArray={newDeliveryArray}
                              sapAvailable={sapAvailable}
                              dispatchNoteAvailable={dispatchNoteAvailable}
                            />
                          ))}
                      </div>
                    )}
                  </Collapse>
                </div>
              ))}
            </Flex>
          ))}

          {noProduct && (
            <Text
              fontSize={"sm"}
              mb={4}
              className="animate__animated animate__fadeIn"
            >
              Este producto no se encuentra disponible para comprar nuevamente
            </Text>
          )}
          <Divider />
          <Flex
            flexDir={"column"}
            alignItems="flex-end"
            mt={"16px"}
            gap="12px"
            p="24px"
          >
            {parsedArs && (
              <>
                <Flex justifyContent={"space-between"} gap={"16px"}>
                  <H6 fontWeight={400}>Subtotal productos en ARS:</H6>{" "}
                  <H6 fontWeight={400}>{orderItem?.total_ARP} + Imp</H6>
                </Flex>
                <Flex justifyContent={"space-between"} gap={"16px"}>
                  <H6 fontWeight={400}>Costo de envío en ARS:</H6>
                  {parsedShippingArs ? (
                    <H6 fontWeight={400} color="brand.success">
                      Gratis
                    </H6>
                  ) : (
                    <H6 fontWeight={400}>
                      {orderItem?.shipping_cost_ARP} + Imp
                    </H6>
                  )}
                </Flex>
                <H5>Total en ARS {orderItem?.total_w_shipping_ARP} + Imp</H5>
              </>
            )}
            {parsedUsd && (
              <>
                <Flex justifyContent={"space-between"} gap={"16px"}>
                  <H6 fontWeight={400}>Subtotal productos en USD:</H6>{" "}
                  <H6 fontWeight={400}>{orderItem?.total_USD} + Imp</H6>
                </Flex>
                <Flex justifyContent={"space-between"} gap={"16px"}>
                  <H6 fontWeight={400}>Costo de envío en USD:</H6>
                  {parsedShippingUsd ? (
                    <H6 fontWeight={400} color="brand.success">
                      Gratis
                    </H6>
                  ) : (
                    <H6 fontWeight={400}>
                      {orderItem?.shipping_cost_USD} + Imp
                    </H6>
                  )}
                </Flex>
                <H5>Total en USD {orderItem?.total_w_shipping_USD} + Imp</H5>
              </>
            )}
          </Flex>
        </Flex>
      </BoxBekeu>
    </Flex>
  );
};

const MyOrdersItem = ({
  item,
  type,
  showitems,
  selected,
  status,
  getOneProduct,
  handleConfirmOrderCart,
  handleClaimOrderCart,
  handlePagination,
  user,
  claim,
  setClaim,
  getHistory,
  setFileNameInput,
  fileNameInput,
  getFeatures,
  ...props
}) => {
  const history = useHistory();
  const [totalItems, setTotalItems] = useState(0);
  const [productName, setProductName] = useState("");
  const [noProduct, setNoProduct] = useState(false);

  function handleScrollTo() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }

  function handleNoProduct(i) {
    handleScrollTo();
    if (i.combination) {
      history.push(`/detail/${i?.sku}`);
    } else {
      setNoProduct(!noProduct);
    }
  }

  useEffect(() => {
    let sum = 0;

    if (item?.buy_orders && item?.buy_orders.length > 0) {
      item.buy_orders.map((bo) => {
        bo.buy_order_items.map((m) => {
          setProductName(m.combination?.title || m.data_combination?.title);
          return (sum += m.quantity);
        });
        setTotalItems(sum);
        return sum;
      });
      return;
    }
    if (item?.buy_orders && item?.buy_orders.length === 0) {
      setTotalItems(0);
      return;
    }
  }, [item?.buy_orders]);

  const parsedArs = parseInt(item?.total_ARP) !== 0;
  const parsedUsd = parseInt(item?.total_USD) !== 0;
  const parsedShippingArs = parseInt(item?.shipping_cost_ARP) === 0;
  const parsedShippingUsd = parseInt(item?.shipping_cost_USD) === 0;

  return (
    <BoxBekeu p={0} {...props}>
      <Flex flexDir={"column"}>
        <Flex alignItems={"center"} justifyContent={"space-between"} p="24px">
          <H5>{moment(item?.updated_at).format("LL")}</H5>
          <Flex>
            <MyOrdersOrderId mr={4}>
              Carrito N°{item?.internal_code}
            </MyOrdersOrderId>
            <MyOrdersBadge item={item}>
              {item?.state === "2"
                ? "Pendiente de aprobación"
                : item?.state === "3"
                ? "Entrega en proceso"
                : item?.state === "4"
                ? "Finalizada"
                : item?.state === "8" && "Reclamada"}
            </MyOrdersBadge>
          </Flex>
        </Flex>
        <Divider />
        {showitems && (
          <>
            <Flex justifyContent={"space-between"} p={"24px"}>
              <Flex flexDir={"column"}>
                <Paragraphs fontWeight={600}>Aprobador</Paragraphs>
                <Paragraphs>{user?.authorizer?.user?.email}</Paragraphs>
              </Flex>
              <Flex flexDir={"column"} w="20rem">
                <Paragraphs fontWeight={600}>Zona de entrega</Paragraphs>
                <Paragraphs lineHeight="1.2rem">
                  {item?.address_description}
                </Paragraphs>
              </Flex>
              {/* <Flex flexDir={"column"} w="16rem">
                <Paragraphs fontWeight={600}>Centro de costo</Paragraphs>
                <Flex flexDir={"column"}>
                  {item?.cost_centers?.map((item, index) => (
                    <Flex w="100%" justifyContent={"space-between"}>
                      <Paragraphs key={index} lineHeight="1.2rem">
                        {item.cost_center}
                      </Paragraphs>
                      <Paragraphs key={index} lineHeight="1.2rem">
                        {item.percentage}%
                      </Paragraphs>
                    </Flex>
                  ))}
                </Flex>
              </Flex> */}
              <Flex flexDir={"column"}>
                <Paragraphs fontWeight={600}>Total de productos </Paragraphs>
                <Paragraphs>{item?.buy_orders && totalItems}</Paragraphs>
              </Flex>
            </Flex>
          </>
        )}
        <MyOrdersDetail
          item={item}
          showitems={showitems}
          totalItems={totalItems}
          productName={productName}
        />
        <Divider />
        {showitems && (
          <Flex justifyContent={"center"} alignItems="center">
            <BoxBekeu
              width={"85%"}
              my={"48px"}
              boxShadow="none"
              border="0.5px solid"
              borderColor="brand.border"
              p="0"
            >
              <H6 p="24px">Totales en ARS y USD</H6>
              <Divider />
              <Flex justifyContent={"space-between"} height="200px" p="24px">
                {parsedArs && (
                  <Flex flexDir={"column"} justifyContent={"space-between"}>
                    <Flex flexDir={"column"} gap="8px">
                      <H6>Total productos en ARS</H6>
                      <H6 fontWeight={400}>{item?.total_ARP} + Imp</H6>
                    </Flex>
                    <Flex flexDir={"column"} gap="8px">
                      <H6>Total costo de envío en ARS</H6>
                      {parsedShippingArs ? (
                        <H6 fontWeight={600} color="brand.success">
                          Envío gratis
                        </H6>
                      ) : (
                        <H6 fontWeight={400}>
                          {item?.shipping_cost_ARP} + Imp
                        </H6>
                      )}
                    </Flex>
                  </Flex>
                )}
                {parsedUsd && (
                  <Flex flexDir={"column"} justifyContent={"space-between"}>
                    <Flex flexDir={"column"} gap="8px">
                      <H6>Total productos en USD</H6>
                      <H6 fontWeight={400}>{item?.total_USD} + Imp</H6>
                    </Flex>
                    <Flex flexDir={"column"} gap="8px">
                      <H6>Total costo de envío USD</H6>
                      {parsedShippingUsd ? (
                        <H6 fontWeight={600} color="brand.success">
                          Envío gratis
                        </H6>
                      ) : (
                        <H6 fontWeight={400}>
                          {item?.shipping_cost_USD} + Imp
                        </H6>
                      )}
                    </Flex>
                  </Flex>
                )}
              </Flex>
            </BoxBekeu>
          </Flex>
        )}
        {showitems &&
          item?.buy_orders &&
          item.buy_orders.map((orderItem, id, itemArr) => (
            <DetailItem
              handleConfirmOrderCart={handleConfirmOrderCart}
              handleClaimOrderCart={handleClaimOrderCart}
              key={id}
              orderItem={orderItem}
              getOneProduct={getOneProduct}
              history={history}
              setTotalItems={setTotalItems}
              setProductName={setProductName}
              handlePagination={handlePagination}
              setClaim={setClaim}
              claim={claim}
              noProduct={noProduct}
              handleNoProduct={handleNoProduct}
              getHistory={getHistory}
              itemArr={itemArr}
              setFileNameInput={setFileNameInput}
              fileNameInput={fileNameInput}
              getFeatures={getFeatures}
            />
          ))}
      </Flex>
    </BoxBekeu>
  );
};

export default injectIntl(connect(null, {...product.actions, ...auth.actions})(MyOrdersItem));