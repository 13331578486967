import { Divider, Flex, Text, Tooltip, Box } from "@chakra-ui/react";
import React from "react";
import info from "../../design-system/icons/Shapes/info-circle-dark.svg";
import box from "../../design-system/icons/Essentials/box.svg";

import { BekeuIcon } from "../BekeuIcon/BekeuIcon";

const ShippingText = ({ children, ...props }) => {
  return (
    <Text
      fontWeight={600}
      fontSize={"14px"}
      isTruncated
      lineHeight={"19px"}
      fontStyle="normal"
      fontFamily={"Open Sans"}
      {...props}
    >
      {children}
    </Text>
  );
};

const CustomCard = React.forwardRef(({ children, ...rest }, ref) => (
  <BekeuIcon src={info} ml="10px" ref={ref} {...rest} />
));

export const ShippingTitle = ({ item, infobtn }) => {
  return (
    <>
      <Flex
        w="100%"
        alignItems={"center"}
        justifyContent="space-between"
        gap="12px"
      >
        <ShippingText w="8%" textAlign="center">
          {item?.state}
        </ShippingText>
        <ShippingText w="10%" textAlign={"center"} title={item?.zone}>
          {item?.zone}
        </ShippingText>
        <ShippingText w="8%" textAlign={"center"}>
          {item?.country}
        </ShippingText>
        <Tooltip
          closeDelay={200}
          label={"El tiempo de entrega corresponden a días hábiles"}
          bg="white"
          color="black"
          borderRadius={"12px"}
          w={"85%"}
          p={"12px"}
          borderWidth={"1px"}
          borderTopLeftRadius={"0px"}
          marginLeft="100px"
          placement={"bottom-start"}
          overflow="hidden"
        >
          <Flex alignItems={"center"} w={"10%"} isTruncated>
            <Text
              fontWeight={600}
              fontSize={"14px"}
              isTruncated
              lineHeight={"19px"}
              fontStyle="normal"
            >
              {item?.deliveryTime}
            </Text>
            <CustomCard />
          </Flex>
        </Tooltip>
        <ShippingText w="8%">
          <Flex alignItems={"center"} justifyContent={"center"}>
            <BekeuIcon src={box} mr="8px" /> {item?.T1}
          </Flex>
        </ShippingText>
        <ShippingText w="8%">
          <Flex alignItems={"center"} justifyContent={"center"}>
            <BekeuIcon src={box} mr="8px" /> {item?.T2}
          </Flex>
        </ShippingText>
        <ShippingText w="8%">
          <Flex alignItems={"center"} justifyContent={"center"}>
            <BekeuIcon src={box} mr="8px" /> {item?.T3}
          </Flex>
        </ShippingText>
        <ShippingText w="8%">
          <Flex alignItems={"center"} justifyContent={"center"}>
            <BekeuIcon src={box} mr="8px" /> {item?.T4}
          </Flex>
        </ShippingText>
        <ShippingText w="8%">
          <Flex alignItems={"center"} justifyContent={"center"}>
            <BekeuIcon src={box} mr="8px" /> {item?.T5}
          </Flex>
        </ShippingText>
        <ShippingText w="8%">
          <Flex alignItems={"center"} justifyContent={"center"}>
            <BekeuIcon src={box} mr="8px" /> {item?.T6}
          </Flex>
        </ShippingText>
        <ShippingText w="8%">
          <Flex alignItems={"center"} justifyContent={"center"}>
            <BekeuIcon src={box} mr="8px" /> {item?.T7}
          </Flex>
        </ShippingText>
        <Box>{infobtn}</Box>
      </Flex>
      <Divider />
    </>
  );
};
