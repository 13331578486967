import { Alert, Text } from "@chakra-ui/react";
import React from "react";
import { BekeuIcon } from "../../components/BekeuIcon/BekeuIcon";
import infoCircle from "../../design-system/icons/Shapes/info-circle.svg";

export const AlertBekeu = ({ children, ...props }) => {
  return (
    <Alert
      status="info"
      borderRadius={"12px"}
      borderWidth={"1px"}
      borderColor={"brand.info"}
      backgroundColor="#2196F333"
      padding="10px"
      gap="10px"
      width={"fit-content"}
      {...props}
    >
      <BekeuIcon src={infoCircle} />
      <Text
        fontSize={"1rem"}
        fontWeight={400}
        lineHeight="1.375rem"
        fontFamily={"Open Sans"}
      >
        {children}
      </Text>
    </Alert>
  );
};
