import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  useDisclosure,
  SkeletonText,
  Flex,
  Collapse,
} from "@chakra-ui/react";
import { ButtonBekeuLink } from "../../design-system/forms/ButtonBekeuLink";
import { H5 } from "../../design-system/headings/H5";
import { Paragraphs } from "../../design-system/headings/Paragraphs";
import { CheckboxBekeu } from "../../design-system/inputs/Checkbox";
import { ButtonBekeuLinkM } from "../../design-system/forms/ButtonBekeuLinkM";
import useQuery from "../../hooks/useQuery";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { InputBekeu } from "../../design-system/inputs/InputBekeu";
import { BekeuIcon } from "../BekeuIcon/BekeuIcon";
import checkPrimary from "../../design-system/icons/Shipping/success-circle-primary.svg";

export const ProductsGridFilterBox = ({
  listProductsGrid,
  listProductsGridsFilters,
  listProductsGridFilters,
  skeletonLoading,
  isVisible,
  idTag,
  counterTag,
  tagItem,
}) => {
  // Main state
  const storageFiltersVar = localStorage.getItem("storageFilters");

  // Hooks
  const query = useQuery();
  const history = useHistory();
  const brandToggle = useDisclosure();
  const { isOpen, onToggle } = useDisclosure();

  //useQuery variables
  const category_id = query.get("category_id");
  const page = Number(query.get("page"));
  const perPage = Number(query.get("per_page"));
  const term = query.get("term");
  const fl = query.get("fl");

  let searchVariable = ``;
  if (page) {
    searchVariable += `?page=1`;
  }
  if (perPage) {
    searchVariable += `&per_page=${perPage}`;
  }
  if (category_id) {
    searchVariable += `&category_id=${category_id}`;
  }
  if (term) {
    searchVariable += `&term=${term}`;
  }
  searchVariable += `&fl=0`;

  const parsedStorageFilters =
    storageFiltersVar && JSON.parse(storageFiltersVar);

  // useState
  const [counter, setCounter] = useState(0);
  const [totalStates, setTotalStates] = useState({
    category_id:
      parsedStorageFilters?.categories.category_id ||
      (category_id ? [category_id] : []),
    brand_id: parsedStorageFilters?.brands.brand_id || [],
    delivery_zone: parsedStorageFilters?.delivery_zones.delivery_zone_id || [],
    price: [
      listProductsGridsFilters.precios?.minimo,
      listProductsGridsFilters.precios?.maximo,
    ],
    delivery_days: [
      listProductsGridsFilters.tiempo_entrega?.minimo,
      listProductsGridsFilters.tiempo_entrega?.maximo,
    ],
    page: 1,
    perPage,
    term,
  });
  const [storageFilters, setStorageFilters] = useState(
    JSON.parse(storageFiltersVar) || {
      isFirstLoad: true,
      categories: { category_name: [], category_id: [] },
      brands: { brand_name: [], brand_id: [] },
      delivery_zones: { delivery_zone_name: [], delivery_zone_id: [] },
      price: [],
      delivery_days: [],
    }
  );

  const parsedFilters = JSON.parse(storageFiltersVar);
  const filterValidation =
    parsedFilters?.categories?.category_id?.length !== 0 ||
    parsedFilters?.brands?.brand_id?.length !== 0 ||
    parsedFilters?.delivery_zones?.delivery_zone_id?.length !== 0;

  const [cleanFilters, setCleanFilters] = useState(filterValidation);

  // ----- useEffect ------
  // setting range inputs

  useEffect(() => {
    tagItem?.type === "category" && handleChangeFilters(tagItem);
    tagItem?.type === "brand" && handleChangeBrand(tagItem);
    tagItem?.type === "delivery_zone" && handleChangeDeliveryZone(tagItem);
    tagItem?.type === "price" &&
      handleClickViewResult(tagItem.type, "removeTag");
    tagItem?.type === "delivery_days" &&
      handleClickViewResult(tagItem.type, "removeTag");
  }, [tagItem]);

  useEffect(() => {
    setTotalStates({
      ...totalStates,
      price: [
        listProductsGridsFilters.precios?.minimo,
        listProductsGridsFilters.precios?.maximo,
      ],
      delivery_days: [
        listProductsGridsFilters.tiempo_entrega?.minimo,
        listProductsGridsFilters.tiempo_entrega?.maximo,
      ],
    });
  }, [listProductsGridsFilters]);

  // updating local storage filters
  useEffect(() => {
    storageFilters &&
      localStorage.setItem("storageFilters", JSON.stringify(storageFilters));
  }, [storageFilters]);

  // setting tag filters
  useEffect(() => {
    idTag && handleChangeFilters(idTag);
  }, [idTag, counterTag]);

  //Function to remove item from array
  function removeItemFromArr(arr, item) {
    let i = arr.indexOf(item);
    if (i !== -1) {
      arr.splice(i, 1);
    }
    setCounter(counter + 1);
  }

  // function to scroll to top
  function handleScrollTo() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }

  // function for query params search
  function handleSearchVariable() {
    history.push(searchVariable);
  }

  // this variables validates if there's an array item
  const stateValidation =
    totalStates.category_id.length !== 0 ||
    totalStates.brand_id.length !== 0 ||
    totalStates.delivery_zone.length !== 0;

  // v initial state validation
  const deliveryDaysValidation =
    totalStates.delivery_days[0] !== 999 &&
    totalStates.delivery_days[1] !== 999;

  // this useEffect updates when a totalState variable changes and calls filters API
  useEffect(() => {
    if (
      totalStates?.category_id.length === 0 &&
      totalStates?.brand_id.length === 0 &&
      totalStates?.delivery_zone.length === 0
    ) {
      setCleanFilters(false);
    }
  }, [
    totalStates,
    category_id,
    listProductsGridFilters,
    term,
    page,
    stateValidation,
  ]);

  // function to remove filters from box
  function removeFilterValidation() {
    if (filterValidation) {
      setCleanFilters(false);
      listProductsGridFilters({
        term,
        category_id: category_id ? [category_id] : totalStates?.category_id,
        brand_id: totalStates?.brand_id,
        delivery_zone: totalStates?.delivery_zone,
      });
    }
  }

  // function for setting localStorage state
  function setGlobalStorage(reset) {
    localStorage.setItem(
      "globalStorage",
      JSON.stringify({
        isFirstLoad: true,
        page: !reset && 1,
      })
    );
  }

  // this function triggers when the user clicks on the filter button
  function handleChangeFilters(filter) {
    if (totalStates.category_id.includes(filter.id)) {
      removeItemFromArr(totalStates?.category_id, filter.id);
      removeItemFromArr(storageFilters.categories?.category_name, filter.name);
      removeItemFromArr(storageFilters.categories?.category_id, filter.id);
      if (totalStates?.category_id.length === 0) {
        setTotalStates({ ...totalStates, category_id: [] });
      }
      setTotalStates({ ...totalStates, category_id: totalStates?.category_id });
      listProductsGrid({
        term,
        page: totalStates?.page,
        perPage: totalStates?.perPage,
        brand_id: totalStates?.brand_id,
        delivery_zone: totalStates?.delivery_zone,
        category_id: category_id ? [category_id] : totalStates?.category_id,
      });
      setStorageFilters({
        ...storageFilters,
        categories: {
          category_name: storageFilters.categories?.category_name,
          category_id: storageFilters.categories?.category_id,
          categoriesTag: [],
        },
        isFirstLoad: false,
      });
      removeFilterValidation();
    } else {
      setCleanFilters(true);
      setTotalStates({
        ...totalStates,
        category_id: [...totalStates?.category_id, filter.id],
      });
      setStorageFilters({
        ...storageFilters,
        categories: {
          category_name: [
            ...storageFilters.categories?.category_name,
            filter.name,
          ],
          category_id: [...totalStates?.category_id, filter.id],
          categoriesTag: [
            {
              category_name: [
                ...storageFilters.categories?.category_name,
                filter.name,
              ],
              category_id: [...totalStates?.category_id, filter.id],
            },
          ],
        },
        isFirstLoad: false,
      });
      listProductsGrid({
        ...totalStates,
        category_id: [...totalStates?.category_id, filter.id],
      });
      setGlobalStorage();
      listProductsGridFilters({
        term,
        category_id: [...totalStates?.category_id, filter.id],
        brand_id: totalStates.brand_id,
        delivery_zone: totalStates.delivery_zone,
      });
    }
    setCounter(counter + 1);
    handleScrollTo();
    setTimeout(() => {
      page !== 1 && handleSearchVariable();
    }, 1);
  }

  function handleChangeBrand(brand) {
    if (totalStates.brand_id.includes(brand.id)) {
      removeItemFromArr(totalStates?.brand_id, brand.id);
      removeItemFromArr(storageFilters.brands?.brand_name, brand.name);
      removeItemFromArr(storageFilters.brands?.brand_id, brand.id);
      if (totalStates.brand_id.length === 0) {
        setTotalStates({ ...totalStates, brand_id: [] });
      }
      setTotalStates({ ...totalStates, brand_id: totalStates?.brand_id });
      listProductsGrid({
        term,
        page: totalStates?.page,
        perPage: totalStates?.perPage,
        delivery_zone: totalStates?.delivery_zone,
        category_id: totalStates?.category_id,
        brand_id: totalStates?.brand_id,
      });
      setStorageFilters({
        ...storageFilters,
        brands: {
          brand_name: storageFilters.brands?.brand_name,
          brand_id: storageFilters.brands?.brand_id,
          brandsTag: [],
        },
        isFirstLoad: false,
      });
      removeFilterValidation();
    } else {
      setCleanFilters(true);
      setTotalStates({
        ...totalStates,
        brand_id: [...totalStates?.brand_id, brand.id],
      });
      setStorageFilters({
        ...storageFilters,
        brands: {
          brand_name: [...storageFilters.brands?.brand_name, brand.name],
          brand_id: [...totalStates?.brand_id, brand.id],
          brandsTag: [
            {
              brand_name: [...storageFilters.brands?.brand_name, brand.name],
              brand_id: [...totalStates?.brand_id, brand.id],
            },
          ],
        },
        isFirstLoad: false,
      });
      listProductsGrid({
        ...totalStates,
        brand_id: [...totalStates?.brand_id, brand.id],
      });
      setGlobalStorage();
      listProductsGridFilters({
        term,
        category_id: totalStates.category_id,
        brand_id: [...totalStates?.brand_id, brand.id],
        delivery_zone: totalStates.delivery_zone,
      });
    }
    setCounter(counter + 1);
    handleScrollTo();
    setTimeout(() => {
      page !== 1 && handleSearchVariable();
    }, 1);
  }

  function handleChangeDeliveryZone(delivery_zone) {
    if (totalStates.delivery_zone.includes(delivery_zone.id)) {
      removeItemFromArr(totalStates?.delivery_zone, delivery_zone.id);
      removeItemFromArr(
        storageFilters.delivery_zones?.delivery_zone_name,
        delivery_zone.name
      );
      if (totalStates?.delivery_zone.length === 0) {
        setTotalStates({ ...totalStates, delivery_zone: [] });
      }
      setTotalStates({
        ...totalStates,
        delivery_zone: [],
      });
      listProductsGrid({
        term,
        page: totalStates?.page,
        perPage: totalStates?.perPage,
        delivery_zone: totalStates?.delivery_zone,
        category_id: totalStates?.category_id,
        brand_id: totalStates?.brand_id,
      });
      setStorageFilters({
        ...storageFilters,
        delivery_zones: {
          delivery_zone_name: storageFilters.delivery_zones?.delivery_zone_name,
        },
        isFirstLoad: false,
      });
      removeFilterValidation();
    } else {
      setCleanFilters(true);
      setTotalStates({
        ...totalStates,
        delivery_zone: [...totalStates?.delivery_zone, delivery_zone.id],
      });
      setStorageFilters({
        ...storageFilters,
        delivery_zones: {
          delivery_zone_name: [
            ...storageFilters.delivery_zones?.delivery_zone_name,
            delivery_zone.name,
          ],
          delivery_zone_id: [...totalStates?.delivery_zone, delivery_zone.id],
          delivery_zone_tag: [
            {
              delivery_zone_name: [
                ...storageFilters.delivery_zone?.delivery_zone_name,
                delivery_zone.name,
              ],
              delivery_zone_id: [
                ...totalStates?.delivery_zone,
                delivery_zone.id,
              ],
            },
          ],
        },
        isFirstLoad: false,
      });
      listProductsGrid({
        ...totalStates,
        delivery_zone: [...totalStates?.delivery_zone, delivery_zone.id],
      });
      setGlobalStorage();
      listProductsGridFilters({
        term,
        category_id: totalStates.category_id,
        brand_id: totalStates.brand_id,
        delivery_zone: [...totalStates?.delivery_zone, delivery_zone.id],
      });
    }
    setCounter(counter + 1);
    handleScrollTo();
    setTimeout(() => {
      page !== 1 && handleSearchVariable();
    }, 1);
  }

  const handleClickViewResult = (type, removeTag) => {
    if (removeTag) {
      listProductsGrid({
        category_id: totalStates.category_id,
        brand_id: totalStates.brand_id,
        delivery_zone: totalStates.delivery_zone,
        page: 1,
        perPage,
        term,
      });
      listProductsGridFilters({
        category_id: totalStates.category_id,
        brand_id: totalStates.brand_id,
        delivery_zone: totalStates.delivery_zone,
        page,
        perPage,
        term,
      });
      type === "price" &&
        setStorageFilters({
          ...storageFilters,
          price: [],
          isFirstLoad: false,
        });
      type === "delivery_days" &&
        setStorageFilters({
          ...storageFilters,
          delivery_days: [],
          isFirstLoad: false,
        });
    } else {
      if (type === "price" && deliveryDaysValidation) {
        setStorageFilters({
          ...storageFilters,
          price: [{ min: totalStates.price[0], max: totalStates.price[1] }],
          delivery_days: [
            {
              min: totalStates.delivery_days[0],
              max: totalStates.delivery_days[1],
            },
          ],
          isFirstLoad: false,
        });
        listProductsGrid({
          ...totalStates,
          page: 1,
          perPage,
          term,
        });
        listProductsGridFilters(totalStates);
      } else if (!deliveryDaysValidation && type === "price") {
        setStorageFilters({
          ...storageFilters,
          delivery_days: [],
          price: [{ min: totalStates.price[0], max: totalStates.price[1] }],
          isFirstLoad: false,
        });
        listProductsGrid({
          ...totalStates,
          delivery_days: [],
          page: 1,
          perPage,
          term,
        });
        listProductsGridFilters({ ...totalStates, delivery_days: [] });
      } else if (type === "delivery_days") {
        listProductsGrid({
          ...totalStates,
          page: 1,
          perPage,
          term,
        });
        setStorageFilters({
          ...storageFilters,
          price: [{ min: totalStates.price[0], max: totalStates.price[1] }],
          delivery_days: [
            {
              min: totalStates.delivery_days[0],
              max: totalStates.delivery_days[1],
            },
          ],
          isFirstLoad: false,
        });
        listProductsGridFilters(totalStates);
      }
    }
    setCleanFilters(true);
    handleScrollTo();
    setTimeout(() => {
      page !== 1 && handleSearchVariable();
    }, 1);
  };

  const handleClickCleanFilters = () => {
    setCleanFilters(false);
    setTotalStates({
      ...totalStates,
      category_id: [],
      brand_id: [],
      delivery_zone: [],
      price: [
        listProductsGridsFilters.precios?.minimo,
        listProductsGridsFilters.precios?.maximo,
      ],
      delivery_days: [
        listProductsGridsFilters.tiempo_entrega?.minimo,
        listProductsGridsFilters.tiempo_entrega?.maximo,
      ],
    });
    listProductsGrid({
      category_id: category_id ? [category_id] : [],
      brand_id: [],
      delivery_zone: [],
      delivery_days: [],
      page: 1,
      perPage,
      term,
    });
    setStorageFilters({
      isFirstLoad: false,
      categories: {
        category_name: [],
        category_id: category_id ? [category_id] : [],
      },
      brands: { brand_name: [], brand_id: [] },
      delivery_zones: { delivery_zone_name: [], delivery_zone_id: [] },
      price: [],
      delivery_days: [],
    });
    listProductsGridFilters({
      term,
      category_id: category_id ? [category_id] : [],
      brand_id: [],
      delivery_zone: [],
    });
    handleScrollTo();
    setGlobalStorage("reset");
    setTimeout(() => {
      page !== 1 && handleSearchVariable();
    }, 1);
  };

  return (
    <Box
      h={"fit-content"}
      w={"307px"}
      borderRadius={12}
      overflow="hidden"
      boxShadow={"lg"}
      bg={"white"}
      p={"24px"}
      mr={"40px"}
      display={!isVisible ? "inherit" : "none"}
      className={
        !fl ? `animate__animated animate__fadeIn` : `animate__animated`
      }
    >
      <Flex flexDir={"column"} width="100%">
        <Flex
          justifyContent={"space-between"}
          alignItems={"center"}
          mb={"10px"}
        >
          <H5>Filtrar</H5>
          {cleanFilters && (
            <ButtonBekeuLink
              onClick={handleClickCleanFilters}
              className="animate__animated animate__fadeIn"
            >
              Limpiar filtros
            </ButtonBekeuLink>
          )}
        </Flex>
        <Flex flexDir={"column"} mb="10px" pb="10px">
          <Paragraphs>Categorías</Paragraphs>
          {skeletonLoading ? (
            <Flex flexDir={"column"} height="100%" justifyContent={"center"}>
              <SkeletonText noOfLines={1} spacing="2" width={"50%"} mt={"4"} />
              <SkeletonText noOfLines={1} spacing="2" width={"20%"} mt={"4"} />
              <SkeletonText noOfLines={1} spacing="2" width={"25%"} mt={"4"} />
            </Flex>
          ) : (
            <>
              {listProductsGridsFilters.categorias &&
                listProductsGridsFilters.categorias.map((category, index) => {
                  return (
                    index < 4 && (
                      <CheckboxBekeu
                        key={index}
                        checked={totalStates.category_id.includes(category.id)}
                        onChange={() => handleChangeFilters(category)}
                        className="animate__animated animate__fadeIn"
                      >
                        {category?.name}
                      </CheckboxBekeu>
                    )
                  );
                })}
              <Flex flexDir={"column"}>
                {listProductsGridsFilters.categorias &&
                  listProductsGridsFilters.categorias.length > 4 && (
                    <ButtonBekeuLinkM onClick={brandToggle.onToggle} my={1}>
                      Mostrar {!brandToggle.isOpen ? "más" : "menos"}
                    </ButtonBekeuLinkM>
                  )}
                <Collapse in={brandToggle.isOpen} animateOpacity>
                  {listProductsGridsFilters.categorias &&
                    listProductsGridsFilters.categorias.map(
                      (category, index) => {
                        return (
                          index >= 4 && (
                            <CheckboxBekeu
                              key={index}
                              checked={totalStates.category_id.includes(
                                category.id
                              )}
                              onChange={() => handleChangeFilters(category)}
                            >
                              {category?.name}
                            </CheckboxBekeu>
                          )
                        );
                      }
                    )}
                </Collapse>
              </Flex>
            </>
          )}
        </Flex>
        <Flex flexDir={"column"} mb="10px" pb="10px">
          <Paragraphs>Marca</Paragraphs>
          {skeletonLoading ? (
            <Flex flexDir={"column"} height="100%" justifyContent={"center"}>
              <SkeletonText noOfLines={1} spacing="2" width={"20%"} mt={"4"} />
              <SkeletonText noOfLines={1} spacing="2" width={"25%"} mt={"4"} />
              <SkeletonText noOfLines={1} spacing="2" width={"50%"} mt={"4"} />
            </Flex>
          ) : (
            <>
              {listProductsGridsFilters.marcas &&
                listProductsGridsFilters.marcas.map((brand, index) => {
                  return (
                    index < 4 && (
                      <CheckboxBekeu
                        key={index}
                        checked={totalStates.brand_id.includes(brand.id)}
                        onChange={() => handleChangeBrand(brand)}
                      >
                        {brand?.name}
                      </CheckboxBekeu>
                    )
                  );
                })}
              <Flex flexDir={"column"}>
                {listProductsGridsFilters.marcas &&
                  listProductsGridsFilters.marcas.length > 4 && (
                    <ButtonBekeuLinkM onClick={onToggle} my={1}>
                      Mostrar {!isOpen ? "más" : "menos"}
                    </ButtonBekeuLinkM>
                  )}
                <Collapse in={isOpen} animateOpacity>
                  {listProductsGridsFilters.marcas &&
                    listProductsGridsFilters.marcas.map((brand, index) => {
                      return (
                        index >= 4 && (
                          <CheckboxBekeu
                            key={index}
                            checked={totalStates.brand_id.includes(brand.id)}
                            onChange={() => handleChangeBrand(brand)}
                          >
                            {brand?.name}
                          </CheckboxBekeu>
                        )
                      );
                    })}
                </Collapse>
              </Flex>
            </>
          )}
        </Flex>

        <Flex flexDir={"column"} mb={"4"}>
          <Paragraphs>Precios</Paragraphs>
          {skeletonLoading ? (
            <Flex flexDir={"column"} height="100%" justifyContent={"center"}>
              <SkeletonText noOfLines={1} spacing="2" width={"100%"} my={"4"} />
            </Flex>
          ) : (
            <Flex w="100%" gap={1} flexDir={"column"} mt={1}>
              <Flex alignItems="center">
                <Text
                  fontSize={"14px"}
                  w="50%"
                  color={"brand.contentSecondary"}
                >
                  Mínimo
                </Text>
                <Text
                  fontSize={"14px"}
                  w="50%"
                  color={"brand.contentSecondary"}
                >
                  Máximo
                </Text>
              </Flex>
              <Flex gap={2} alignItems="center">
                <Flex alignItems="center" gap={2}>
                  <InputBekeu
                    grouph="38px"
                    height="38px"
                    fontSize="14px"
                    placeholder={listProductsGridsFilters.precios.minimo}
                    value={totalStates.price[0]}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        handleClickViewResult("price");
                      }
                    }}
                    onChange={(e) =>
                      setTotalStates({
                        ...totalStates,
                        price: [
                          parseInt(e.target.value.replace(/[^0-9,.]/g, "")),
                          totalStates.price[1],
                        ],
                      })
                    }
                  />
                  <Text color="brand.border">-</Text>
                </Flex>
                <Flex alignItems="center" gap={2}>
                  <InputBekeu
                    grouph="38px"
                    height="38px"
                    fontSize="14px"
                    placeholder={listProductsGridsFilters.precios.maximo}
                    value={totalStates.price[1]}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        handleClickViewResult("price");
                      }
                    }}
                    onChange={(e) =>
                      setTotalStates({
                        ...totalStates,
                        price: [
                          totalStates.price[0],
                          parseInt(e.target.value.replace(/[^0-9,.]/g, "")),
                        ],
                      })
                    }
                  />
                  <BekeuIcon
                    src={checkPrimary}
                    h="24px"
                    w="24px"
                    onClick={() => handleClickViewResult("price")}
                    cursor="pointer"
                  />
                </Flex>
              </Flex>
            </Flex>
          )}
        </Flex>

        <Flex flexDir={"column"}>
          <Flex flexDir={"column"}>
            <Paragraphs>Zona de Entrega</Paragraphs>
            {skeletonLoading ? (
              <Flex flexDir={"column"} height="100%" justifyContent={"center"}>
                <SkeletonText
                  noOfLines={1}
                  spacing="2"
                  width={"20%"}
                  mt={"4"}
                />
                <SkeletonText
                  noOfLines={1}
                  spacing="2"
                  width={"50%"}
                  mt={"4"}
                />
                <SkeletonText
                  noOfLines={1}
                  spacing="2"
                  width={"25%"}
                  mt={"4"}
                />
              </Flex>
            ) : (
              listProductsGridsFilters.regiones &&
              listProductsGridsFilters.regiones.map((deliveryZone, id) => (
                <CheckboxBekeu
                  key={id}
                  checked={totalStates.delivery_zone.includes(deliveryZone.id)}
                  onChange={() => handleChangeDeliveryZone(deliveryZone)}
                >
                  {deliveryZone?.name}
                </CheckboxBekeu>
              ))
            )}
          </Flex>
        </Flex>

        {deliveryDaysValidation && (
          <Flex flexDir={"column"} my={"4"}>
            <Paragraphs>Tiempos de Entrega</Paragraphs>
            {skeletonLoading ? (
              <Flex flexDir={"column"} height="100%" justifyContent={"center"}>
                <SkeletonText
                  noOfLines={1}
                  spacing="2"
                  width={"100%"}
                  my={"4"}
                />
              </Flex>
            ) : (
              <Flex w="100%" gap={1} flexDir={"column"} mt={1}>
                <Flex alignItems="center">
                  <Text
                    fontSize={"14px"}
                    w="50%"
                    color={"brand.contentSecondary"}
                  >
                    Mínimo
                  </Text>
                  <Text
                    fontSize={"14px"}
                    w="50%"
                    color={"brand.contentSecondary"}
                  >
                    Máximo
                  </Text>
                </Flex>
                <Flex gap={2} alignItems="center">
                  <Flex alignItems="center" gap={2}>
                    <InputBekeu
                      grouph="38px"
                      height="38px"
                      fontSize="14px"
                      placeholder={
                        listProductsGridsFilters.tiempo_entrega.minimo
                      }
                      value={totalStates.delivery_days[0]}
                      title={`${totalStates.delivery_days[0]} días`}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          handleClickViewResult("delivery_days");
                        }
                      }}
                      onChange={(e) =>
                        setTotalStates({
                          ...totalStates,
                          delivery_days: [
                            parseInt(e.target.value.replace(/[^0-9,.]/g, "")),
                            totalStates.delivery_days[1],
                          ],
                        })
                      }
                    />
                    <Text color="brand.border">-</Text>
                  </Flex>
                  <Flex alignItems="center" gap={2}>
                    <InputBekeu
                      grouph="38px"
                      height="38px"
                      fontSize="14px"
                      placeholder={
                        listProductsGridsFilters.tiempo_entrega.maximo
                      }
                      value={totalStates.delivery_days[1]}
                      title={`${totalStates.delivery_days[1]} días`}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          handleClickViewResult("delivery_days");
                        }
                      }}
                      onChange={(e) =>
                        setTotalStates({
                          ...totalStates,
                          delivery_days: [
                            totalStates.delivery_days[0],
                            parseInt(e.target.value.replace(/[^0-9,.]/g, "")),
                          ],
                        })
                      }
                    />
                    <BekeuIcon
                      src={checkPrimary}
                      h="24px"
                      w="24px"
                      onClick={() => handleClickViewResult("delivery_days")}
                      cursor="pointer"
                    />
                  </Flex>
                </Flex>
              </Flex>
            )}
          </Flex>
        )}
      </Flex>
    </Box>
  );
};
