import React from "react";
import { Tag, TagCloseButton, Text } from "@chakra-ui/react";

const TagFilters = ({ tagName, handleRemoveTag }) => {
  return (
    <Tag
      borderRadius="12px"
      borderColor={"brand.content3"}
      borderWidth="1px"
      mr="16px"
      mb="24px"
      h="52px"
      p="14px"
      bg="white"
      w={"fit-content"}
    >
      <Text
        color="brand.contentSecondary"
        fontSize={"16px"}
        lineHeight="22px"
        fontWeight={300}
      >
        {tagName}
      </Text>
      <TagCloseButton
        onClick={handleRemoveTag}
        _focus={{
          boxShadow: "rgba(0, 0, 0, 0)",
        }}
      />
    </Tag>
  );
};

export default TagFilters;
