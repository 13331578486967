import { Button } from "@chakra-ui/react";
import React from "react";

export const ButtonBekeu = ({ children, ...props }) => {
  return (
    <Button
      w={"100%"}
      my={"16px"}
      height={"3rem"}
      bg={"brand.primary"}
      color="white"
      fontSize={"1rem"}
      fontWeight={600}
      borderRadius={"12px"}
      _hover={{ bg: "brand.hover" }}
      _active={{ bg: "brand.hover" }}
      _focus={{
        boxShadow:
          "0 0 1px 2px rgba(122, 57, 162, 1), 0 1px 1px rgba(122, 57, 162, 1)",
      }}
      {...props}
    >
      {children}
    </Button>
  );
};
