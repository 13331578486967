import axios from "axios";
import { urlsBase } from "../../../../config";

export const ORDERS_URL = `${urlsBase.base_url}/api/v1/buyorders`;
export const MY_ORDERS_URL = `${urlsBase.base_url}/api/v1/buyorders/my-orders`;
export const HISTORY_URL = `${urlsBase.base_url}/api/v1/dispatch-notes`;
export const UPLOAD_NOTE_URL = `${urlsBase.base_url}/api/v1/dispatch-notes/upload-note`;

export function listCarts(payload) {
  let requestOrders = `?`;

  if (payload && payload.page && payload.perPage) {
    requestOrders += `&page=${payload.page}&per_page=${payload.perPage}`;
  }

  if (payload && payload.state && payload.state !== []) {
    requestOrders += `&state=${payload.state}`;
  }

  if (payload && payload.from && payload.from !== []) {
    requestOrders += `&from=${payload.from}`;
  }

  if (payload && payload.to && payload.to !== []) {
    requestOrders += `&to=${payload.to}`;
  }
  return axios.get(`${MY_ORDERS_URL}/${requestOrders}`);
}

export function listDetailCart({ order_id }) {
  return axios.get(`${MY_ORDERS_URL}/${order_id}`);
}

export function confirmMyOrdersCart(payload, formdata) {
  return axios.post(`${ORDERS_URL}/confirm/${payload.orderId}`, formdata);
}

export function confirmMyOrdersCartUploadDispatchNote(payload) {
  return axios.post(`${UPLOAD_NOTE_URL}`, payload);
}

export function claimMyOrdersCart(payload) {
  return axios.post(`${ORDERS_URL}/claim/${payload.orderId}`, {
    order_id: payload.orderId,
    provider_id: payload.providerId,
    message: payload.claimMessage,
    dispatch_note_number: payload.dispatchNoteNumber,
  });
}

export function getHistory({ order_id }) {
  return axios.get(`${HISTORY_URL}/${order_id}`);
}
