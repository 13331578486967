export const urlsBase = {
  base_url: process.env.REACT_APP_BASE_URL,
  web_url: process.env.REACT_APP_WEB_URL,
  aws_url: process.env.REACT_APP_AWS_URL,
};

export const ssoAuth = {
  clientId: process.env.REACT_APP_SSO_CLIENT_ID,
  authority: process.env.REACT_APP_SSO_AUTHORITY,
  redirectUri: process.env.REACT_APP_SSO_REDIRECT_URI
}

export const gtmModule = {
  gtm_id: process.env.REACT_APP_GTM_ID,
};

export const flareConfig = {
  flare_key: process.env.FLARE_KEY,
};

export const envConfig = {
  app_env: process.env.APP_ENV,
  vista_env: process.env.VISTA_ENV,
  vista_url: process.env.VISTA_URL,
  pampa_env: process.env.PAMPA_ENV,
}
