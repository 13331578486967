import React, { useState } from "react";
import "./CardItem.scss";
import { Box, Divider, Flex, Img, Spinner, Text } from "@chakra-ui/react";
import location from "../../design-system/icons/Location/location-tick-primary.svg";
import { Link } from "react-router-dom";
import { BekeuIcon } from "../BekeuIcon/BekeuIcon";
import TagManager from "react-gtm-module";
import notFoundImg from "../../design-system/images/not-found.png";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const CardItem = ({ data, gridLoading }) => {
  const storageFiltersVar = localStorage.getItem("storageFilters");
  const [imageError, setImageError] = useState(false);

  const storageFilters = {
    isFirstLoad: false,
    categories: {
      category_name: storageFiltersVar
        ? JSON.parse(storageFiltersVar).categories.category_name
        : [],
      category_id: storageFiltersVar
        ? JSON.parse(storageFiltersVar).categories.category_id
        : [],
    },
    brands: {
      brand_name: storageFiltersVar
        ? JSON.parse(storageFiltersVar).brands.brand_name
        : [],
      brand_id: storageFiltersVar
        ? JSON.parse(storageFiltersVar).brands.brand_id
        : [],
    },
    delivery_zones: {
      delivery_zone_name: storageFiltersVar
        ? JSON.parse(storageFiltersVar).delivery_zones.delivery_zone_name
        : [],
      delivery_zone_id: storageFiltersVar
        ? JSON.parse(storageFiltersVar).delivery_zones.delivery_zone_id
        : [],
    },
    price: storageFiltersVar ? JSON.parse(storageFiltersVar).price : [],
    delivery_days: storageFiltersVar
      ? JSON.parse(storageFiltersVar).delivery_days
      : [],
  };

  useEffect(() => {
    AOS.init();
  }, []);

  function handleScrollTo() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }

  return (
    <Box
      borderRadius={12}
      overflow="hidden"
      boxShadow={"lg"}
      mb={"12px"}
      bg={"white"}
      onClick={() => {
        handleScrollTo();
        localStorage.setItem("storageFilters", JSON.stringify(storageFilters));
        TagManager.dataLayer({
          dataLayer: { event: "item", path: data.product_name },
        });
      }}
    >
      <Link to={`/detail/${data.sku}`}>
        <Flex justifyContent={"center"} p={"12px"} h={"156px"}>
          {gridLoading ? (
            <Spinner color="gray.300" size="lg" />
          ) : (
            <Flex
              overflow="hidden"
              justifyContent={"center"}
              alignItems="center"
            >
              <Img
                h={imageError ? "50%" : "100%"}
                title={imageError ? "Imagen no disponible" : data?.product_name}
                src={imageError ? notFoundImg : data?.image_source}
                draggable={"false"}
                onError={() => setImageError(true)}
              />
            </Flex>
          )}
        </Flex>
        <Divider />
        <Flex
          flexDir={"column"}
          justifyContent="space-between"
          px={"12px"}
          pb={"10px"}
        >
          <Text
            h={"38px"}
            fontSize="14px"
            lineHeight={"19px"}
            fontWeight={400}
            fontStyle={"normal"}
            color={"brand.contentSecondary"}
            mt={"12px"}
            mb={"6px"}
            overflow={"hidden"}
          >
            {data?.product_name}
          </Text>
          <Text
            fontSize="14px"
            lineHeight={"19px"}
            color={"#000000"}
            fontWeight="semibold"
            my={"3px"}
          >
            {data?.currency_code} {data?.min_price_value} + Imp
          </Text>
          <Flex
            my={"3px"}
            alignItems={"center"}
            bg={"rgba(76, 175, 80, 0.1)"}
            borderRadius={"12px"}
            px={"8px"}
            py={"2px"}
            pr={"12px"}
            minH={"23px"}
            w="fit-content"
          >
            <BekeuIcon src={location} h={"100%"} mr={1} />
            <Text
              color={"brand.success"}
              fontSize={"0.8rem"}
              fontWeight={400}
              letterSpacing={"-0.005em"}
            >
              Disponible en {data?.regions_available}{" "}
              {data?.regions_available === 1 ? "zona" : "zonas"}
            </Text>
          </Flex>
        </Flex>
      </Link>
    </Box>
  );
};

export default CardItem;
