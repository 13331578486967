import { objectFilter } from "@chakra-ui/utils";
import axios from "axios";
import { typeOf } from "react-is";
import { urlsBase } from "../../../../config";

export const PRODUCT_URL = `${urlsBase.base_url}/api/v1/products`;

export function listProductsGrid(payload) {
  let requestProducts = `?page=${payload.page}&per_page=${payload.perPage}`;
  if (payload && payload.category_id && payload.category_id !== []) {
    requestProducts += `&category_id=${payload.category_id}`;
  }

  if (payload && payload.brand_id && payload.brand_id !== []) {
    requestProducts += `&brand_id=${payload.brand_id}`;
  }

  if (payload && payload.price && payload.price !== []) {
    requestProducts += `&price_min=${payload.price[0]}&price_max=${payload.price[1]}`;
  }

  if (payload && payload.delivery_zone && payload.delivery_zone !== []) {
    requestProducts += `&zone_available_qty=${payload.delivery_zone}`;
  }

  if (payload && payload.delivery_days && payload.delivery_days !== []) {
    requestProducts += `&delivery_days=${payload.delivery_days}`;
  }

  if (payload && payload.term && payload.term !== []) {
    requestProducts += `&term=${payload.term}`;
  }

  return axios.get(`${PRODUCT_URL}/searchelastic${requestProducts}`);
}

export function listProductsGridByTerm(payload) {
  return axios.get(
    `${PRODUCT_URL}/searchelastic?term=${payload.term}&page=${payload.page}&per_page=${payload.perPage}`
  );
}

export function listProductsGridFilters(payload) {
  let requestProducts = "?";

  if (payload && payload.category_id && payload.category_id !== []) {
    requestProducts += `category_id=${payload.category_id}`;
  }

  if (payload && payload.brand_id && payload.brand_id !== []) {
    requestProducts += `&brand_id=${payload.brand_id}`;
  }

  if (payload && payload.price && payload.price !== []) {
    requestProducts += `&price_min=${payload.price[0]}&price_max=${payload.price[1]}`;
  }

  if (payload && payload.delivery_zone && payload.delivery_zone !== []) {
    requestProducts += `&zone_available_qty=${payload.delivery_zone}`;
  }

  if (payload && payload.delivery_days && payload.delivery_days !== []) {
    requestProducts += `&delivery_days=${payload.delivery_days}`;
  }

  if (payload && payload.term && payload.term !== []) {
    requestProducts += `&term=${payload.term}`;
  }

  return axios.get(`${PRODUCT_URL}/filterselastic${requestProducts}`);
}

export function getOneProduct(payload) {
  return axios.get(`${PRODUCT_URL}/${payload}`);
}
