import { Flex, Text } from "@chakra-ui/react";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import { ButtonBekeu } from "../../design-system/forms/ButtonBekeu";
import { ButtonBekeuLink } from "../../design-system/forms/ButtonBekeuLink";
import { H6 } from "../../design-system/headings/H6";
import { ProductDetailModalSeller } from "../ProductDetailModalSeller/ProductDetailModalSeller";

export const MyOrdersDetail = ({
  item,
  showitems,
  totalItems,
  productName,
}) => {
  const history = useHistory();

  function handleScrollTo() {
    window.scrollTo({ top: 0, left: 0 });
  }

  const parsedArs = parseInt(item?.total_ARP) !== 0;
  const parsedUsd = parseInt(item?.total_USD) !== 0;
  const parsedShippingArs = parseInt(item?.shipping_cost_ARP) === 0;
  const parsedShippingUsd = parseInt(item?.shipping_cost_USD) === 0;

  return (
    !showitems && (
      <Flex
        justifyContent="space-between"
        alignItems="center"
        minH="160px"
        p="24px"
      >
        <Flex flexDir={"column"} w="500px" gap="12px" minH="160px">
          <H6 color={"brand.primary"}>
            <Text
              cursor={"pointer"}
              onClick={() => {
                handleScrollTo();
                history.push(
                  `/my-orders/order-detail/${item?.id}/?state=${item?.state}`
                );
              }}
            >
              {item?.buy_orders && totalItems > 1
                ? `${totalItems} productos `
                : productName}
            </Text>
          </H6>
          <Text
            fontWeight={400}
            fontSize="16px"
            lineHeight={"22px"}
            noOfLines={2}
          >
            Dirección de Entrega: {item?.address_description}
          </Text>
          {/* <Flex flexDir={"column"}>
            <Text>Centro de costo: </Text>
            {item?.cost_centers?.map((costCenter, index) => (
              <Flex key={index} w="10rem" justifyContent={"space-between"}>
                <Text>{costCenter.cost_center}</Text>
                <Text>{costCenter.percentage}%</Text>
              </Flex>
            ))}
          </Flex> */}
          <Flex gap={2} alignItems="center">
            <Text>Seller: </Text>
            {item?.buy_orders?.length === 1
              ? item?.buy_orders?.map((order, id) => {
                  return (
                    <ProductDetailModalSeller
                      sellerName={order.provider?.fantasy_name}
                      email={order.provider?.email}
                      phone={order.provider?.phone}
                      key={id}
                    />
                  );
                })
              : item?.buy_orders?.length > 1 && (
                  <ButtonBekeuLink
                    color={"brand.primary"}
                    onClick={() =>
                      history.push(
                        `/my-orders/order-detail/${item?.id}/?state=${item?.state}`
                      )
                    }
                  >
                    {item?.buy_orders?.length} sellers
                  </ButtonBekeuLink>
                )}
          </Flex>
        </Flex>
        <Flex width="40%" gap="50px" justifyContent={"flex-end"}>
          {parsedArs && (
            <Flex flexDir={"column"} justifyContent="space-between" gap="24px">
              <Flex flexDir={"column"} gap="8px">
                <Text
                  color={"brand.contentSecondary"}
                  fontWeight={400}
                  fontSize="16px"
                >
                  Total productos en ARS:{" "}
                </Text>
                <Text fontWeight={600} fontSize="16px">
                  {item?.total_ARP} + Imp
                </Text>
              </Flex>
              <Flex flexDir={"column"} gap="8px">
                <Text>Total costo de envíos en ARS: </Text>
                {parsedShippingArs ? (
                  <Text fontWeight={600} fontSize="16px" color="brand.success">
                    Envío gratis
                  </Text>
                ) : (
                  <Text fontWeight={600} fontSize="16px">
                    {item?.shipping_cost_ARP} + Imp
                  </Text>
                )}
              </Flex>
            </Flex>
          )}
          {parsedUsd && (
            <Flex flexDir={"column"} justifyContent="space-between" gap="24px">
              <Flex flexDir={"column"} gap="8px">
                <Text
                  color={"brand.contentSecondary"}
                  fontWeight={400}
                  fontSize="16px"
                >
                  Total productos en USD:{" "}
                </Text>
                <Text fontWeight={600} fontSize="16px">
                  {item?.total_USD} + Imp
                </Text>
              </Flex>
              <Flex flexDir={"column"} gap="8px">
                <Text>Total costo de envíos en USD: </Text>
                {parsedShippingUsd ? (
                  <Text fontWeight={600} fontSize="16px" color="brand.success">
                    Envío gratis
                  </Text>
                ) : (
                  <Text fontWeight={600} fontSize="16px">
                    {item?.shipping_cost_USD} + Imp
                  </Text>
                )}
              </Flex>
            </Flex>
          )}
        </Flex>
        <Link
          to={`/my-orders/order-detail/${item?.id}/?state=${item?.state}`}
          _focus={{ boxShadow: "rgba(0,0,0,0)" }}
        >
          <ButtonBekeu
            w={"200px"}
            ml="50px"
            onClick={() => {
              handleScrollTo();
            }}
          >
            Ver compra
          </ButtonBekeu>
        </Link>
      </Flex>
    )
  );
};
