import { Box, Button, Divider, Flex, Img, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { useEffect } from "react";
import { Paragraphs } from "../../design-system/headings/Paragraphs";
import { BekeuIcon } from "../BekeuIcon/BekeuIcon";
import { Link } from "react-router-dom";

export const MiniCartItem = ({
  imageError,
  setImageError,
  notFoundImage,
  item,
  history,
  cart,
  handleRemoveCartItem,
  trash,
}) => {
  const [itemLoading, setItemLoading] = useState(false);
  useEffect(() => {
    cart.result && setItemLoading(false);
  }, [cart.result]);

  return (
    <div>
      <Flex mb={2}>
        <Flex justifyContent={"center"}>
          <Box
            h={"80px"}
            w={"80px"}
            borderWidth={"1px"}
            borderRadius="6px"
            overflow="hidden"
          >
            <Link to={`/detail/${item?.sku}`}>
              <Img
                h={"100%"}
                p={"10px"}
                src={imageError ? notFoundImage : item?.image_source}
                draggable={"false"}
                cursor={"pointer"}
                onError={() => setImageError(true)}
              />
            </Link>
          </Box>
        </Flex>
        <Flex flexDir={"column"} ml={4} w={"285px"}>
          <Flex justifyContent={"space-between"}>
            {item?.error ? (
              <Paragraphs color={"brand.contentSecondary"} lineHeight={"22px"}>
                {item?.title}
              </Paragraphs>
            ) : (
              <Link to={`/detail/${item?.sku}`}>
                <Paragraphs
                  color={"brand.contentSecondary"}
                  lineHeight={"22px"}
                  cursor={"pointer"}
                >
                  {item?.title}
                </Paragraphs>
              </Link>
            )}
            <Button
              variant={"ghost"}
              borderRadius={"12px"}
              m={2}
              p={0}
              _focus={{
                boxShadow: "rgba(0, 0, 0, 0)",
              }}
              onClick={() => {
                handleRemoveCartItem(item);
                setItemLoading(true);
              }}
              isLoading={itemLoading}
            >
              <BekeuIcon src={trash} />
            </Button>
          </Flex>
          <Flex justifyContent={"space-between"}>
            <Paragraphs>x{item?.quantity}</Paragraphs>
            <Paragraphs>
              {item?.currency_code} {item?.subtotal} + Imp
            </Paragraphs>
          </Flex>
        </Flex>
      </Flex>
      {item?.error && (
        <Text
          fontSize={"sm"}
          mb={4}
          className="animate__animated animate__fadeIn"
        >
          Este producto ya no se encuentra disponible para comprar
        </Text>
      )}
      <Divider my={"10px"} />
    </div>
  );
};
