import React, { useState } from "react";
import "./Searcher.scss";
import {
  Flex,
  Input,
  InputRightElement,
  InputGroup,
  Button,
} from "@chakra-ui/react";
import search from "../../design-system/icons/Search/search.svg";
import { BekeuIcon } from "../BekeuIcon/BekeuIcon";
import useQuery from "../../hooks/useQuery";
// import TagManager from "react-gtm-module";

const Searcher = ({ history, ...props }) => {
  const query = useQuery();
  const term = query.get("term");
  const [value, setValue] = useState(term);

  const storageFilters = {
    isFirstLoad: true,
    categories: { category_name: [], category_id: [] },
    brands: { brand_name: [], brand_id: [] },
    delivery_zones: { delivery_zone_name: [], delivery_zone_id: [] },
    price: [],
    delivery_days: [],
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (value) {
      history.push(`/search/?page=1&per_page=20&term=${value.toLowerCase()}`);
      localStorage.setItem("storageFilters", JSON.stringify(storageFilters));
      // TagManager.dataLayer({
      //   dataLayer: { event: "search", path: value },
      // });
    }
  };

  return (
    <Flex w={"50%"} {...props}>
      <form onSubmit={handleSearch} style={{ width: "100%" }}>
        <InputGroup>
          <Input
            defaultValue={term && term}
            placeholder="¿Qué estás buscando?"
            onChange={(event) => {
              setValue(event.target.value);
            }}
            bg={"brand.white"}
            h={50}
            borderRadius={12}
            _focus={{
              boxShadow:
                "0 0 1px 2px rgba(122, 57, 162, 1), 0 1px 1px rgba(122, 57, 162, 1)",
            }}
            autoFocus={!term}
            overflow="hidden"
          />

          <InputRightElement
            bg={"brand.acents"}
            borderRightRadius={12}
            h={50}
            w={"fit-content"}
            p={0}
          >
            <Button
              onClick={handleSearch}
              variant={"ghost"}
              _hover={{ bg: "brand.transparent" }}
              _active={{ bg: "brand.transparent" }}
              _focus={{
                boxShadow: "rgba(0,0,0,0)",
              }}
            >
              <BekeuIcon src={search} />
            </Button>
          </InputRightElement>
        </InputGroup>
      </form>
    </Flex>
  );
};

export default Searcher;
