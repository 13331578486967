import { Button } from "@chakra-ui/react";
import React from "react";

export const ButtonBekeuLinkM = ({ children, ...props }) => {
  return (
    <Button
      w={"fit-content"}
      variant={"link"}
      color={"brand.primary"}
      fontFamily={"Open Sans"}
      fontSize={"0.875rem"}
      fontWeight={400}
      _hover={{ borderBottomWidth: 0, color: "brand.hover" }}
      _focus={{
        boxShadow: "rgba(0, 0, 0, 0)",
      }}
      {...props}
    >
      {children}
    </Button>
  );
};
