import { Button, Flex, Img, Text, useDisclosure } from "@chakra-ui/react";
import React from "react";
import locationTick from "../../design-system/icons/Location/location-tick.svg";
import location from "../../design-system/icons/Location/location.svg";
import arrowDownRight from "../../design-system/icons/Arrows/arrow-down-right.png";
import { H5 } from "../../design-system/headings/H5";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { SearchBarBekeu } from "../../design-system/inputs/SearchBarBekeu";
import { BekeuIcon } from "../BekeuIcon/BekeuIcon";

export const ProductDetailModalCheckout = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button
        variant={"link"}
        onClick={onOpen}
        _focus={{
          boxShadow: "rgba(0, 0, 0, 0)",
          borderWidth: "0px",
          border: "none",
        }}
        _active={{ borderWidth: "0px", border: "none" }}
        _hover={{ color: "brand.hover" }}
        mb={2}
        w={"fit-content"}
      >
        <Flex alignItems={"center"} w={"fit-content"} {...props}>
          <BekeuIcon src={locationTick} alt="locationTick" mr={2} />
          <Text
            fontSize={"16px"}
            fontWeight={400}
            color={"brand.primary"}
            mr={1}
          >
            {props.selectedZone}
          </Text>
          <BekeuIcon src={arrowDownRight} alt="arrowDownRight" />
        </Flex>
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size={"lg"}>
        <ModalOverlay />
        <ModalContent borderRadius={"12px"}>
          <ModalCloseButton
            _focus={{
              boxShadow: "rgba(0, 0, 0, 0)",
            }}
          />
          <ModalBody>
            <Flex p={"50px"} flexDir="column">
              <H5 mb={"30px"}>Zona de entrega</H5>
              <Flex alignItems={"center"} w={"fit-content"} {...props} mb={4}>
                <BekeuIcon src={locationTick} alt="locationTick" mr={2} />
                <Text
                  fontSize={"16px"}
                  fontWeight={400}
                  color={"brand.primary"}
                  mr={1}
                >
                  {props.selectedZone}
                </Text>
                <BekeuIcon src={arrowDownRight} alt="arrowDownRight" />
              </Flex>
              <SearchBarBekeu placeholder={"Buscar"} mb={"33px"} />
              {Object.keys(props.deliveryZone).map((deliveryZone, id) => {
                return (
                  <Flex
                    mb={"16px"}
                    key={id}
                    onClick={() => {
                      props.handleChangeDeliveryZone(
                        props.deliveryZone[deliveryZone]
                      );
                      onClose();
                    }}
                    cursor={"pointer"}
                  >
                    <BekeuIcon src={location} alt="locationTick" mr={2} />
                    <Text>{deliveryZone}</Text>
                  </Flex>
                );
              })}
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
