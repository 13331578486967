import { Box } from "@chakra-ui/react";
import React from "react";

export const SalesBadge = ({ children, item, ...props }) => {
  return (
    <Box
      color={
        item?.state === "1"
          ? "brand.warning"
          : item?.state === "2"
          ? "brand.warning"
          : item?.state === "5"
          ? "brand.error"
          : item?.state === "4"
          ? "brand.success"
          : item?.state === "7"
          ? "brand.success"
          : item?.state === "9"
          ? "brand.warning"
          : item?.state === "10"
          ? "brand.success"
          : item?.state === "8" && "brand.warning"
      }
      background={
        item?.state === "2"
          ? "rgba(255, 152, 0, 0.2)"
          : item?.state === "5"
          ? "rgba(244, 67, 54, 0.2)"
          : item?.state === "4"
          ? "rgba(76, 175, 80, 0.2)"
          : item?.state === "7"
          ? "rgba(76, 175, 80, 0.2)"
          : item?.state === "9"
          ? "rgba(255, 152, 0, 0.2)"
          : item?.state === "10"
          ? "rgba(76, 175, 80, 0.2)"
          : item?.state === "8" && "rgba(255, 152, 0, 0.2)"
      }
      padding={"8px"}
      px={"12px"}
      borderRadius={"12px"}
      {...props}
    >
      {children}
    </Box>
  );
};