import { Box, Flex, Skeleton, Stack } from "@chakra-ui/react";
import React from "react";

import { H6 } from "../../design-system/headings/H6";
import { BoxBekeu } from "../../design-system/layout/BoxBekeu";

import { CartShippingAccordion } from "../CartShippingAccordion/CartShippingAccordion";

export const CartShipping = ({
  handleChangeAddress,
  handleChangeCostCenter,
  handleChangeProviderComments,
  handleChangeApproverComments,
  costCenter,
  setEnableLogisticCenter,
}) => {
  return (
    <BoxBekeu mt={"32px"} w={"60%"} minH={"200px"} overflow="hidden" p={"12px"}>
      <Flex flexDir={"column"}>
        <H6 p={"12px"}>Seleccioná única dirección de envío</H6>
        <CartShippingAccordion
          handleChangeAddress={handleChangeAddress}
          handleChangeCostCenter={handleChangeCostCenter}
          handleChangeProviderComments={handleChangeProviderComments}
          handleChangeApproverComments={handleChangeApproverComments}
          setEnableLogisticCenter={setEnableLogisticCenter}
          costCenterValue={costCenter}
        />
      </Flex>
    </BoxBekeu>
  );
};
