import React, { useRef, useEffect } from "react";
import { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  ListItem,
  OrderedList,
  Box,
  Img,
  Flex,
  useDisclosure,
} from "@chakra-ui/react";
import { ButtonBekeuM } from "../../design-system/forms/ButtonBekeuM";
import { ButtonBekeu } from "../../design-system/forms/ButtonBekeu";
import { ButtonBekeuOutline } from "../../design-system/forms/ButtonBekeuOutline";
import document from "../../design-system/icons/Import/document.svg";
import importOk from "../../design-system/icons/Import/importOk.svg";
import importError from "../../design-system/icons/Import/importError.svg";
import successCircleFill from "../../design-system/icons/Arrows/success-circle-fill.svg";
import { Paragraphs } from "../../design-system/headings/Paragraphs";
import { H5 } from "../../design-system/headings/H5";
import { ButtonBekeuLink } from "../../design-system/forms/ButtonBekeuLink";
import { actions as quotesActions } from "../../pages/myQuotesPage/ducks/myQuotes.duck";
import { connect, useSelector } from "react-redux";
import { injectIntl } from "react-intl";
import Dropzone from "dropzone";
import { urlsBase } from "../../../config";
import { ErrorModal } from "../../components/ErrorModal/ErrorModal";

const PriceListItem = ({ name }) => (
  <ListItem
    fontSize={"16px"}
    fontWeight={400}
    lineHeight={"22px"}
    color={"brand.contentSecondary"}
  >
    {name}
  </ListItem>
);

const priceList = [
  {
    id: 1,
    name: "Los precios deben ser netos (No incluir IVA, IIBB, envío)",
  },
  { id: 2, name: "No utilizar formatos, fórmulas y símbolos" },
  { id: 3, name: "Importar unicamente archivos en formato .xlsx" },
];
const MyQuotesImportModal = ({
  isOpen,
  onClose,
  getImportQuote,
  handleConfirmImport,
}) => {
  const [uploadedFile, setUploadedFile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fileName, setFileName] = useState("");
  const [counter, setCounter] = useState(0);
  const [priceState, setPriceState] = useState("instructions");
  const [uploadProgress, setUploadProgress] = useState(false);
  const [err, setErr] = useState("");
  const [uploadedFileName, setUploadedFileName] = useState("");
  const mainState = useSelector((state) => state);
  const [globalStatus, setGlobalStatus] = useState("loading");
  const {
    isOpen: isOpenErrorModal,
    onOpen: onOpenErrorModal,
    onClose: onCloseErrorModal,
  } = useDisclosure();
  const hasError = `${mainState.quotes.result}`;

  const handleConfirmLoad = () => {
    setLoading(true);
    uploadedFileName &&
      getImportQuote({
        file: uploadedFileName,
      });
    // setEditFile(false);
    // setMessage(true);
  };

  const ref = useRef(false);

  const element = ref.current;

  Dropzone.autoDiscover = false;

  useEffect(() => {
    Dropzone.options.datanodeupload = {
      addedfile: (file) => {
        console.log("ok");
      },
    };

    let myDropzone =
      element &&
      setTimeout(() => {
        new Dropzone(element, {
          url: `${urlsBase.base_url}/api/v1/quotes/import/upload`,
          method: "POST",
          chunking: true, // enable chunking
          chunkSize: 2000000, // chunk size 2,000,000 bytes (~2MB)
          forceChunking: true, // forces chunking when file.size < chunkSize
          disablePreviews: true, // disable previews
          headers: {
            Authorization: mainState.auth.authToken,
          },
          acceptedFiles: ".xlsx",
          success: function(file, response) {
            setUploadedFileName(response.name);
            setUploadedFile(true);
            setFileName(file.name);
            setGlobalStatus("success");
          },
          uploadprogress: (file, progress) => {
            setGlobalStatus("loading");
            setUploadProgress(file);
          },
          error: (file) => {
            setGlobalStatus("error");
            setUploadedFile(true);
            setErr(file);
          },
        });
      }, 1000);
  }, [element]);

  useEffect(() => {
    if (mainState.quotes.result === "importSuccess") {
      setLoading(false);
      setPriceState("priceMessage");
    }
  }, [mainState.quotes.result]);

  useEffect(() => {
    setCounter(counter + 1);
  }, [priceState]);

  useEffect(() => {
    if (hasError.includes("generalErrorImport")) {
      onOpenErrorModal();
      onClose();
    }
  }, [hasError]);

  return (
    <>
      <ErrorModal
        isOpen={isOpenErrorModal}
        onClose={onCloseErrorModal}
        codeError={hasError}
      />
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={"xl"}
        onCloseComplete={() => {
          setPriceState("instructions");
          setUploadedFile(false);
          setUploadedFileName("");
          mainState.quotes.result === "importSuccess" && handleConfirmImport();
        }}
        isCentered
        useInert={false}
      >
        <ModalOverlay />
        <ModalContent borderRadius="12px">
          <ModalCloseButton _focus={{ boxShadow: "rgba(0,0,0,0)" }} />
          <ModalBody p="48px">
            <Flex flexDirection={"column"} justifyContent="center" width="100%">
              {priceState !== "priceMessage" && (
                <H5 mb={"32px"} textAlign={"center"}>
                  Importación masiva de precios
                </H5>
              )}
              {priceState === "instructions" ? (
                <>
                  <Flex>
                    <Paragraphs
                      lineHeight={"22px"}
                      fontWeight={"600"}
                      textAlign={"center"}
                    >
                      Para una correcta importación tener en cuenta lo
                      siguiente:
                    </Paragraphs>
                  </Flex>
                  <OrderedList mt={"25px"}>
                    {priceList.map((price) => (
                      <PriceListItem key={price.id} name={price.name} />
                    ))}
                  </OrderedList>
                  <Flex width={"100%"} justifyContent={"center"} mt={"32px"}>
                    <ButtonBekeu
                      align="center"
                      onClick={() => {
                        setPriceState("uploadFile");
                        setCounter(counter + 1);
                      }}
                      w={"fit-content"}
                      px={"66px"}
                      my={"0"}
                    >
                      Continuar
                    </ButtonBekeu>
                  </Flex>
                </>
              ) : (
                priceState === "uploadFile" && (
                  <>
                    <form
                      ref={ref}
                      id="dropzoneId"
                      className="dropzone animate__animated animate__fadeIn"
                      style={{
                        width: "100%",
                        height: "260px",
                        border: "1px dashed",
                        borderColor: "#BDBDBD",
                        borderRadius: "12px",
                        cursor: "pointer",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {uploadedFile ? (
                        <Flex
                          flexDir={"column"}
                          alignItems="center"
                          justifyContent={"center"}
                          className="animate__animated animate__fadeIn"
                        >
                          {globalStatus === "success" && (
                            <>
                              <Img
                                src={importOk}
                                w="40px"
                                alt="importacion ok"
                              />
                              <Paragraphs mt={"28px"} mb={"40px"}>
                                {fileName}
                              </Paragraphs>
                              <ButtonBekeuLink
                                onClick={() => setUploadedFile(false)}
                              >
                                Modificar archivo
                              </ButtonBekeuLink>
                            </>
                          )}
                          {globalStatus === "error" && (
                            <>
                              <Img
                                src={importError}
                                alt="error de importacion"
                              />
                              <Paragraphs mt={"28px"}>
                                Ocurrió un error. Recordá que tiene que ser en
                                formato .xlsx
                              </Paragraphs>
                              <ButtonBekeuM
                                px={"33px"}
                                my={"9px"}
                                onClick={() => {
                                  setUploadedFile(false);
                                }}
                              >
                                Subir archivo
                              </ButtonBekeuM>
                            </>
                          )}
                        </Flex>
                      ) : (
                        <>
                          <Img src={document} alt="document" />
                          <Paragraphs
                            mt={"28px"}
                            className="dz-message"
                            data-dz-message
                          >
                            Subí tu archivo en formato .xlsx
                          </Paragraphs>
                          <ButtonBekeuM
                            px={"33px"}
                            isLoading={
                              uploadProgress.status === "uploading" &&
                              err !== "error"
                            }
                            onClick={() => element.click()}
                          >
                            Subir archivo
                          </ButtonBekeuM>
                        </>
                      )}
                    </form>
                    <Flex
                      align-items={"center"}
                      justifyContent={"space-between"}
                      w={"100%"}
                      mt={"32px"}
                      mb={0}
                    >
                      <ButtonBekeuOutline
                        w={"50%"}
                        mr={"12px"}
                        my={0}
                        onClick={onClose}
                      >
                        Cancelar
                      </ButtonBekeuOutline>
                      <ButtonBekeu
                        my={0}
                        w={"50%"}
                        onClick={() => {
                          uploadedFile
                            ? handleConfirmLoad()
                            : setCounter(counter + 1);
                        }}
                        isLoading={loading}
                        isDisabled={!uploadedFileName}
                      >
                        Confirmar
                      </ButtonBekeu>
                    </Flex>
                  </>
                )
              )}
              {priceState === "priceMessage" && (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  flexDirection="column"
                >
                  {mainState.quotes.result === "importSuccess" && (
                    <>
                      <Img src={successCircleFill} alt="importacion ok" />
                      <H5 mt={"37px"}>¡El archivo se subió con éxito!</H5>
                    </>
                  )}

                  {/* {mainState.quotes.result === "generalError" && (
                  // <>
                  //   <Img
                  //     src={importError}
                  //     alt="error de importacion"
                  //     mt="59px"
                  //   />
                  //   <Paragraphs mt={"28px"}>Ocurrió un error</Paragraphs>
                  // </>
                  
                )} */}

                  <ButtonBekeuM
                    onClick={() => {
                      handleConfirmImport();
                      onClose();
                    }}
                    align="center"
                    width={"fit-content"}
                    px={"75px"}
                    my={"13px"}
                  >
                    Aceptar
                  </ButtonBekeuM>
                </Box>
              )}
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default injectIntl(connect(null, quotesActions)(MyQuotesImportModal));
