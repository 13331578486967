import React, { useMemo } from "react";

const useFormatCurrency = () => {
  const ARS = useMemo(() => {
    return new Intl.NumberFormat("es-AR", {
      style: "currency",
      currency: "ARS",
    });
  }, []);

  const USD = useMemo(() => {
    return new Intl.NumberFormat("es-AR", {
      style: "currency",
      currency: "USD",
    });
  }, []);

  return { ARS, USD };
};

export default useFormatCurrency;