import {
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/react";
import React from "react";

export const InputBekeu = ({
  lefticon,
  righticon,
  groupw,
  grouph,
  pointerEvents,
  groupref,
  customRef,
  ...props
}) => {
  return (
    <InputGroup height={!grouph && "56px"} width={groupw} groupref={groupref}>
      {lefticon !== undefined && (
        <InputLeftElement
          pointerEvents="none"
          children={lefticon}
          height={"56px"}
          width={"56px"}
        />
      )}
      <Input
        px={"16px"}
        py="8px"
        height={"56px"}
        fontFamily="Open Sans"
        fontWeight={300}
        lineHeight="1.375rem"
        fontSize="1rem"
        borderRadius={"15px"}
        borderColor={"brand.border"}
        borderWidth="1px"
        focusBorderColor="brand.hover"
        ref={customRef}
        {...props}
      />
      {righticon !== undefined && (
        <InputRightElement
          pointerEvents={!pointerEvents && "none"}
          children={righticon}
          height={"56px"}
          width={"56px"}
        />
      )}
    </InputGroup>
  );
};
