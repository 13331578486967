import React, { useEffect, useState } from "react";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Flex,
  MenuDivider,
  MenuGroup,
  Box,
  SimpleGrid,
  Text,
  Link,
} from "@chakra-ui/react";
import menu from "./menu.svg";
import arrow from "./arrow-right.svg";
import { useHistory } from "react-router-dom";
import { injectIntl } from "react-intl";
import { connect, useSelector } from "react-redux";
import * as categories from "../../pages/categoriesPage/ducks/categories.duck";
import { ButtonBekeuLink } from "../../design-system/forms/ButtonBekeuLink";
import { H5 } from "../../design-system/headings/H5";
import { BekeuIcon } from "../BekeuIcon/BekeuIcon";
import { Paragraphs } from "../../design-system/headings/Paragraphs";

const NewMenuCategories = ({ children, ml, listCategories }) => {
  useEffect(() => {
    listCategories();
  }, [listCategories]);

  const mainState = useSelector((state) => state);
  const [openMenu, setOpenMenu] = useState(false);
  const [isPressable, setIsPressable] = useState(false);

  const history = useHistory();

  const storageFilters = {
    isFirstLoad: true,
    categories: { category_name: [], category_id: [] },
    brands: { brand_name: [], brand_id: [] },
    delivery_zones: { delivery_zone_name: [], delivery_zone_id: [] },
    price: [],
    delivery_days: [],
  };

  function handleResetLocalStorage() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    localStorage.setItem("storageFilters", JSON.stringify(storageFilters));
  }

  function handleClick(e, redirectPage) {
    e.preventDefault();
    history.push(redirectPage);
  }

  return (
    <Menu closeOnSelect={false} closeOnBlur>
      {({ onClose }) => {
        const onMainClose = onClose;
        return (
          <>
            <MenuButton
              as={Button}
              bg={"brand.primary"}
              color="brand.white"
              borderRadius={"12px"}
              _hover={{ bg: "brand.hover" }}
              _focus={{
                boxShadow: 0,
                bg: 0,
              }}
              _active={{ bg: "brand.hover" }}
              mr={8}
              ml={"-16px"}
            >
              <Flex alignItems={"center"}>
                <BekeuIcon src={menu} />
                <Paragraphs fontSize={"16px"} fontWeight={"normal"} ml={ml}>
                  {children}
                </Paragraphs>
              </Flex>
            </MenuButton>
            <MenuList
              zIndex={9999}
              borderRadius={12}
              boxShadow="lg"
              color={"black"}
              fontSize="16px"
              h={
                mainState?.categories?.categories.length === 0
                  ? "60px"
                  : "500px"
              }
              overflowY="scroll"
              className="overflow-y"
              onMouseLeave={() => {
                !openMenu && onClose();
              }}
            >
              {mainState?.categories?.categories.length === 0
                ? "No se encontraron resultados"
                : mainState?.categories?.categories.map((category, id) => (
                    <MenuItem px={"24px"} py={"12px"} key={id} as={Box}>
                      <Menu placement="right-start">
                        {({ onClose }) => {
                          const onSubCategoryClose = onClose;
                          return (
                            <>
                              <MenuButton
                                w={"100%"}
                                textAlign={"start"}
                                pl={4}
                                h={"35px"}
                                fontSize={"16px"}
                                color={"black"}
                                fontWeight={400}
                                _hover={{
                                  bg: "rgba(255, 0, 0, 0)",
                                  color: "brand.hover",
                                }}
                                key={id}
                              >
                                <Flex>
                                  <Paragraphs>{category.name}</Paragraphs>
                                  <BekeuIcon src={arrow} ml={"10px"} />
                                </Flex>
                              </MenuButton>
                              <MenuList
                                borderRadius={12}
                                boxShadow="lg"
                                color={"black"}
                                fontSize="16px"
                                minW={"800px"}
                                pb={"20px"}
                                maxH="70vh"
                                overflowY="scroll"
                                className="overflow-y"
                                onMouseEnter={() => {
                                  setOpenMenu(true);
                                }}
                                onMouseLeave={() => {
                                  setOpenMenu(false);
                                  onSubCategoryClose();
                                }}
                              >
                                <MenuGroup>
                                  <Flex alignItems={"center"}>
                                    <H5
                                      px={"24px"}
                                      py={"12px"}
                                      cursor="pointer"
                                      onClick={() => {
                                        onMainClose();
                                        handleResetLocalStorage();
                                        history.push(
                                          `/search/?page=1&per_page=20&category_id=${category.id}&lvl=01`
                                        );
                                      }}
                                    >
                                      {category.name}
                                    </H5>
                                    <Link
                                      href={`/search/?page=1&per_page=20&category_id=${category.id}&lvl=01`}
                                      style={{ textDecoration: "none" }}
                                    >
                                      <ButtonBekeuLink
                                        ml={2}
                                        onClick={(e) => {
                                          onMainClose();
                                          handleResetLocalStorage();
                                          // history.push(
                                          //   `/search/?page=1&per_page=20&category_id=${category.id}`
                                          // );
                                          handleClick(
                                            e,
                                            `/search/?page=1&per_page=20&category_id=${category.id}&lvl=01`
                                          );
                                        }}
                                      >
                                        Ver todo
                                      </ButtonBekeuLink>
                                    </Link>
                                  </Flex>
                                  <MenuDivider />
                                  <SimpleGrid minChildWidth="200px" spacing={4}>
                                    {category?.sub_categories &&
                                      category?.sub_categories
                                        ?.sort(
                                          (a, b) =>
                                            a?.sub_category_items?.length -
                                            b?.sub_category_items?.length
                                        )
                                        ?.map((cat, id) => (
                                          <Flex
                                            key={id}
                                            flexDir={"column"}
                                            alignItems={"start"}
                                          >
                                            <Button
                                              variant={"link"}
                                              mx={"24px"}
                                              py={"8px"}
                                              fontFamily={"Open Sans"}
                                              fontWeight={600}
                                              fontSize={"16px"}
                                              color="brand.contentPrimary"
                                              _hover={{
                                                borderBottomWidth: 0,
                                                color: "brand.hover",
                                              }}
                                              _focus={{
                                                boxShadow: "rgba(0, 0, 0, 0)",
                                              }}
                                              onClick={() => {
                                                onMainClose();
                                                handleResetLocalStorage();
                                                history.push(
                                                  `/search/?page=1&per_page=20&category_id=${cat.id}&lvl=02`
                                                );
                                              }}
                                              isTruncated
                                            >
                                              <div style={{ width: "202px" }}>
                                                {" "}
                                                <Text
                                                  textAlign={"left"}
                                                  isTruncated
                                                >
                                                  {cat?.name}
                                                </Text>
                                              </div>
                                            </Button>

                                            {cat?.sub_category_items ? (
                                              cat?.sub_category_items.map(
                                                (sub, id) =>
                                                  id < 5 ? (
                                                    <Button
                                                      variant={"link"}
                                                      mx={"24px"}
                                                      py={"8px"}
                                                      fontFamily={"Open Sans"}
                                                      fontWeight={400}
                                                      fontSize={"16px"}
                                                      color="brand.contentSecondary"
                                                      _hover={{
                                                        borderBottomWidth: 0,
                                                        color: "brand.hover",
                                                      }}
                                                      _focus={{
                                                        boxShadow:
                                                          "rgba(0, 0, 0, 0)",
                                                        color: "brand.hover",
                                                      }}
                                                      textAlign={"left"}
                                                      onClick={() => {
                                                        onMainClose();
                                                        handleResetLocalStorage();
                                                        history.push(
                                                          `/search/?page=1&per_page=20&category_id=${sub.id}`
                                                        );
                                                      }}
                                                      key={id}
                                                    >
                                                      <div
                                                        style={{
                                                          width: "202px",
                                                        }}
                                                      >
                                                        {" "}
                                                        <Text
                                                          textAlign={"left"}
                                                          isTruncated
                                                          title={sub.name}
                                                        >
                                                          {sub.name}
                                                        </Text>
                                                      </div>
                                                    </Button>
                                                  ) : (
                                                    id === 5 && (
                                                      <ButtonBekeuLink
                                                        mx={"24px"}
                                                        onClick={() => {
                                                          onMainClose();
                                                          handleResetLocalStorage();
                                                          history.push(
                                                            `/search/?page=1&per_page=20&category_id=${cat.id}`
                                                          );
                                                        }}
                                                        pb={"12px"}
                                                        pt="6px"
                                                        key={id}
                                                      >
                                                        Ver todo
                                                      </ButtonBekeuLink>
                                                    )
                                                  )
                                              )
                                            ) : (
                                              <ButtonBekeuLink
                                                ml={2}
                                                mx={"24px"}
                                                onClick={() => {
                                                  onMainClose();
                                                  handleResetLocalStorage();
                                                  history.push(
                                                    `/search/?page=1&per_page=20&category_id=${cat.id}`
                                                  );
                                                }}
                                              >
                                                Ver todo
                                              </ButtonBekeuLink>
                                            )}
                                          </Flex>
                                        ))}
                                  </SimpleGrid>
                                </MenuGroup>
                              </MenuList>
                            </>
                          );
                        }}
                      </Menu>
                    </MenuItem>
                  ))}
            </MenuList>
          </>
        );
      }}
    </Menu>
  );
};

export default React.memo(
  injectIntl(connect(null, categories.actions)(NewMenuCategories))
);
