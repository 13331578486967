import React from "react";
import { Badge } from "@chakra-ui/react";
import { actions as quotesActions } from "../../pages/myQuotesPage/ducks/myQuotes.duck";
import { connect, useSelector } from "react-redux";
import { injectIntl } from "react-intl";

const MyQuotesBadge = ({ state }) => {
  return (
    <Badge
      bg={
        state && state === "1"
          ? "#4CAF5033"
          : state === "2"
          ? "#FF980033"
          : state === "3" && "#F4433633"
      }
      color={
        state && state === "1"
          ? "brand.success"
          : state === "2"
          ? "brand.warning"
          : state === "3" && "brand.error"
      }
      borderRadius="12px"
      textTransform="capitalize"
      fontWeight="normal"
      fontSize={"16px"}
      display="inline-block"
      px={"8px"}
      py={"4px"}
    >
      {state && state === "1"
        ? "Publicación Activa"
        : state === "2"
        ? "Publicación Pausada"
        : state === "3" && "Publicación Cancelada"}
    </Badge>
  );
};

export default injectIntl(connect(null, quotesActions)(MyQuotesBadge));
