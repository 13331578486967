import { Collapse, Divider, Flex, Img, Spinner, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import { H6 } from "../../design-system/headings/H6";
import { Paragraphs } from "../../design-system/headings/Paragraphs";
import { BoxBekeu } from "../../design-system/layout/BoxBekeu";
import eye from "../../design-system/icons/Security/eye.svg";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";
import "moment/locale/es-mx";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import * as product from "../../pages/productGridPage/ducks/productsGrid.duck";
import { ButtonBekeuLink } from "../../design-system/forms/ButtonBekeuLink";
import { SalesBadge } from "../SalesBadge/SalesBadge";
import { SalesRejectModal } from "../SalesRejectModal/SalesRejectModal";
import { SalesSuccessModal } from "../SalesSuccessModal/SalesSuccessModal";
import { BekeuIcon } from "../BekeuIcon/BekeuIcon";
import { MaxDeliveryTimeBadge } from "../MaxDeliveryTimeBadge/MaxDeliveryTimeBadge";
import { ShippingCostBadge } from "../ShippingCostBadge/ShippingCostBadge";
import { InputBekeu } from "../../design-system/inputs/InputBekeu";
import arrowDown from "../../design-system/icons/Arrows/arrow-down.svg";
import { HistoryItem } from "../HistoryItem/HistoryItem";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import notFoundImg from "../../design-system/images/not-found.png";

const DetailItem = ({
  item,
  order,
  itemsState,
  getHistory,
  orderItem,
  newDeliveryArray,
}) => {
  const [quantityInput, setQuantityInput] = useState(
    item?.quantity - item?.quantity_sent
  );
  const [isOpen, setIsOpen] = useState(false);
  const mainState = useSelector((state) => state);
  const [imageError, setImageError] = useState(false);
  const [quantityValidation, setQuantityValidation] = useState(false);

  function handleHistoryClick() {
    setIsOpen(!isOpen);
    !isOpen && getHistory({ order_id: orderItem.id });
  }

  const totalQuantityValidation =
    item?.quantity === item?.quantity_sent + item?.quantity_cancel;

  useEffect(() => {
    setQuantityInput(item?.quantity - item?.quantity_sent);
    setQuantityValidation(totalQuantityValidation);
  }, [item, totalQuantityValidation, setQuantityValidation]);

  return (
    <>
      <Flex flexDir={"column"}>
        <Flex p="24px">
          <Flex
            w="88px"
            h="88px"
            mr="20px"
            border="1px solid #BDBDBD"
            borderRadius={"12px"}
            overflow="hidden"
            justifyContent={"center"}
            alignItems="center"
          >
            <Img
              src={
                imageError
                  ? notFoundImg
                  : item?.combination?.media?.image_source ||
                    item?.data_combination?.image_source
              }
              onError={() => setImageError(true)}
              draggable={"false"}
              objectFit="cover"
              title={
                imageError
                  ? "Imagen no disponible"
                  : item?.combination?.title ||
                    item?.data_combination?.comb_title
              }
              h={imageError ? "50%" : "100%"}
            />
          </Flex>
          <Flex justifyContent={"space-between"} width={"100%"} gap="12px">
            <Flex
              flexDir={"column"}
              justifyContent="space-between"
              w={"390px"}
              gap="12px"
            >
              <H6
                noOfLines={2}
                title={
                  item?.combination?.title || item?.data_combination?.comb_title
                }
              >
                {item?.combination?.title || item?.data_combination?.comb_title}
              </H6>
              <Paragraphs
                lineHeight="22px"
                overflow="hidden"
                noOfLines={2}
                color="brand.contentSecondary"
              >
                {item?.combination?.long_description ||
                  item?.data_combination?.comb_long_description}
              </Paragraphs>
              <Paragraphs lineHeight="22px" color="brand.contentSecondary">
                SKU: {item?.combination?.sku || item?.data_combination?.sku}
              </Paragraphs>
              <Flex flexDir={"column"}>
                <Paragraphs lineHeight={"22px"} mb="3px" fontWeight={600}>
                  Centros de costo:
                </Paragraphs>
                {item?.cost_centers?.map((item, key) => (
                  <Flex key={key} justifyContent={"space-between"} w="100%">
                    <Paragraphs lineHeight={"22px"}>
                      {item?.cost_center}
                    </Paragraphs>
                    <Paragraphs lineHeight={"22px"}>
                      {item?.percentage}%
                    </Paragraphs>
                  </Flex>
                ))}
              </Flex>
            </Flex>
            <Flex
              flexDir={"column"}
              gap="12px"
              h={"60px"}
              fontWeight={600}
              lineHeight="22px"
              fontSize={"16px"}
            >
              <Text>Precio del producto</Text>
              <Text>${item?.price_unit} + Imp</Text>
            </Flex>
            <Flex
              flexDir={"column"}
              gap="12px"
              h={"60px"}
              fontWeight={600}
              lineHeight="22px"
              fontSize={"16px"}
            >
              <Text>Cantidad comprada</Text>
              <Text>{item?.quantity}</Text>
            </Flex>
            <Flex
              flexDir={"column"}
              gap="12px"
              h={"60px"}
              fontWeight={600}
              lineHeight="22px"
              fontSize={"16px"}
            >
              <Text>Cantidad enviada</Text>
              <Text>{item?.quantity_sent}</Text>
            </Flex>
            <Flex
              flexDir={"column"}
              gap="12px"
              h={"60px"}
              fontWeight={600}
              lineHeight="22px"
              fontSize={"16px"}
            >
              <Text>Cantidad cancelada</Text>
              <Text>{item?.quantity_cancel}</Text>
            </Flex>
            <Flex
              flexDir={"column"}
              gap="12px"
              h={"60px"}
              fontWeight={600}
              lineHeight="22px"
              fontSize={"16px"}
            >
              <Text>Cantidad Confirmada</Text>
              <Text>{item?.quantity_confirmed}</Text>
            </Flex>
            {!totalQuantityValidation && (
              <Flex
                flexDir={"column"}
                gap="12px"
                h={"60px"}
                fontWeight={600}
                lineHeight="22px"
                fontSize={"16px"}
              >
                <Text>
                  Cantidad{" "}
                  {order?.state === "2"
                    ? "a enviar"
                    : order?.state === "5"
                    ? "cancelada"
                    : order?.state === "4"
                    ? "enviada"
                    : order?.state === "8"
                    ? "a enviar"
                    : order?.state === "9" && "a enviar"}
                </Text>
                <InputBekeu
                  borderRadius="20px"
                  height="48px"
                  width="140px"
                  disabled={order?.state === "5" || order?.state === "4"}
                  defaultValue={item?.quantity - item?.quantity_sent}
                  placeholder={item?.quantity - item?.quantity_sent}
                  value={quantityInput}
                  onChange={(e) => {
                    if (
                      e.target.value <=
                      item?.quantity - item?.quantity_sent
                    ) {
                      setQuantityInput(
                        Number(e.target.value.replace(/[^0-9,.]/g, ""))
                      );
                      itemsState.filter(
                        (a) => a.buy_order_item_id === item.id
                      )[0].qty_sent = Number(
                        e.target.value.replace(/[^0-9,.]/g, "")
                      );
                    }
                  }}
                  max={item?.quantity - item?.quantity_sent}
                  borderColor="rgba(226, 226, 226, 1)"
                  color="#000000"
                />
              </Flex>
            )}
          </Flex>
        </Flex>
        <Flex justifyContent={"flex-end"}>
          <ButtonBekeuLink
            onClick={handleHistoryClick}
            mr="24px"
            mb="24px"
            rightIcon={
              <Img
                style={{ transform: `rotate(${isOpen ? "180deg" : "0deg"})` }}
                src={arrowDown}
              />
            }
          >
            Ver historial
          </ButtonBekeuLink>
        </Flex>
        <Collapse
          in={isOpen}
          animateOpacity
          style={{
            overflow: `${isOpen ? "unset" : "hidden"}`,
            zIndex: 999,
          }}
        >
          {mainState?.sales?.historyLoading ? (
            <Flex justifyContent={"center"} alignItems="center">
              <Spinner mb="44px" />
            </Flex>
          ) : (
            <div
              style={{
                marginBottom: "44px",
              }}
            >
              {mainState?.sales?.history
                ?.filter(
                  (filterItem) => filterItem.buy_order_item_id === item.id
                )
                .map((note, id) => (
                  <HistoryItem
                    msg={note}
                    key={id}
                    product={item}
                    orderItem={orderItem}
                    newDeliveryArray={newDeliveryArray}
                  />
                ))}
            </div>
          )}
        </Collapse>

        <Divider />
      </Flex>
    </>
  );
};

const SalesItem = ({
  item,
  type,
  showitems,
  selected,
  state,
  getOneProduct,
  handleApproveOrder,
  handleRejectOrder,
  setRemitNumber,
  sellerComments,
  setSellerComments,
  handlePagination,
  remitNumber,
  params,
  itemArr,
  handleGetHistory,
  handleFillResult,
  getHistory,
  ...props
}) => {
  const history = useHistory();
  const [itemsState, setItemsState] = useState([]);
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    setItemsState(
      itemArr &&
        itemArr[0]?.buy_order_items?.map((orderItemVal) => {
          return {
            buy_order_item_id: orderItemVal?.id,
            qty_sent: orderItemVal?.quantity - orderItemVal?.quantity_sent,
            delivery_time: orderItemVal?.days_delivery_time_block,
          };
        })
    );
  }, [itemArr]);

  function handleScrollTo() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }

  let newDeliveryArray = [];

  if (item && item.buy_order_items) {
    item.buy_order_items.forEach((x, i) => {
      if (!newDeliveryArray.hasOwnProperty(x.days_delivery_time_block)) {
        newDeliveryArray[x.days_delivery_time_block] = {
          days_delivery_time_block: [],
        };
      }

      newDeliveryArray[
        x.days_delivery_time_block
      ].days_delivery_time_block.push({
        ...x,
        days_delivery_time_block: x.days_delivery_time_block,
      });
    });
  }

  let startdate = moment(item.created_at).format("DD/MM/YYYY");
  let deliveryNumber = item?.buy_order_items
    ?.map((i) => i?.days_delivery_time_block)
    .reduce(function(a, b) {
      return Math.max(a, b);
    }, -Infinity);

  let deliveryDate = moment(startdate, "DD/MM/YYYY")
    .add("days", deliveryNumber)
    .format("DD/MM/YYYY");

  useEffect(() => {
    let sum = 0;

    if (
      !showitems &&
      item?.buy_order_items &&
      item?.buy_order_items.length > 0
    ) {
      item.buy_order_items.map((m) => {
        return (sum += m.quantity);
      });
      setTotalItems(sum);
    }
    if (item?.buy_order_items && item?.buy_order_items.length === 0) {
      setTotalItems(0);
    }
  }, [item.buy_order_items, showitems]);

  //parsed variables
  const parsedArs = parseInt(item?.total_w_shipping_ARP) !== 0;
  const parsedUsd = parseInt(item?.total_w_shipping_USD) !== 0;
  const parsedShippingArs = parseInt(item?.shipping_cost_ARP) !== 0;
  const parsedShippingUsd = parseInt(item?.shipping_cost_USD) !== 0;

  return (
    <BoxBekeu {...props} p="0">
      <Flex flexDir={"column"}>
        <Flex alignItems={"center"} justifyContent={"space-between"} p="24px">
          <Flex flexDir={"column"}>
            <Flex>
              <H6 mr="2">Fecha de compra:</H6>
              <H6 fontWeight="400">
                {moment(item.created_at).format("DD/MM/YYYY")}
              </H6>
            </Flex>
            {!showitems && (
              <Flex>
                <H6 mr="2">Fecha estimada de entrega:</H6>
                <H6 fontWeight="400">{deliveryDate}</H6>
              </Flex>
            )}

            {showitems && (
              <Text mt="12px">Email: {item?.client?.user?.email}</Text>
            )}
          </Flex>
          <Flex>
            {item?.erp_number && (
              <Text
                fontWeight={400}
                fontSize={"16px"}
                border="1px solid"
                borderColor={"brand.contentSecondary"}
                padding={"8px"}
                px={"12px"}
                borderRadius={"12px"}
                color="brand.contentSecondary"
                mr="24px"
              >
                Nro de Pedido: {item?.erp_number}
              </Text>
            )}
            {!showitems && (
              <SalesBadge type={type} item={item}>
                {item?.state === "2"
                  ? "Pendiente de envío"
                  : item?.state === "5"
                  ? "Cancelada"
                  : item?.state === "4"
                  ? "Enviada"
                  : item?.state === "7"
                  ? "Confirmada por cliente"
                  : item?.state === "9"
                  ? "Entrega en proceso"
                  : item?.state === "10"
                  ? "Finalizada"
                  : item?.state === "8" && "Reclamada"}
              </SalesBadge>
            )}
            {showitems && (
              <Text
                fontWeight={600}
                fontSize={"16px"}
                border="1px solid"
                borderColor={"brand.contentSecondary"}
                padding={"8px"}
                px={"12px"}
                borderRadius={"12px"}
                color="brand.contentSecondary"
              >
                Compra {item?.internal_code}
              </Text>
            )}
          </Flex>
        </Flex>
        <Divider />
        {!showitems && (
          <>
            <Flex justifyContent={"space-between"} p={"24px"} gap="24px">
              <Flex flexDir={"column"} gap="12px">
                <Paragraphs fontWeight={600} lineHeight={"22px"}>
                  Comprador
                </Paragraphs>
                <Paragraphs lineHeight={"22px"}>
                  {item?.client?.user?.email}
                </Paragraphs>
              </Flex>
              <Flex flexDir={"column"} gap="12px">
                <Paragraphs fontWeight={600} lineHeight={"22px"}>
                  Orden de compra Nº
                </Paragraphs>
                <Paragraphs lineHeight={"22px"}>{item?.id}</Paragraphs>
              </Flex>
              <Flex flexDir={"column"} gap="12px">
                <Paragraphs fontWeight={600} lineHeight={"22px"}>
                  Zona de entrega
                </Paragraphs>
                <Paragraphs lineHeight={"22px"}>
                  {item?.address_description}
                </Paragraphs>
              </Flex>
              <Flex flexDir={"column"} gap="12px" maxW={"24rem"}>
                <Paragraphs fontWeight={600} lineHeight={"22px"}>
                  Comentarios del comprador
                </Paragraphs>
                <Paragraphs lineHeight={"22px"} noOfLines={5}>
                  {!item?.provider_comments ? "-" : item?.provider_comments}
                </Paragraphs>
              </Flex>
              <Flex flexDir={"column"}>
                <Paragraphs fontWeight={600} lineHeight={"22px"}>
                  Total de productos{" "}
                </Paragraphs>
                <Paragraphs lineHeight={"22px"}>
                  {item?.buy_order_items && totalItems}
                </Paragraphs>
              </Flex>
            </Flex>
            <Divider />
          </>
        )}
        {showitems &&
          newDeliveryArray.map((newDeliveryArray, id) => {
            const allQuantitySent = newDeliveryArray?.days_delivery_time_block.filter(
              (item) =>
                item?.quantity === item?.quantity_sent + item?.quantity_cancel
            );

            const allQuantitySentValidation =
              allQuantitySent?.length ===
              newDeliveryArray?.days_delivery_time_block?.length;
            const enableRejectOrder =
              newDeliveryArray?.days_delivery_time_block?.[0]
                ?.quantity_confirmed ===
              newDeliveryArray?.days_delivery_time_block?.[0]?.quantity_sent;

            return (
              <>
                <Flex
                  key={id}
                  px="24px"
                  py="16px"
                  alignItems={"center"}
                  justifyContent="space-between"
                >
                  <Flex>
                    <MaxDeliveryTimeBadge
                      days={
                        newDeliveryArray?.days_delivery_time_block[0]
                          ?.days_delivery_time_block
                      }
                      mr="24px"
                    />
                    <ShippingCostBadge
                      currency={
                        parseInt(item?.shipping_cost_ARP) !== 0
                          ? "ARS"
                          : parseInt(item?.shipping_cost_USD) !== 0 && "USD"
                      }
                      shippingCost={
                        (parseInt(item?.shipping_cost_ARP) !== 0 &&
                          item?.shipping_cost_ARP) ||
                        (parseInt(item?.shipping_cost_USD) !== 0 &&
                          item?.shipping_cost_USD)
                      }
                    />
                  </Flex>
                  <SalesBadge type={type} item={item}>
                    {item?.state === "2"
                      ? "Pendiente de envío"
                      : item?.state === "5"
                      ? "Cancelada"
                      : item?.state === "4"
                      ? "Enviada"
                      : item?.state === "7"
                      ? "Confirmada por cliente"
                      : item?.state === "9"
                      ? "Entrega en proceso"
                      : item?.state === "10"
                      ? "Finalizada"
                      : item?.state === "8" && "Reclamada"}
                  </SalesBadge>
                </Flex>
                <Divider />

                {newDeliveryArray?.days_delivery_time_block?.map(
                  (product, id) => {
                    return (
                      <DetailItem
                        key={id}
                        item={product}
                        sellerData={item?.provider}
                        getOneProduct={getOneProduct}
                        order={item}
                        itemsState={itemsState}
                        setItemsState={setItemsState}
                        handleGetHistory={handleGetHistory}
                        orderItem={item}
                        getHistory={getHistory}
                        newDeliveryArray={newDeliveryArray}
                      />
                    );
                  }
                )}

                {item.state === "2" && (
                  <Flex
                    alignItems={"center"}
                    p="24px"
                    w="100%"
                    gap="12px"
                    justifyContent={"flex-end"}
                  >
                    <SalesRejectModal
                      handleRejectOrder={handleRejectOrder}
                      item={item}
                      setSellerComments={setSellerComments}
                      sellerComments={sellerComments}
                      handlePagination={handlePagination}
                      handleFillResult={handleFillResult}
                      deliveryTime={
                        newDeliveryArray?.days_delivery_time_block[0]
                          ?.days_delivery_time_block
                      }
                      itemsState={itemsState.filter(
                        (i) =>
                          i?.delivery_time ===
                          newDeliveryArray?.days_delivery_time_block[0]
                            ?.days_delivery_time_block
                      )}
                      enableRejectOrder={enableRejectOrder}
                    />
                    <SalesSuccessModal
                      remitNumber={remitNumber}
                      handleApproveOrder={handleApproveOrder}
                      setRemitNumber={setRemitNumber}
                      item={item}
                      handleFillResult={handleFillResult}
                      handlePagination={handlePagination}
                      itemsState={itemsState.filter(
                        (i) =>
                          i?.delivery_time ===
                          newDeliveryArray?.days_delivery_time_block[0]
                            ?.days_delivery_time_block
                      )}
                    />
                  </Flex>
                )}
                {item.state === "9" && !allQuantitySentValidation && (
                  <Flex
                    alignItems={"center"}
                    p="24px"
                    w="100%"
                    gap="12px"
                    justifyContent={"flex-end"}
                  >
                    <SalesRejectModal
                      handleRejectOrder={handleRejectOrder}
                      item={item}
                      setSellerComments={setSellerComments}
                      sellerComments={sellerComments}
                      handlePagination={handlePagination}
                      handleFillResult={handleFillResult}
                      deliveryTime={
                        newDeliveryArray?.days_delivery_time_block[0]
                          ?.days_delivery_time_block
                      }
                      itemsState={itemsState.filter(
                        (i) =>
                          i?.delivery_time ===
                          newDeliveryArray?.days_delivery_time_block[0]
                            ?.days_delivery_time_block
                      )}
                      enableRejectOrder={enableRejectOrder}
                    />
                    <SalesSuccessModal
                      remitNumber={remitNumber}
                      handleApproveOrder={handleApproveOrder}
                      setRemitNumber={setRemitNumber}
                      item={item}
                      handleFillResult={handleFillResult}
                      handlePagination={handlePagination}
                      itemsState={itemsState.filter(
                        (i) =>
                          i?.delivery_time ===
                          newDeliveryArray?.days_delivery_time_block[0]
                            ?.days_delivery_time_block
                      )}
                    />
                  </Flex>
                )}
              </>
            );
          })}
        {!showitems && (
          <Flex flexDir={"column"} alignItems="flex-end" mr="24px">
            {parsedArs && (
              <>
                <Flex gap={"12px"} alignItems="center" mt={"14px"}>
                  <H6 fontWeight="400">Subtotal en ARS:</H6>
                  <H6 fontWeight="400">{item?.total_ARP} + Imp </H6>
                </Flex>
                {!parsedShippingArs ? (
                  <Flex gap={"12px"} alignItems="center" mt="8px">
                    <H6>Costo envío en ARS:</H6>
                    <H6 color="brand.success">Envío gratis</H6>
                  </Flex>
                ) : (
                  <Flex gap={"12px"} alignItems="center" mt="8px">
                    <H6 fontWeight="400">Costo envío en ARS:</H6>
                    <H6 fontWeight="400">{item?.shipping_cost_ARP} + Imp </H6>
                  </Flex>
                )}
                <Flex gap={"12px"} mt="8px">
                  <H6>Total en ARS:</H6>
                  <H6>{item?.total_w_shipping_ARP} + Imp </H6>
                </Flex>
              </>
            )}
            {parsedUsd && (
              <>
                <H6 mt={"14px"} fontWeight="400">
                  Subtotal en USD: {item?.total_USD} + Imp{" "}
                </H6>
                {!parsedShippingUsd ? (
                  <Flex gap={"12px"} alignItems="center" mt="8px">
                    <H6> Costo envío en USD:</H6>
                    <H6 color="brand.success">Envío gratis</H6>
                  </Flex>
                ) : (
                  <H6 mt="8px" fontWeight="400">
                    Costo envío en USD: {item?.shipping_cost_USD} + Imp{" "}
                  </H6>
                )}
                <Flex gap={"12px"} mt="8px">
                  <H6>Total en USD:</H6>
                  <H6>{item?.total_w_shipping_USD} + Imp </H6>
                </Flex>
              </>
            )}
          </Flex>
        )}
        <Flex
          justifyContent={
            item?.state === "2" && showitems && !selected
              ? "space-between"
              : "flex-end"
          }
          alignItems={"center"}
        >
          {!selected && (
            <>
              <Link
                to={`/sales/order-detail/${item.id}/?state=${item.state}`}
                _focus={{ boxShadow: "rgba(0,0,0,0)" }}
              >
                <ButtonBekeuLink
                  leftIcon={<BekeuIcon src={eye} />}
                  p="24px"
                  onClick={() => {
                    handleScrollTo();
                  }}
                >
                  Ver Pedido
                </ButtonBekeuLink>
              </Link>
              {item.state === "2" && showitems && (
                <Flex
                  alignItems={"center"}
                  p="24px"
                  w="100%"
                  gap="12px"
                  justifyContent={"flex-end"}
                >
                  <SalesRejectModal
                    handleRejectOrder={handleRejectOrder}
                    item={item}
                    setSellerComments={setSellerComments}
                    sellerComments={sellerComments}
                    handlePagination={handlePagination}
                    deliveryTime={deliveryNumber}
                    itemsState={itemsState}
                    handleFillResult={handleFillResult}
                    // enableRejectOrder={enableRejectOrder}
                  />
                  <SalesSuccessModal
                    remitNumber={remitNumber}
                    handleApproveOrder={handleApproveOrder}
                    setRemitNumber={setRemitNumber}
                    item={item}
                    handlePagination={handlePagination}
                    itemsState={itemsState}
                    handleFillResult={handleFillResult}
                  />
                </Flex>
              )}
            </>
          )}
        </Flex>
        {showitems && (
          <Flex flexDir={"column"}>
            <Text
              py="16px"
              px="24px"
              fontSize={"20px"}
              fontWeight={400}
              lineHeight="27px"
            >
              Resumen de compra
            </Text>
            <Divider />
            <Flex py="24px">
              <Flex flexDir={"column"} gap="12px">
                {parsedArs && (
                  <H6 px="24px" fontWeight={400}>
                    Subtotal en ARS
                  </H6>
                )}
                {parsedUsd && (
                  <H6 px="24px" fontWeight={400}>
                    Subtotal en USD
                  </H6>
                )}
                {parsedArs && (
                  <H6 px="24px" fontWeight={400}>
                    Costo envío en ARS
                  </H6>
                )}
                {parsedUsd && (
                  <H6 px="24px" fontWeight={400}>
                    Costo envío en USD
                  </H6>
                )}
                {parsedArs && <H6 px="24px">Total en ARS</H6>}
                {parsedUsd && <H6 px="24px">Total en USD</H6>}
              </Flex>
              <Flex flexDir={"column"} gap="12px">
                {parsedArs && (
                  <H6 px="12px" fontWeight={400}>
                    {item?.total_ARP} + Imp
                  </H6>
                )}
                {parsedUsd && (
                  <H6 px="12px" fontWeight={400}>
                    {item?.total_USD} + Imp
                  </H6>
                )}
                {parsedArs &&
                  (parsedShippingArs ? (
                    <H6 px="12px" fontWeight={400}>
                      {item?.shipping_cost_ARP} + Imp
                    </H6>
                  ) : (
                    <H6 px="12px" color="brand.success">
                      Envío gratis
                    </H6>
                  ))}
                {parsedUsd &&
                  (parsedShippingUsd ? (
                    <H6 px="12px" fontWeight={400}>
                      {item?.shipping_cost_USD} + Imp
                    </H6>
                  ) : (
                    <H6 px="12px" color="brand.success">
                      Envío gratis
                    </H6>
                  ))}
                {parsedArs && (
                  <H6 px="12px">{item?.total_w_shipping_ARP} + Imp</H6>
                )}
                {parsedUsd && (
                  <H6 px="12px">{item?.total_w_shipping_USD} + Imp</H6>
                )}
              </Flex>
            </Flex>
          </Flex>
        )}
      </Flex>
    </BoxBekeu>
  );
};

export default injectIntl(connect(null, product.actions)(SalesItem));