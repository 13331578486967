import React from "react";
import {
  Modal,
  useDisclosure,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Flex,
  Img,
  Box,
  useToast,
  OrderedList,
  ListItem,
  Text,
  Divider,
} from "@chakra-ui/react";
import smsTracking from "../../design-system/icons/Contact/sms-tracking.svg";
import { H5 } from "../../design-system/headings/H5";
import { ButtonBekeu } from "../../design-system/forms/ButtonBekeu";
import { Paragraphs } from "../../design-system/headings/Paragraphs";
import { connect, useSelector } from "react-redux";
import { injectIntl } from "react-intl";

import { actions as cartActions } from "../../store/cart/cart.duck";
import { useEffect } from "react";
import { useState } from "react";
import { ButtonBekeuOutline } from "../../design-system/forms/ButtonBekeuOutline";
import { useHistory } from "react-router-dom";
import { ErrorModal } from "../ErrorModal/ErrorModal";
import errorCircle from "../../design-system/icons/Errors/error-circle.svg";

const CartSuccessModal = ({ isDisabled, handleEndsBuy, cart, getCart }) => {
  const toast = useToast();
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenErrorModal,
    onOpen: onOpenErrorModal,
    onClose: onCloseErrorModal,
  } = useDisclosure();

  const [orderData, setOrderData] = useState({
    cartId: cart?.id,
    totalARP: cart?.total_ARP,
    totalUSD: cart?.total_USD,
    internalCode: cart?.internal_code,
    shipping_cost_ARP: cart?.shipping_cost_ARP,
    shipping_cost_USD: cart?.shipping_cost_USD,
    total_w_shipping_ARP: cart?.total_w_shipping_ARP,
    total_w_shipping_USD: cart?.total_w_shipping_USD,
  });
  const mainState = useSelector((state) => state);
  const [loading, setLoading] = useState(false);

  const handleClickButton = () => {
    handleEndsBuy();
    onOpen();
    setLoading(true);
  };

  useEffect(() => {
    cart?.id &&
      setOrderData({
        cartId: cart?.id,
        totalARP: cart?.total_ARP,
        totalUSD: cart?.total_USD,
        internalCode: cart?.internal_code,
        shipping_cost_ARP: cart?.shipping_cost_ARP,
        shipping_cost_USD: cart?.shipping_cost_USD,
        total_w_shipping_ARP: cart?.total_w_shipping_ARP,
        total_w_shipping_USD: cart?.total_w_shipping_USD,
      });
  }, [cart]);

  function handleScrollTo() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }

  const arrayProduct = [];

  const hasError = `${mainState.cart.result}`;

  const handleErrorEndBuy = () => {
    handleScrollTo();
    cart.items.map((item) => arrayProduct.push(item?.error));
    if (arrayProduct.includes(true)) {
      toast({
        title: "Error al finalizar compra",
        description:
          "No se puede generar orden de compra con productos fuera de stock",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } else if (hasError.includes("error")) {
      onOpenErrorModal();
      handleScrollTo();
    } else {
      handleClickButton();
    }
  };

  return (
    <>
      <ButtonBekeu
        onClick={handleErrorEndBuy}
        isDisabled={isDisabled}
        isLoading={loading && !mainState.cart.endsBuyResult}
      >
        Finalizar compra
      </ButtonBekeu>
      <ErrorModal
        isOpen={isOpenErrorModal}
        onClose={onCloseErrorModal}
        codeError={mainState.cart.result}
        getCart={getCart}
      />
      {mainState.cart.endsBuyResult && (
        <Modal isOpen={isOpen} onClose={onClose} size="2xl" isCentered>
          <ModalOverlay />
          <ModalContent borderRadius={"15px"} minW="528px">
            <ModalCloseButton _focus={{ boxShadow: "rgba(0,0,0,0)" }} />
            <ModalBody borderRadius={"15px"} p={"48px"}>
              <Flex
                flexDir={"column"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                {mainState.cart.endsBuyResultValue.message?.success?.length !==
                0 ? (
                  <>
                    <Flex alignItems={"center"} my={6}>
                      <Img src={smsTracking} alt="smsTracking" />
                    </Flex>
                    <H5 mb={4}>¡Tu compra se realizó con éxito!</H5>
                    {mainState?.auth?.user?.authorizer?.user?.email && (
                      <Box
                        color={"brand.primary"}
                        bg={"rgba(100, 36, 126, 0.1)"}
                        px={2}
                        borderRadius={"12px"}
                        w={"fit-content"}
                        mb={4}
                      >
                        {`Aprobador: ${mainState?.auth?.user?.authorizer?.user?.email}`}
                      </Box>
                    )}
                  </>
                ) : (
                  <>
                    <Flex alignItems={"center"} my={6}>
                      <Img src={errorCircle} alt="error" />
                    </Flex>
                    <H5 mb={4}>Algo salió mal</H5>
                    <Paragraphs align="center" lineHeight="22px">
                      Error al finalizar compra.
                    </Paragraphs>
                  </>
                )}
              </Flex>
              <Flex
                flexDir={"column"}
                p={"24px"}
                justifyContent={"space-between"}
                gap="16px"
                px="32px"
              >
                {mainState.cart.endsBuyResultValue.message &&
                  Object.keys(
                    mainState.cart.endsBuyResultValue.message.success
                  ).map((success) => {
                    const sapMsg =
                      mainState.cart.endsBuyResultValue?.message?.success[
                        success
                      ][0];

                    const sapNumber = sapMsg?.replace(/^\D+/g, "");

                    return (
                      <Flex flexDir={"column"}>
                        <Paragraphs
                          fontWeight={600}
                          fontSize={"1.125rem"}
                          color="brand.success"
                        >
                          Compra Nº {success}
                        </Paragraphs>
                        <Paragraphs lineHeight="1.25rem">
                          {sapNumber.length > 0 &&
                          `Creada con el Nro de Pedido SAP ${sapNumber}`}
                        </Paragraphs>
                      </Flex>
                    );
                  })}
                {mainState.cart.endsBuyResultValue.message?.error?.length !==
                  0 && (
                  <>
                    <Divider />
                    {mainState.cart.endsBuyResultValue.message &&
                      Object.keys(
                        mainState.cart.endsBuyResultValue.message.error
                      ).map((error) => {
                        return (
                          <Flex flexDir={"column"}>
                            <Paragraphs
                              fontWeight={600}
                              fontSize={"1.125rem"}
                              color="brand.error"
                            >
                              Compra Nº {error}
                            </Paragraphs>
                            <Paragraphs fontWeight={600} fontSize={"1rem"}>
                              La compra no pudo realizarse por los siguientes
                              motivos:
                            </Paragraphs>
                            <Paragraphs lineHeight="1.25rem">
                              <OrderedList>
                                {mainState.cart?.endsBuyResultValue?.message?.error[
                                  error
                                ].map((item, id) => (
                                  <ListItem key={id}>{item}</ListItem>
                                ))}
                              </OrderedList>
                            </Paragraphs>
                          </Flex>
                        );
                      })}
                  </>
                )}
                <Divider />
              </Flex>
              <Flex
                gap={1}
                fontSize="0.9125rem"
                justifyContent={"center"}
                mb={4}
              >
                <Text color="brand.contentSecondary">
                  Dudas y/o consultas a
                </Text>
                <Text color="brand.primary">info@bekeu.com</Text>
              </Flex>
              <Flex gap="24px">
                <ButtonBekeuOutline onClick={() => history.push("/")}>
                  Volver al home
                </ButtonBekeuOutline>
                <ButtonBekeu
                  my={0}
                  onClick={() =>
                    history.push("/my-orders/?state=3&page=1&per_page=20")
                  }
                >
                  Ir a mis compras
                </ButtonBekeu>
              </Flex>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

export default injectIntl(connect(null, cartActions)(CartSuccessModal));
