import React from "react";
import "./Footer.scss";
import { Button, Flex, Link, Text } from "@chakra-ui/react";
import Logo from "../Logo/Logo";
import linkedin from "../../design-system/icons/Social/linkedin.svg";
import instagram from "../../design-system/icons/Social/instagram.svg";
import { BekeuIcon } from "../BekeuIcon/BekeuIcon";
import { urlsBase } from "../../../config";

export const AWS_URL = `${urlsBase.aws_url}`;

const footerItems = [
  {
    id: 1,
    name: "Manual de usuario",
    //link: `${AWS_URL}/products/Manual_Usuario.pdf`,
    link: `${AWS_URL}/products/Manual_Usuario_v2.pdf`,
  },
];

const Footer = ({ userType }) => {
  return (
    <Flex bg="brand.primary" justifyContent="center">
      <Flex flexDir={"column"} w="75rem">
        <Flex
          alignItems={"center"}
          h={"130px"}
          justifyContent={"space-between"}
        >
          <Logo />
          <Flex alignItems={"center"}>
            {footerItems.map((footerItem, id) => (
              <Button
                key={id}
                as={Link}
                variant={"ghost"}
                color="white"
                p={4}
                borderRadius={"12px"}
                _hover={{ bg: "brand.hover" }}
                _focus={{
                  boxShadow:
                    "0 0 1px 2px rgba(122, 57, 162, 1), 0 1px 1px rgba(122, 57, 162, 1)",
                }}
                style={{ textDecoration: "none" }}
                href={footerItem.link}
                isExternal
                fontSize={"16px"}
                fontWeight={"normal"}
              >
                {footerItem.name}
              </Button>
            ))}
          </Flex>
        </Flex>
        <Flex h={"80px"} alignItems={"center"} justifyContent="flex-end">
          <Text
            fontSize={"16px"}
            fontWeight={"normal"}
            color={"brand.white"}
            mr={8}
          >
            Seguinos en
          </Text>
          <Flex alignItems={"center"}>
            <Button
              as={Link}
              variant={"ghost"}
              p={2}
              borderRadius={"12px"}
              _hover={{ bg: "brand.hover" }}
              _focus={{
                boxShadow:
                  "0 0 1px 2px rgba(122, 57, 162, 1), 0 1px 1px rgba(122, 57, 162, 1)",
              }}
              href={"https://www.linkedin.com/company/bekeu/"}
              isExternal
            >
              <BekeuIcon src={linkedin} />
            </Button>
            <Button
              as={Link}
              variant={"ghost"}
              p={2}
              borderRadius={"12px"}
              _hover={{ bg: "brand.hover" }}
              _focus={{
                boxShadow:
                  "0 0 1px 2px rgba(122, 57, 162, 1), 0 1px 1px rgba(122, 57, 162, 1)",
              }}
              href={"https://www.instagram.com/bekeu_la/"}
              isExternal
            >
              <BekeuIcon src={instagram} />
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Footer;
