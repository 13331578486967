import React, { useEffect, useState } from "react";
import { Flex, Progress, useDisclosure } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { ApproveOrdersBreadcrumb } from "../../components/ApproveOrdersBreadcrumb/ApproveOrdersBreadcrumb";
import { H5 } from "../../design-system/headings/H5";
import { injectIntl } from "react-intl";
import { connect, useSelector } from "react-redux";
import { actions as orderActions } from "../../pages/myOrdersPage/ducks/myOrders.duck";
import * as product from "../../pages/productGridPage/ducks/productsGrid.duck";
import MyOrdersItem from "../MyOrdersItem/MyOrdersItem";
import { ErrorModal } from "../../components/ErrorModal/ErrorModal";
import { Paragraphs } from "../../design-system/headings/Paragraphs";

const MyOrdersStatus = (props) => {
  const params = useParams();
  const mainState = useSelector((state) => state);
  const [claim, setClaim] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [fileNameInput, setFileNameInput] = useState({ name: "", file: null});

  const hasError = `${mainState.carts.result}`;

  useEffect(() => {
    props.listDetailCart({ order_id: params.orderId });
  }, []);

  useEffect(() => {
    (hasError.includes("generalError") || hasError.includes("fileError")) && onOpen();
  }, [hasError, onOpen]);

  const filteredItem = mainState.carts.carts;

  const handleConfirmOrderCart = (item, dispatchNoteNumber) => {
    props.confirmMyOrdersCart({
      orderId: item,
      dispatchNoteNumber,
      file: fileNameInput
    });
  };

  const handleClaimOrderCart = (item, dispatchNoteNumber) => {
    props.claimMyOrdersCart({
      orderId: item?.id,
      claimMessage: claim,
      providerId: item?.provider_id,
      dispatchNoteNumber,
    });
  };

  const handlePagination = () => {
    props.listDetailCart({ order_id: params.orderId });
  };

  return (
    <Flex justifyContent={"center"}>
      <Flex
        minH={"100vh"}
        flexDir={"column"}
        py={"40px"}
        bg={"brand.background"}
        w="75rem"
        {...props}
      >
        <ApproveOrdersBreadcrumb />
        <H5 mt={"32px"}>Estado de la compra</H5>
        {mainState.carts.loading ? (
          <Progress
            size="xs"
            isIndeterminate
            colorScheme={"purple"}
            mt={"10"}
          />
        ) : filteredItem && filteredItem.length > 0 ? (
          filteredItem.map((item, id, itemArr) => {
            return (
              <MyOrdersItem
                mt={"32px"}
                key={id}
                item={item}
                showitems
                selected
                status={item.state === "pending" ? true : undefined}
                handleConfirmOrderCart={handleConfirmOrderCart}
                handleClaimOrderCart={handleClaimOrderCart}
                setFileNameInput={setFileNameInput}
                fileNameInput={fileNameInput}
                handlePagination={handlePagination}
                setClaim={setClaim}
                claim={claim}
                user={props.user}
                getHistory={props.getHistory}
                itemArr={itemArr}
              />
            );
          })
        ) : (
          "No se encontraron resultados"
        )}
        <ErrorModal isOpen={isOpen} onClose={onClose} codeError={hasError} />
      </Flex>
    </Flex>
  );
};

export default injectIntl(
  connect(null, { ...orderActions, ...product.actions })(MyOrdersStatus)
);
