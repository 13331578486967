import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Flex,
  Img,
} from "@chakra-ui/react";
import errorCircle from "../../design-system/icons/Errors/error-circle.svg";
import { H5 } from "../../design-system/headings/H5";
import { Paragraphs } from "../../design-system/headings/Paragraphs";

export const ErrorModal = ({
  isOpen,
  onClose,
  codeError,
  customOnCloseComplete,
  customComponent,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
      isCentered
      onCloseComplete={customOnCloseComplete}
    >
      <ModalOverlay />
      <ModalContent borderRadius="12px">
        <ModalCloseButton _focus={{ boxShadow: "rgba(0,0,0,0)" }} />
        <ModalBody borderRadius={"12px"}>
          <Flex
            flexDir={"column"}
            p={"45px 35px"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Flex alignItems={"center"} mb={8}>
              <Img src={errorCircle} alt="Error" />
            </Flex>

            {!codeError === "generalError" &&
              !codeError === "generalErrorImport" &&
              !codeError === "error 1" &&
              !codeError === "error 2" && (
                <>
                  <H5>Algo salió mal</H5>
                  <Paragraphs align="center" lineHeight="22px">
                    Lo solucionaremos a la brevedad.
                  </Paragraphs>
                </>
            )}
            
            {codeError === "generalError" && <H5>Algo salió mal</H5>}
            {codeError === "fileError" && <H5>Algo salió mal</H5>}
            {codeError === "generalErrorImport" && <H5>Algo salió mal</H5>}
            {codeError === "error 1" ||
              (codeError === "error 2" && (
                <H5>No es posible agregar este producto</H5>
              ))}
            <Paragraphs align="center" lineHeight="22px">
              {codeError === "error 1"
                ? "Recordá que los productos que agregues al carrito deben compartir la misma zona de entrega."
                : codeError === "error 2" &&
                  "Recordá que los productos que agregues al carrito no deben superar el monto establecido."}
              {codeError === "generalError" &&
                "Lo solucionaremos a la brevedad."}
              {codeError === "generalErrorImport" &&
                "Error al importar el archivo."}
            </Paragraphs>
            {!codeError === "generalError" &&
              !codeError === "generalErrorImport" &&
              !codeError === "error 1" &&
              !codeError === "error 2" && (
                <>
                  <H5>Algo salió mal</H5>
                  <Paragraphs align="center" lineHeight="22px">
                    Lo solucionaremos a la brevedad.
                  </Paragraphs>
                </>
              )}
            {customComponent}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
