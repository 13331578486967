import { Flex, Heading, Img, Text } from "@chakra-ui/react";
import React from "react";
import group1 from "../../design-system/images/group1.svg";
import group2 from "../../design-system/images/group2.svg";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import vector from "../../../app/home-images/vector.svg";
import vector2 from "../../../app/home-images/vector2.svg";
import frame50 from "../../../app/home-images/frame50.svg";
import frame49 from "../../../app/home-images/frame49.svg";
import frame51 from "../../../app/home-images/frame51.svg";
import "./homeWelcome.css";

const HomeWelcome = () => {
  return (
    <Flex justifyContent="center">
      <Flex
        my={"135px"}
        justifyContent="space-evenly"
        className="animate__animated animate__fadeIn"
        w="75rem"
      >
        <Flex flexDir={"column"} mt={10}>
          <Flex w={"506px"} alignItems={"end"} mb={4} zIndex="999">
            <Heading as="h1" fontSize={"48px"} fontWeight={400}>
              Bienvenido al marketplace de
            </Heading>
            <Heading as="h1" fontSize={"48px"} fontWeight={600}>
              Bekeu
            </Heading>
          </Flex>
          <Flex w={"516px"} flexDir={"column"}>
            <Text
              fontSize={"16px"}
              fontWeight={400}
              color={"brand.contentSecondary"}
            >
              Comprá de forma eficiente con la información adecuada, en menor
              tiempo y con la mejor competencia del mercado.
            </Text>
          </Flex>
        </Flex>
        <Flex
          draggable="false"
          className="animate__animated animate__fadeIn"
          justifyContent={"center"}
          alignItems="center"
        >
          <Flex w={"100%"} height={"100%"}>
            <Img
              src={frame50}
              position="absolute"
              marginBottom={"220px"}
              marginLeft={"150px"}
              zIndex="999"
              draggable="false"
              className="floatingIcon"
            />
            <Img
              src={frame49}
              position="absolute"
              marginBottom={"150px"}
              marginLeft={"-110px"}
              zIndex="999"
              draggable="false"
              className="floatingIcon"
            />
            <Img
              src={frame51}
              position="absolute"
              marginTop={"250px"}
              marginRight={"20px"}
              zIndex="999"
              draggable="false"
              className="floatingIcon"
            />
          </Flex>
          <Img
            src={vector}
            position="absolute"
            className="floating"
            draggable="false"
          />
          <Img
            src={vector2}
            position="absolute"
            className="floating"
            draggable="false"
          />
          <Carousel
            autoPlay
            infiniteLoop
            interval={3000}
            animationHandler={"fade"}
            showArrows={false}
            showStatus={false}
            showIndicators={false}
            showThumbs={false}
            width={"350px"}
          >
            <Img
              src={group1}
              draggable="false"
              className="animate__animated animate__fadeIn"
            />
            <Img
              src={group2}
              draggable="false"
              className="animate__animated animate__fadeIn"
            />
          </Carousel>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default React.memo(HomeWelcome);
