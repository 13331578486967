import React, { useEffect, useState } from "react";
import {
  Button,
  Img,
  Menu,
  MenuButton,
  Text,
  MenuList,
  Flex,
  Divider,
  Box,
  Spinner,
  useDisclosure,
  OrderedList,
  ListItem,
} from "@chakra-ui/react";
import shopping from "../../design-system/icons/Navbar/shopping-cart.svg";
import { Badge } from "@material-ui/core";
import { ButtonBekeuM } from "../../design-system/forms/ButtonBekeuM";
import { Paragraphs } from "../../design-system/headings/Paragraphs";
import trash from "../../design-system/icons/Cart/trash.svg";
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { actions as cartActions } from "../../store/cart/cart.duck";
import { BekeuIcon } from "../BekeuIcon/BekeuIcon";
import notFoundImage from "../../design-system/images/not-found.png";
import { MiniCartItem } from "./MiniCartItem";
import { ErrorModal } from "../../components/ErrorModal/ErrorModal";
import { H5 } from "../../design-system/headings/H5";

const Cart = ({ getCart, removeCartItem, getCostCenter }) => {
  const cartItems = useSelector((state) => state.cart.items);
  const cart = useSelector((state) => state.cart);
  const [cartState, setCartState] = useState(false);
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const {
    isOpen: isOpenErrorModal,
    onOpen: onOpenErrorModal,
    onClose: onCloseErrorModal,
  } = useDisclosure();

  const cartResult = cart.result;
  const hasError = `${cart.result?.type}`;

  useEffect(() => {
    getCart();
  }, [getCart]);

  useEffect(() => {
    hasError.includes("generalError") && onOpenErrorModal();
  }, [hasError, onOpenErrorModal]);

  const handleRemoveCartItem = (item) => {
    removeCartItem(item);
  };
  useEffect(() => {
    cart.result &&
      cart.result !== "buy-now" &&
      history.location.pathname !== "/cart" &&
      setIsOpen(true);
  }, [cart, history.location.pathname]);

  const [imageError, setImageError] = useState(false);

  const parsedArs = parseInt(cart?.items?.total_ARP) !== 0;
  const parsedUsd = parseInt(cart?.items?.total_USD) !== 0;
  const parsedShippingArs = parseInt(cart?.items?.shipping_cost_ARP) !== 0;
  const parsedShippingUsd = parseInt(cart?.items?.shipping_cost_USD) !== 0;

  return (
    <Menu
      placement="bottom-end"
      isLazy
      isOpen={isOpen}
      closeOnBlur={true}
      onClose={() => setIsOpen(!isOpen)}
    >
      {({ onClose }) => (
        <>
          <ErrorModal
            isOpen={isOpenErrorModal}
            onClose={onCloseErrorModal}
            codeError={cartResult}
            customOnCloseComplete={() => getCart()}
            customComponent={
              <>
                <H5>Algo salió mal</H5>
              </>
            }
          />
          <MenuButton
            onClick={() => {
              setCartState(!cartState);
              setIsOpen(!isOpen);
            }}
            as={Button}
            variant={"ghost"}
            colorScheme={"blackAlpha"}
            m={0}
            p={0}
            borderRadius={"12px"}
            _hover={{ bg: "brand.hover" }}
            _focus={{
              boxShadow:
                "0 0 1px 2px rgba(122, 57, 162, 1), 0 1px 1px rgba(122, 57, 162, 1)",
            }}
          >
            <Badge
              badgeContent={cartItems?.items && cartItems?.items.length}
              color="primary"
            >
              <Flex flexDir={"column"} alignItems="center">
                <BekeuIcon src={shopping} />
              </Flex>
            </Badge>
          </MenuButton>
          <MenuList
            borderRadius={12}
            boxShadow="lg"
            p={0}
            // onMouseLeave={() => setIsOpen(false)}
          >
            {cart.loading ? (
              <Flex justifyContent={"center"} alignItems="center" p="24px">
                <Spinner size={"sm"} />
              </Flex>
            ) : (
              <Flex flexDir={"column"} px={"24px"} pt={"24px"}>
                {cartItems?.items?.map((item, id) => {
                  return (
                    <MiniCartItem
                      key={id}
                      item={item}
                      history={history}
                      imageError={imageError}
                      setImageError={setImageError}
                      cart={cart}
                      handleRemoveCartItem={handleRemoveCartItem}
                      trash={trash}
                    />
                  );
                })}

                <Flex flexDir={"column"} mt={"6px"} w={"100%"}>
                  {parsedArs && (
                    <Flex justifyContent={"space-between"} mb={"6px"}>
                      <Text mr={"12px"}>Envío en ARS </Text>
                      {parsedShippingArs ? (
                        <Text color={"brand.primary"} fontWeight={600}>
                          ARS {cart?.items?.shipping_cost_ARP} + Imp
                        </Text>
                      ) : (
                        <Text color={"brand.success"} fontWeight={600}>
                          Envío gratis
                        </Text>
                      )}
                    </Flex>
                  )}
                  {parsedUsd && (
                    <Flex justifyContent={"space-between"} mb={"6px"}>
                      <Text mr={"12px"}>Envío en USD </Text>
                      {parsedShippingUsd ? (
                        <Text color={"brand.primary"} fontWeight={600}>
                          USD {cart?.items?.shipping_cost_USD} + Imp
                        </Text>
                      ) : (
                        <Text color={"brand.success"}>Envío gratis</Text>
                      )}
                    </Flex>
                  )}
                  {parsedArs && (
                    <Flex justifyContent={"space-between"} mb={"6px"}>
                      <Text mr={"12px"}>Total en ARS </Text>
                      <Text color={"brand.primary"} fontWeight={600}>
                        {cart?.items?.total_w_shipping_ARP &&
                          `ARS ${cart?.items?.total_w_shipping_ARP}`}{" "}
                        + Imp
                      </Text>
                    </Flex>
                  )}
                  {parsedUsd && (
                    <Flex justifyContent={"space-between"} mb={"6px"}>
                      <Text mr={"12px"}>Total en USD </Text>
                      <Text color={"brand.primary"} fontWeight={600}>
                        {cart?.items?.total_w_shipping_USD &&
                          `USD ${cart?.items?.total_w_shipping_USD}`}{" "}
                        + Imp
                      </Text>
                    </Flex>
                  )}
                </Flex>
                {cartItems?.items && cartItems?.items.length !== 0 && (
                  <Link to={"/cart"}>
                    <ButtonBekeuM w={"100%"} onClick={onClose}>
                      Ver carrito
                    </ButtonBekeuM>
                  </Link>
                )}
              </Flex>
            )}
            {cartItems?.items &&
              cartItems?.items.length === 0 &&
              !cart.loading && (
                <Flex justifyContent={"center"} alignItems={"center"} pb="24px">
                  <Paragraphs>Tu carrito está vacío</Paragraphs>
                </Flex>
              )}
          </MenuList>
        </>
      )}
    </Menu>
  );
};

export default injectIntl(connect(null, cartActions)(Cart));
