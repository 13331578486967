import React, { useEffect } from "react";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import arrowRight from "../../design-system/icons/Arrows/arrow-right.svg";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";
import { BekeuIcon } from "../BekeuIcon/BekeuIcon";
import useQuery from "../../hooks/useQuery";

export const ProductsGridBreadcrumb = ({ product }) => {
  //hooks
  const history = useHistory();
  const mainState = useSelector((state) => state);
  const query = useQuery();

  //useState
  const [breadcrumbCategoryItems, setBreadcrumbCategoryItems] = useState([]);

  //localStorage
  const storageFiltersVar = localStorage.getItem("storageFilters");

  const storageFilters = {
    isFirstLoad: true,
    categories: {
      category_name: storageFiltersVar
        ? JSON.parse(storageFiltersVar).categories.category_name
        : [],
      category_id: storageFiltersVar
        ? JSON.parse(storageFiltersVar).categories.category_id
        : [],
    },
    brands: {
      brand_name: storageFiltersVar
        ? JSON.parse(storageFiltersVar).brands.brand_name
        : [],
      brand_id: storageFiltersVar
        ? JSON.parse(storageFiltersVar).brands.brand_id
        : [],
    },
    delivery_zones: {
      delivery_zone_name: storageFiltersVar
        ? JSON.parse(storageFiltersVar).delivery_zones.delivery_zone_name
        : [],
      delivery_zone_id: storageFiltersVar
        ? JSON.parse(storageFiltersVar).delivery_zones.delivery_zone_id
        : [],
    },
    price: storageFiltersVar ? JSON.parse(storageFiltersVar).price : [],
    delivery_days: storageFiltersVar
      ? JSON.parse(storageFiltersVar).delivery_days
      : [],
  };

  // usequery variables
  const lvl = query.get("lvl");

  //useEffect
  useEffect(() => {
    const categories = mainState?.categories?.categories?.filter((category) =>
      category?.sub_categories?.filter((cat) =>
        cat?.sub_category_items?.filter((c) => {
          if (c?.id === product?.id) {
            return setBreadcrumbCategoryItems([
              {
                id: 1,
                category_name:
                  cat?.parent_id_category === category?.id && category?.name,
                category_id:
                  cat?.parent_id_category === category?.id && category?.id,
                level: category?.level,
                current: lvl === category?.level,
                renderItem: true,
              },
              {
                id: 2,
                category_name: c?.parent_id_category === cat?.id && cat?.name,
                category_id: c?.parent_id_category === cat?.id && cat?.id,
                level: cat?.level,
                current: lvl === cat?.level,
                renderItem: lvl !== category?.level,
              },
              {
                id: 3,
                category_name: c?.id === product?.id && c?.name,
                category_id: c?.id === product?.id && c?.id,
                level: c?.level,
                current: true,
                renderItem: !lvl,
              },
            ]);
          }
        })
      )
    );
  }, [mainState.categories.categories, product]);

  return (
    <Breadcrumb spacing="8px" separator={<BekeuIcon src={arrowRight} />}>
      {product &&
        breadcrumbCategoryItems?.map(
          (item, id) =>
            item.renderItem && (
              <BreadcrumbItem key={id} isCurrentPage={item?.current}>
                <BreadcrumbLink
                  onClick={() => {
                    history.push(
                      `/search/?page=1&per_page=20&category_id=${item.category_id}&lvl=${item.level}`
                    );
                    localStorage.setItem(
                      "storageFilters",
                      JSON.stringify(storageFilters)
                    );
                  }}
                  _hover={{ color: "brand.hover" }}
                  fontFamily={"Open Sans"}
                  fontWeight={400}
                  fontSize={"14px"}
                  title={`Ir a ${item?.category_name}`}
                  color={item?.current && "brand.primary"}
                  overflow={"hidden"}
                  whiteSpace={"nowrap"}
                  maxW={!item.current && "120px"}
                  textOverflow={"ellipsis"}
                >
                  {item?.category_name}
                </BreadcrumbLink>
              </BreadcrumbItem>
            )
        )}
    </Breadcrumb>
  );
};
