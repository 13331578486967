import axios from "axios";
import { urlsBase } from "../../config";

export const CART_URL = `${urlsBase.base_url}/api/v1/cart`;
export const ADDRESS_URL = `${urlsBase.base_url}/api/v1/addresses/region`;
export const COST_CENTER_URL = `${urlsBase.base_url}/api/v1/companies/cost-centers`;
export const COMPANIES_DEMO = `${urlsBase.base_url}/api/v1/companies/client-companies`;

export function getCart() {
  return axios.get(CART_URL);
}

export function getAddress(cartItemId, idCompany) {
  return axios.get(`${ADDRESS_URL}/${cartItemId}/${idCompany}`);
}

export function getCostCenter(idCompany) {
  return axios.get(`${COST_CENTER_URL}/${idCompany}`);
}

export function getCompaniesDemo() {
  return axios.get(`${COMPANIES_DEMO}`);
}

export function addItemToCart({ item, quantity }) {
  return axios.put(`${CART_URL}/${item.sku}`, {
    country_id: item.country_id,
    currency_id: item.currency_id,
    provider: item.provider_id,
    quantity: quantity || 1,
    region_id: item.region_id,
  });
}

export function cartRemoveItem({ item }) {
  return axios.delete(`${CART_URL}/${item.cart_item_id}`, {
    provider: item.provider_id,
    currency_code: item.currency_code,
    region_id: item.region_id,
    value: item.value,
    country_id: item.country_id,
    image: item.image,
  });
}

export function endsBuy({
  address,
  providerComments,
  approverComments,
  company,
}) {
  return axios.post(`${CART_URL}/endbuy`, {
    address,
    provider_comments: providerComments,
    approver_comments: approverComments,
    company,
  });
}

export function cartBuyNow({ item, quantity }) {
  return axios.put(`${CART_URL}/${item.sku}`, {
    provider: item.provider_id,
    currency_id: item.currency_id,
    region_id: item.region_id,
    value: item.value,
    country_id: item.country_id,
    quantity: quantity || 1,
    image_source: item.image_source,
  });
}

export function setCecoItem({ obj, product }) {
  return axios.post(`${CART_URL}/set-cost-center/${product.cart_item_id}`, {
    cost_center: obj.cost_center,
    position: obj.position,
    percentage: obj.percentage,
  });
}

export function deleteCecoItem({ obj, product }) {
  return axios.delete(`${CART_URL}/set-cost-center/${obj.id}`, {
    cost_center: obj.cost_center,
    position: obj.position,
    percentage: obj.percentage,
  });
}
