import {
  Flex,
  Img,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  OrderedList,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { ButtonBekeu } from "../../design-system/forms/ButtonBekeu";
import { H5 } from "../../design-system/headings/H5";
import success from "../../design-system/icons/Arrows/success-circle-fill.svg";
import { ButtonBekeuOutline } from "../../design-system/forms/ButtonBekeuOutline";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { ButtonBekeuLink } from "../../design-system/forms/ButtonBekeuLink";
import { Paragraphs } from "../../design-system/headings/Paragraphs";
import errorCircle from "../../design-system/icons/Errors/error-circle.svg";
import FormUploadDispatchNote from "./FormUploadDispatchNote";

const Confirmation = ({ 
  onClose, 
  handleConfirm, 
  loading, 
  selectedFileName, 
  setFileNameInput, 
  fileNameInput,
  sapAvailable,
  dispatchNoteAvailable
}) => {
  console.log(sapAvailable, dispatchNoteAvailable);
  let disabledConfirmButton = false;
  if(sapAvailable && dispatchNoteAvailable){
    disabledConfirmButton = fileNameInput?.name?.length <= 0
  }
  return (
    <Flex flexDir={"column"} alignItems={"center"} p={"48px"}>
      <H5 textAlign="center" py={2}>
        ¿Estás seguro que querés confirmar la recepción de esta compra?
      </H5>

      {sapAvailable ?
        <>
        {dispatchNoteAvailable ?
          (<FormUploadDispatchNote
            setFileNameInput = {setFileNameInput}
            fileNameInput = {fileNameInput}
            sapAvailable={sapAvailable}
            dispatchNoteAvailable={dispatchNoteAvailable}
          />) 
          : 
          (null)
        }
        </>
        :
        null
      }      
  
      <ModalCloseButton _focus={{ boxShadow: "rgba(0,0,0,0)" }} />
      <Flex alignItems={"center"} width="100%" justifyContent={"space-between"}>
        <ButtonBekeuOutline mr={2} onClick={onClose}>
          No, cancelar
        </ButtonBekeuOutline>
        <ButtonBekeu ml={2} onClick={handleConfirm} isLoading={loading} isDisabled={disabledConfirmButton}>
          Si, confirmar
        </ButtonBekeu>
      </Flex>
    </Flex>
  )
}
const UpdateChanges = ({ onClose, handlePagination, setUpdate, mainState }) => {
  const orderHasErrors = mainState.carts.result?.error?.length === 0;
  return (
    <Flex flexDir={"column"} alignItems={"center"} p={"48px"}>
      <Img src={orderHasErrors ? success : errorCircle} />
      {orderHasErrors ? (
        <H5 textAlign="center" py={2}>
          ¡La recepción de tu compra fue realizada con éxito!
        </H5>
      ) : (
        <>
          <H5 textAlign="center" py={2}>
            Ocurrió un error al confirmar tu compra
          </H5>
          <OrderedList>
            {mainState.carts.result?.error?.map((item, id) => (
              <ListItem>{item}</ListItem>
            ))}
          </OrderedList>
        </>
      )}
      <ButtonBekeu
        w={"75%"}
        mt={4}
        onClose={onClose}
        onClick={() => {
          setUpdate(false);
          handlePagination();
        }}
      >
        Aceptar
      </ButtonBekeu>
    </Flex>
  );
};

export const MyOrdersConfirmRejectModal = ({
  isDisabled,
  children,
  handleConfirmOrderCart,
  handlePagination,
  orderItem,
  dispatchNoteNumber,
  setFileNameInput,
  fileNameInput,
  sapAvailable,
  dispatchNoteAvailable,
  ...props
}) => {
  const mainState = useSelector((state) => state);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [update, setUpdate] = useState(true);
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const handleConfirm = () => {
    setLoading(true);
    handleConfirmOrderCart(orderItem?.id, dispatchNoteNumber);
  };

  useEffect(() => {
    if (mainState.orders.result) {
      setUpdate(false);
      setLoading(false);
    }
  }, [mainState.orders.result]);

  return (
    <>
      <ButtonBekeuLink
        ml="12px"
        width={"100%"}
        onClick={onOpen}
        isDisabled={isDisabled}
        {...props}
      >
        {children}
      </ButtonBekeuLink>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        size="xl"
        onCloseComplete={() => {
          if (mainState.orders.result) {
            setUpdate(false);
            handlePagination();
          }
        }}
      >
        <ModalOverlay />
        <ModalContent borderRadius={"12px"}>
          <ModalCloseButton _focus={{ boxShadow: "rgba(0,0,0,0)" }} />
          <ModalBody>
            {update ? (
              <Confirmation
                onClose={onClose}
                handleConfirm={handleConfirm}
                setFileNameInput={setFileNameInput}
                fileNameInput={fileNameInput}
                history={history}
                loading={loading}
                sapAvailable={sapAvailable}
                dispatchNoteAvailable={dispatchNoteAvailable}
              />
            ) : (
              <UpdateChanges
                onClose={onClose}
                history={history}
                handlePagination={handlePagination}
                setFileNameInput={setFileNameInput}
                fileNameInput={fileNameInput}
                setUpdate={setUpdate}
                mainState={mainState}
              />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
