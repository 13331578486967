import { Button } from "@chakra-ui/react";
import React from "react";

export const ButtonBekeuOutline = (props) => {
  return (
    <Button
      w={"100%"}
      height={"48px"}
      variant={"outline"}
      bg={"brand.white"}
      colorScheme={"blackAlpha"}
      color={"brand.primary"}
      borderRadius={"12px"}
      borderWidth={"1px"}
      fontSize="1rem"
      fontWeight={600}
      _hover={{ bg: "brand.hover", color: "brand.white" }}
      _active={{ bg: "brand.hover", color: "brand.white" }}
      _focus={{
        boxShadow:
          "0 0 1px 1px rgba(122, 57, 162, 1), 0 1px 1px rgba(122, 57, 162, 1)",
      }}
      {...props}
    >
      {props.children}
    </Button>
  );
};
