import {
  Button,
  Divider,
  Flex,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Box,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import play from "../../design-system/icons/Essentials/play.svg";
import pause from "../../design-system/icons/Essentials/pause.svg";
import edit from "../../design-system/icons/Essentials/message-edit.svg";
import checkPrimary from "../../design-system/icons/Shipping/success-circle-primary.svg";
import checkSuccess from "../../design-system/icons/Shipping/success-circle.svg";
import checkDisabled from "../../design-system/icons/Shipping/success-circle-disabled.svg";
import { H5 } from "../../design-system/headings/H5";
import { Paragraphs } from "../../design-system/headings/Paragraphs";
import { ButtonBekeuOutline } from "../../design-system/forms/ButtonBekeuOutline";
import { ButtonBekeu } from "../../design-system/forms/ButtonBekeu";
import success from "../../design-system/icons/Arrows/success-circle-fill.svg";

import { BekeuIcon } from "../BekeuIcon/BekeuIcon";
import { InputBekeu } from "../../design-system/inputs/InputBekeu";
import { useSelector } from "react-redux";

const ShippingText = ({ children, ...props }) => {
  return (
    <Text
      fontWeight={400}
      fontSize={"14px"}
      isTruncated
      lineHeight={"19px"}
      fontStyle="normal"
      fontFamily={"Open Sans"}
      {...props}
    >
      {children}
    </Text>
  );
};

const ShippingButton = ({
  src,
  onClick,
  item,
  label = "",
  editState,
  ...props
}) => {
  return (
    <Flex cursor={"pointer"} overflow="hidden" justifyContent={"center"}>
      <Button
        variant={"link"}
        p={0}
        _focus={{
          boxShadow: "rgba(0, 0, 0, 0)",
        }}
        onClick={onClick}
        className="animate__animated animate__fadeIn"
        {...props}
      >
        {editState ? (
          <BekeuIcon src={src} width={"24px"} height={"24px"} title={label} />
        ) : (
          <BekeuIcon src={src} width={"24px"} height={"24px"} title="" />
        )}
      </Button>
    </Flex>
  );
};

export const ShippingItem = ({
  item,
  handlePauseActivate,
  listShipping,
  altKey,
  changeDeliveryTime,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [loading, setLoading] = useState(false);
  const [inputDisabled, setInputDisabled] = useState(true);
  const [inputValue, setInputValue] = useState(item?.delivery_time);
  const [shippingButtonLoading, setShippingButtonLoading] = useState(false);
  const [checkSuccessState, setCheckSuccessState] = useState(edit);

  const mainState = useSelector((state) => state);

  function handleChangeDeliveryTime() {
    setInputDisabled(!inputDisabled);
    if (!inputDisabled) {
      changeDeliveryTime({
        shipping_provider_id: item?.shipping_provider_id,
        delivery_time: inputValue,
      });
      setShippingButtonLoading(true);
    }
  }

  useEffect(() => {
    setInputValue(item?.delivery_time);
  }, [item]);

  useEffect(() => {
    if (mainState.shipping.deliveryTimeResult) {
      setInputDisabled(true);
      setShippingButtonLoading(false);
    }
  }, [mainState.shipping.deliveryTimeResult]);

  useEffect(() => {
    if (inputDisabled && !mainState?.shipping?.deliveryTimeResult) {
      setCheckSuccessState(edit);
    }

    if (!inputDisabled && !mainState?.shipping?.deliveryTimeResult) {
      setCheckSuccessState(checkPrimary);
    }
    if (mainState?.shipping?.deliveryTimeResult === altKey) {
      setCheckSuccessState(checkSuccess);
    }
    if (inputValue === "") {
      setCheckSuccessState(checkDisabled);
    }
  }, [
    inputValue,
    inputDisabled,
    mainState.shipping.deliveryTimeResult,
    altKey,
  ]);

  return (
    <>
      <Flex
        w="100%"
        py="16px"
        alignItems={"center"}
        justifyContent="center"
        gap="12px"
      >
        <Modal
          isOpen={isOpen}
          onClose={onClose}
          onCloseComplete={() => {
            setLoading(false);
            mainState?.shipping?.result && listShipping();
          }}
          size="xl"
          isCentered
        >
          <ModalOverlay />
          <ModalContent
            borderRadius="12px"
            fontFamily={"Open Sans"}
            flexDir={"row"}
          >
            <ModalCloseButton _focus={{ boxShadow: "rgba(0,0,0,0)" }} />
            {mainState?.shipping?.result ? (
              <ModalBody
                borderRadius={"12px"}
                m={"20px"}
                textAlign="center"
                p="24px"
              >
                <Flex flexDir={"column"} alignItems={"center"}>
                  <BekeuIcon src={success} mb={"24px"} />
                  <H5 mb={"24px"}>
                    El cambio de estado fue realizado exitosamente
                  </H5>
                  <ButtonBekeu
                    mr="12px"
                    onClick={onClose}
                    my={0}
                    px="70px"
                    w="fit-content"
                  >
                    Aceptar
                  </ButtonBekeu>
                </Flex>
              </ModalBody>
            ) : item?.state_shipping === 2 ? (
              <ModalBody
                borderRadius={"12px"}
                m={"20px"}
                textAlign="center"
                p="24px"
              >
                <H5 mb={"24px"}>
                  ¿Estás seguro que querés activar 
                  esta zona?
                </H5>
                <Paragraphs mb={"24px"} lineHeight="22px">
                  Al activar esta zona de entrega, todos tus productos estarán
                  disponibles para comprar en dicha zona.
                </Paragraphs>
                <Flex w="100%">
                  <ButtonBekeuOutline mr="12px" onClick={onClose}>
                    No, cancelar
                  </ButtonBekeuOutline>
                  <ButtonBekeu
                    my="0"
                    ml="12px"
                    onClick={() => {
                      handlePauseActivate(item?.region_id);
                      setLoading(true);
                    }}
                    isLoading={loading}
                  >
                    Si, confirmar
                  </ButtonBekeu>
                </Flex>
              </ModalBody>
            ) : (
              <ModalBody
                borderRadius={"12px"}
                m={"20px"}
                textAlign="center"
                p="24px"
              >
                <H5 mb={"24px"}>
                  ¿Estás seguro que querés pausar esta zona de entrega?
                </H5>
                <Paragraphs mb={"24px"} lineHeight="22px">
                  Al pausar esta zona de entrega todos tus productos no 
                  estarán disponibles para comprar con envió a dicha zona.
                </Paragraphs>
                <Flex w="100%">
                  <ButtonBekeuOutline mr="12px" onClick={onClose}>
                    No, cancelar
                  </ButtonBekeuOutline>
                  <ButtonBekeu
                    my="0"
                    ml="12px"
                    onClick={() => {
                      handlePauseActivate(item?.region_id);
                      setLoading(true);
                    }}
                    isLoading={loading}
                  >
                    Si, confirmar
                  </ButtonBekeu>
                </Flex>
              </ModalBody>
            )}
          </ModalContent>
        </Modal>
        <Box w={"8%"} textAlign={"center"}>
          <ShippingButton
            editState={true}
            label={item?.state_shipping === 2 ? "Activar" : "Pausar"}
            onClick={onOpen}
            src={item?.state_shipping === 2 ? play : pause}
          />
        </Box>
        <Box w={"10%"} textAlign={"center"} title={item?.region}>
          <ShippingText>{item?.region}</ShippingText>
        </Box>
        <Box w={"8%"} textAlign={"center"}>
          <ShippingText>{item?.country}</ShippingText>
        </Box>
        <Box w={"10%"}>
          <InputBekeu
            grouph="38px"
            maxLength={3}
            defaultValue={`${item?.delivery_time} días`}
            placeholder={`${item?.delivery_time} días`}
            value={inputDisabled ? `${inputValue} días` : inputValue}
            onChange={(e) => {
              setInputValue(e.target.value.replace(/[^0-9,.]/g, ""));
            }}
            pointerEvents
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleChangeDeliveryTime();
              }
            }}
            righticon={
              <ShippingButton
                editState={false}
                onClick={handleChangeDeliveryTime}
                isLoading={shippingButtonLoading}
                isDisabled={
                  inputValue === "" || mainState?.shipping?.deliveryTimeResult
                }
                src={checkSuccessState}
              />
            }
            isDisabled={inputDisabled}
          />
        </Box>
        {item?.price?.map(
          (price, id) =>
            price?.size !== 0 &&
            price?.cost !== "ARS 0" && (
              <Box w={"8%"} textAlign={"center"}>
                <ShippingText
                  key={id}
                  color={price?.cost === "ARS 0" ? "brand.success" : "black"}
                  fontWeight={price?.cost === "ARS 0" ? 600 : 400}
                >
                  {price?.cost === "ARS 0" && price?.size === 4
                    ? "Envío gratis"
                    : price?.cost !== "ARS 0" && price?.cost}
                </ShippingText>
              </Box>
            )
        )}
        <Box w={"56px"}></Box>
      </Flex>
      <Divider />
    </>
  );
};
