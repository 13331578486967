import { Flex, Divider } from "@chakra-ui/react";
import React from "react";
import { injectIntl } from "react-intl";
import { connect, useSelector } from "react-redux";
import { BekeuIcon } from "../../components/BekeuIcon/BekeuIcon";
import { H5 } from "../../design-system/headings/H5";
import { BoxBekeu } from "../../design-system/layout/BoxBekeu";
import { ButtonBekeuLink } from "../../design-system/forms/ButtonBekeuLink";
import * as auth from "./../../pages/login/ducks/auth.duck";
import profile from "../../design-system/icons/Profile/profile.svg";
import lock from "../../design-system/icons/Security/lock.svg";
import sms from "../../design-system/icons/Profile/sms.svg";
import money from "../../design-system/icons/Profile/money-recive.svg";
import approver from "../../design-system/icons/Profile/user-octagon.svg";
import { Paragraphs } from "../../design-system/headings/Paragraphs";
import { useHistory } from "react-router-dom";

export const ProfilePage = ({ ...props }) => {
  const mainState = useSelector((state) => state.auth);
  const history = useHistory();

  return (
    <Flex
      minH={"70vh"}
      flexDir={"column"}
      py={"40px"}
      bg={"brand.background"}
      alignItems={"center"}
      justifyContent="center"
      {...props}
    >
      <BoxBekeu width={"620px"} p={"50px"}>
        <H5 mb={2}>Mis datos</H5>
        <Flex alignItems={"center"} my={"10px"}>
          <BekeuIcon src={profile} mr={"10px"} />
          <Paragraphs>
            {mainState?.user?.name} {mainState?.user?.last_name}
          </Paragraphs>
        </Flex>
        <Flex alignItems={"center"} my={"10px"}>
          <BekeuIcon src={sms} mr={"10px"} />
          <Paragraphs>{mainState?.user?.email}</Paragraphs>
        </Flex>
        {mainState?.auth?.user?.client?.cost_center && (
          <Flex alignItems={"center"} my={"10px"}>
            <BekeuIcon src={money} mr={"10px"} />
            <Paragraphs>
              Imputaciones: {mainState?.auth?.user?.client?.cost_center}
            </Paragraphs>
          </Flex>
        )}
        {mainState?.user?.authorizer && (
          <Flex alignItems={"center"} my={"10px"}>
            <BekeuIcon src={approver} mr={"10px"} />
            <Paragraphs>
              Aprobador: {mainState?.user?.authorizer?.user?.email}
            </Paragraphs>{" "}
          </Flex>
        )}
        <Divider />
        <Flex alignItems={"center"} my={"10px"}>
          <BekeuIcon src={lock} mr={"10px"} color={"brand.primary"} />
          <Paragraphs>
            <ButtonBekeuLink
              onClick={() => history.push("/profile/change-password")}
            >
              Cambiar contraseña
            </ButtonBekeuLink>
          </Paragraphs>
        </Flex>
      </BoxBekeu>
    </Flex>
  );
};

export default injectIntl(connect(null, auth.actions)(ProfilePage));
