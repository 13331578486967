import React from "react";
import { Text } from "@chakra-ui/react";
import Checkbox from "react-custom-checkbox";
import bekeuCheckbox from "../../design-system/icons/Shapes/checkbox.svg";
import { BekeuIcon } from "../../components/BekeuIcon/BekeuIcon";

export const CheckboxBekeu = ({ children, ...props }) => {
  return (
    <Checkbox
      icon={
        <BekeuIcon
          src={bekeuCheckbox}
          width={"40px"}
          padding={0}
          style={{
            transform: "scale(1.25)",
            transition: "300ms ease all",
            userSelect: "none",
            userDrag: "none",
          }}
        />
      }
      borderWidth={"1px"}
      borderColor={"rgba(158, 158, 158, 1)"}
      borderRadius={"6px"}
      size={20}
      {...props}
      label={
        <Text
          fontFamily={"Open sans"}
          fontWeight={300}
          fontStyle={"normal"}
          fontSize={"0.875rem"}
          lineHeight={"1.188rem"}
          color={"black"}
          m={"10px 8px"}
          cursor="pointer"
          {...props}
        >
          {children}
        </Text>
      }
    />
  );
};
