import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";

export const MaxDeliveryTimeBadge = ({ days, ...props }) => {
  return (
    <Box
      color={"brand.contentSecondary"}
      fontSize="16px"
      lineHeight={"22px"}
      padding="4px"
      px="8px"
      borderRadius={"12px"}
      backgroundColor="#F5F5F5"
      w="fit-content"
      {...props}
    >
      <Flex>
        <Text fontWeight={600} mr={2}>
          Plazo máximo de entrega:
        </Text>
        <Text fontWeight={400}>{days} días hábiles</Text>
      </Flex>
    </Box>
  );
};
