import { Box } from "@chakra-ui/react";
import React from "react";

export const BoxBekeu = ({ children, nullClassName, ...props }) => (
  <Box
    p={"24px"}
    bg={"white"}
    borderRadius={"12px"}
    overflow="hidden"
    boxShadow={"lg"}
    {...props}
  >
    {children}
  </Box>
);
