import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import {
  getCart,
  getAddress,
  addItemToCart,
  cartRemoveItem,
  endsBuy,
  cartBuyNow,
  getCostCenter,
  setCecoItem,
  getCompaniesDemo,
  deleteCecoItem,
} from "../../services/cart.crud";

export const actionTypes = {
  CART_GET: "CART_GET",
  ADDRESS_GET: "ADDRESS_GET",
  FILL_ADDRESS: "FILL_ADDRESS",
  CART_RESULT: "CART_RESULT",
  CART_GET_FILL: "CART_GET_FILL",
  CART_ADD_ITEM: "CART_ADD_ITEM",
  CART_REMOVE_ITEM: "CART_REMOVE_ITEM",
  ENDS_BUY: "ENDS_BUY",
  ENDS_BUY_RESULT: "ENDS_BUY_RESULT",
  ENDS_BUY_RESULT_VALUE: "ENDS_BUY_RESULT_VALUE",
  CART_BUY_NOW: "CART_BUY_NOW",
  FILL_ITEM_LOADING: "FILL_ITEM_LOADING",
  GET_COST_CENTER: "GET_COST_CENTER",
  FILL_COST_CENTER: "FILL_COST_CENTER",
  SET_CECO_ITEM: "SET_CECO_ITEM",
  DELETE_CECO_ITEM: "DELETE_CECO_ITEM",
  GET_COMPANIES_DEMO: "GET_COMPANIES_DEMO",
  FILL_COMPANIES_DEMO: "FILL_COMPANIES_DEMO",
};

const initialState = {
  loading: false,
  itemLoading: false,
  result: null,
  items: [],
  address: undefined,
  endsBuyResult: undefined,
  endsBuyResultValue: {},
  costCenter: [],
  loadingCostCenter: false,
  companiesDemo: [],
};

export const reducer = persistReducer(
  { storage, key: "cart", whitelist: ["cart"] },
  (state = initialState, action) => {
    switch (action.type) {
      case actionTypes.CART_GET: {
        return {
          ...state,
          loading: true,
        };
      }
      case actionTypes.ADDRESS_GET: {
        return {
          ...state,
          // loading: true,
        };
      }
      case actionTypes.FILL_ADDRESS: {
        return {
          ...state,
          address: action.payload,
        };
      }
      case actionTypes.CART_RESULT: {
        return {
          ...state,
          result: action.payload,
        };
      }
      case actionTypes.ENDS_BUY_RESULT: {
        return {
          ...state,
          endsBuyResult: action.payload,
        };
      }
      case actionTypes.ENDS_BUY_RESULT_VALUE: {
        return {
          ...state,
          endsBuyResultValue: action.payload,
        };
      }
      case actionTypes.CART_GET_FILL: {
        return {
          ...state,
          loading: false,
          items: action.payload,
        };
      }
      case actionTypes.CART_ADD_ITEM: {
        return {
          ...state,
          result: false,
          itemLoading: true,
        };
      }
      case actionTypes.CART_REMOVE_ITEM: {
        return {
          ...state,
          itemLoading: true,
        };
      }
      case actionTypes.ENDS_BUY: {
        return {
          ...state,
          loading: true,
        };
      }
      case actionTypes.CART_BUY_NOW: {
        return {
          ...state,
          result: false,
        };
      }
      case actionTypes.FILL_ITEM_LOADING: {
        return {
          ...state,
          itemLoading: action.payload,
        };
      }

      case actionTypes.GET_COST_CENTER: {
        return {
          ...state,
          loadingCostCenter: true,
        };
      }

      case actionTypes.FILL_COST_CENTER: {
        return {
          ...state,
          costCenter: action.payload,
        };
      }
      case actionTypes.SET_CECO_ITEM: {
        return {
          ...state,
          loadingCecoItem: true,
        };
      }

      case actionTypes.DELETE_CECO_ITEM: {
        return {
          ...state,
          // loading: true,
        };
      }

      case actionTypes.GET_COMPANIES_DEMO: {
        return {
          ...state,
        };
      }
      case actionTypes.FILL_COMPANIES_DEMO: {
        return {
          ...state,
          companiesDemo: action.payload,
        };
      }
      default:
        return state;
    }
  }
);

export const actions = {
  getCart: () => ({ type: actionTypes.CART_GET, payload: null }),
  getAddress: (cartItemId, idCompany) => ({
    type: actionTypes.ADDRESS_GET,
    payload: { cartItemId: cartItemId, idCompany: idCompany },
  }),
  result: (value) => ({ type: actionTypes.CART_RESULT, payload: value }),
  endsBuyResult: (value) => ({
    type: actionTypes.ENDS_BUY_RESULT,
    payload: value,
  }),
  endsBuyResultValue: (value) => ({
    type: actionTypes.ENDS_BUY_RESULT_VALUE,
    payload: value,
  }),
  fillCart: (items) => ({ type: actionTypes.CART_GET_FILL, payload: items }),
  fillAddress: (address) => ({
    type: actionTypes.FILL_ADDRESS,
    payload: address,
  }),
  addItemToCart: (item, quantity) => ({
    type: actionTypes.CART_ADD_ITEM,
    payload: { item, quantity },
  }),
  removeCartItem: (item) => ({
    type: actionTypes.CART_REMOVE_ITEM,
    payload: { item },
  }),
  endsBuy: (data) => ({ type: actionTypes.ENDS_BUY, payload: data }),
  cartBuyNow: (item, quantity) => ({
    type: actionTypes.CART_BUY_NOW,
    payload: { item, quantity },
  }),
  fillItemLoading: (value) => ({
    type: actionTypes.FILL_ITEM_LOADING,
    payload: value,
  }),
  getCostCenter: (companyId) => ({
    type: actionTypes.GET_COST_CENTER,
    payload: companyId,
  }),
  fillCostCenter: (value) => ({
    type: actionTypes.FILL_COST_CENTER,
    payload: value,
  }),
  setCecoItem: (obj, product) => ({
    type: actionTypes.SET_CECO_ITEM,
    payload: { obj, product },
  }),
  getCompaniesDemo: () => ({
    type: actionTypes.GET_COMPANIES_DEMO,
    payload: null,
  }),
  fillCompaniesDemo: (value) => ({
    type: actionTypes.FILL_COMPANIES_DEMO,
    payload: value,
  }),
  deleteCecoItem: (obj, product) => ({
    type: actionTypes.DELETE_CECO_ITEM,
    payload: { obj, product },
  }),
};

export const getState = (state) => state;

export function* saga() {
  yield takeLatest(actionTypes.CART_GET, function* getCartSaga() {
    try {
      const { data } = yield getCart();
      if (data.code === 200) {
        yield put(actions.fillItemLoading(false));
        yield put(actions.fillCart(data.message));
        yield put(actions.result(false));
      } else {
        yield put(actions.fillCart(null));
        yield put(actions.result(false));
      }
    } catch (e) {
      yield put(actions.result("generalError"));
    }
  });

  yield takeLatest(actionTypes.ADDRESS_GET, function* getAddressSaga({
    payload,
  }) {
    try {
      const { data } = yield getAddress(payload.cartItemId, payload.idCompany);
      if (data.code === 200) {
        yield put(actions.endsBuyResult(undefined));
        yield put(actions.fillAddress(data.message));
      } else {
        yield put(actions.fillAddress([]));
      }
    } catch (e) {
      yield put(actions.fillAddress([]));
    }
  });

  yield takeLatest(actionTypes.CART_ADD_ITEM, function* addItemToCartSaga({
    payload,
  }) {
    try {
      const { data } = yield addItemToCart(payload);
      if (data.code === 200) {
        yield put(actions.fillItemLoading(false));
        yield put(actions.getCart());
        yield put(actions.result(true));
      } else if (data.code === 500) {
        yield put(actions.result(`error ${data.error_code}`));
      } else {
        yield put(actions.result("error"));
      }
    } catch (e) {
      yield put(actions.result(`error`));
    }
  });

  yield takeLatest(actionTypes.CART_REMOVE_ITEM, function* cartRemoveItemSaga({
    payload,
  }) {
    try {
      const { data } = yield cartRemoveItem(payload);
      if (data.code === 204) {
        yield put(actions.fillItemLoading(false));
        yield put(actions.getCart());
        yield put(actions.result(true));
      } else {
        yield put(actions.getCart());
        yield put(actions.result(false));
      }
    } catch (e) {
      yield put(actions.getCart());
      yield put(actions.result(false));
    }
  });

  yield takeLatest(actionTypes.ENDS_BUY, function* endsBuySaga({ payload }) {
    try {
      const { data } = yield endsBuy(payload);
      yield put(actions.getCart());
      yield put(actions.endsBuyResult(true));
      yield put(actions.endsBuyResultValue(data));
    } catch (error) {
      // yield put(actions.getCart());
      console.log(error);
      yield put(
        actions.result({ type: "generalError", data: error.response.data })
      );
      yield put(actions.endsBuyResult(false));
    }
  });

  yield takeLatest(actionTypes.CART_BUY_NOW, function* cartBuyNowSaga({
    payload,
  }) {
    try {
      const { data } = yield cartBuyNow(payload);
      if (data.code === 200) {
        yield put(actions.getCart());
        yield put(actions.fillItemLoading(false));
        yield put(actions.result("buy-now"));
      } else if (data.code === 500) {
        yield put(actions.result(`error ${data.error_code}`));
      } else {
        yield put(actions.result("error"));
      }
    } catch (e) {
      yield put(actions.result("error"));
    }
  });

  yield takeLatest(actionTypes.GET_COST_CENTER, function* costCenterSaga({
    payload,
  }) {
    try {
      const { data, status } = yield getCostCenter(payload);

      if (status === 200) {
        yield put(actions.fillCostCenter(data.message));
      } else {
        yield put(actions.fillCostCenter([]));
      }
    } catch (e) {
      yield put(actions.fillCostCenter([]));
      yield put(actions.result("generalError"));
    }
  });

  yield takeLatest(actionTypes.SET_CECO_ITEM, function* setCecoItemSaga({
    payload,
  }) {
    try {
      const { data, status } = yield setCecoItem(payload);
      yield put(actions.getCart());
      yield put(actions.result(true));
    } catch (e) {
      yield put(actions.result(true));
    }
  });

  yield takeLatest(
    actionTypes.GET_COMPANIES_DEMO,
    function* getCompaniesDemoSaga() {
      try {
        const { status, data } = yield getCompaniesDemo();
        let result = data.message;
        if (status === 200) {
          yield put(actions.fillCompaniesDemo(result));
        } else {
          yield put(actions.fillCompaniesDemo([]));
        }
      } catch (e) {
        console.log(e.message);
      }
    }
  );

  yield takeLatest(actionTypes.DELETE_CECO_ITEM, function* deleteCecoItemSaga({
    payload,
  }) {
    try {
      const { data, status } = yield deleteCecoItem(payload);
      yield put(actions.getCart());
    } catch (e) {
      yield put(actions.result("generalError"));
    }
  });
}
