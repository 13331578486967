import React, { useEffect, useState } from "react";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Flex,
  MenuDivider,
  MenuGroup,
  Box,
  SimpleGrid,
  Img,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import arrow from "../../design-system/icons/Arrows/arrow-right.svg";
import { useHistory, useLocation } from "react-router-dom";
import { injectIntl } from "react-intl";
import { connect, useSelector } from "react-redux";
import * as categories from "../../pages/categoriesPage/ducks/categories.duck";
import arrowDown from "../../design-system/icons/Arrows/arrow-down.svg";
import { ButtonBekeuLink } from "../../design-system/forms/ButtonBekeuLink";
import { H5 } from "../../design-system/headings/H5";
import { BekeuIcon } from "../BekeuIcon/BekeuIcon";
import { Paragraphs } from "../../design-system/headings/Paragraphs";
import useQuery from "../../hooks/useQuery";
import { ErrorModal } from "../../components/ErrorModal/ErrorModal";

const MyQuotesMenuCategories = ({ children, ml, listCategories }) => {
  //hooks
  const query = useQuery();
  const mainState = useSelector((state) => state);
  const history = useHistory();
  const { pathname } = useLocation();
  const [openMenu, setOpenMenu] = useState(false);

  const {
    isOpen: isOpenErrorModal,
    onOpen: onOpenErrorModal,
    onClose: onCloseErrorModal,
  } = useDisclosure();

  const hasError = `${mainState?.catgories?.categories}`;

  //queries variables
  const d = query.get("d");
  const from = query.get("from");
  const to = query.get("to");
  const state = query.get("state");
  const currency_id = query.get("currency_id");
  const category_id = query.get("category_id");

  const providerType = mainState?.auth?.user?.roles[0]?.name;

  useEffect(() => {
    if (providerType === "provider") {
      listCategories(true);
    } else {
      listCategories();
    }
  }, [providerType]);

  useEffect(() => {
    hasError.includes("generalError") && onOpenErrorModal();
  }, [hasError, onOpenErrorModal]);

  let searchMenu = ``;

  if (from) {
    searchMenu += `&from=${from}`;
  }

  if (to) {
    searchMenu += `&to=${to}`;
  }

  if (d) {
    searchMenu += `&d=${d}`;
  }

  if (currency_id) {
    searchMenu += `&currency_id=${currency_id}`;
  }

  function handlePush(item, type, onClose) {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    if (category_id && !item.id) {
      searchMenu += `&category_id=${category_id}`;
    }

    type === "category_id" &&
      history.push(
        `${pathname}?state=${state}&page=1&per_page=20${searchMenu}&category_id=${item.id}`
      );

    onClose();
  }
  return (
    <Menu closeOnSelect={false}>
      {({ onClose }) => {
        const onMainClose = onClose;
        return (
          <>
            <MenuButton
              as={Button}
              bg={"white"}
              _focus={{
                color: "brand.primary",
                boxShadow:
                  "0 0 1px 1.5px rgba(122, 57, 162, 1), 0 1px 1px rgba(122, 57, 162, 1)",
              }}
              fontFamily={"Open Sans"}
              fontStyle={"normal"}
              fontWeight={300}
              textAlign={"start"}
              fontSize={"16px"}
              color={"brand.contentPrimary"}
              pl={"16px"}
              minWidth="fit-content"
              w={"100%"}
              height={"56px"}
              // overflow={"hidden"}
              borderRadius={"15px"}
              borderWidth={"1px"}
              borderColor={"brand.border"}
              rightIcon={<Img src={arrowDown} />}
            >
              {children}
            </MenuButton>
            <MenuList
              zIndex={999}
              borderRadius={12}
              boxShadow="lg"
              color={"black"}
              fontSize="16px"
              maxH="500px"
              overflowY="scroll"
              className="overflow-y"
              onMouseLeave={() => {
                !openMenu && onClose();
              }}
            >
              <ErrorModal
                isOpen={isOpenErrorModal}
                onClose={onCloseErrorModal}
                codeError={hasError}
              />
              {category_id && (
                <MenuItem px={"24px"} py={"12px"} as={Box}>
                  <Menu>
                    <MenuButton
                      w={"100%"}
                      textAlign={"start"}
                      pl={4}
                      h={"35px"}
                      fontSize={"16px"}
                      color={"brand.primary"}
                      fontWeight={600}
                      _hover={{
                        bg: "rgba(255, 0, 0, 0)",
                        color: "brand.hover",
                      }}
                      aria-expanded={false}
                      onClick={() => {
                        history.push(
                          `${pathname}?state=${state}&page=1&per_page=20${searchMenu}`
                        );
                        onClose();
                      }}
                    >
                      Limpiar categorias
                    </MenuButton>
                  </Menu>
                </MenuItem>
              )}

              {mainState?.categories?.categories.map((category) => (
                <MenuItem px={"24px"} py={"12px"} key={category.id} as={Box}>
                  <Menu placement="right-start" isLazy>
                    {({ onClose }) => (
                      <>
                        <MenuButton
                          w={"100%"}
                          textAlign={"start"}
                          pl={4}
                          h={"35px"}
                          fontSize={"16px"}
                          color={"black"}
                          fontWeight={400}
                          _hover={{
                            bg: "rgba(255, 0, 0, 0)",
                            color: "brand.hover",
                          }}
                          aria-expanded={false}
                        >
                          <Flex alignItems={"center"}>
                            <Paragraphs>{category.name}</Paragraphs>
                            <BekeuIcon src={arrow} ml={"10px"} />
                          </Flex>
                        </MenuButton>
                        <MenuList
                          borderRadius={12}
                          boxShadow="lg"
                          color={"black"}
                          fontSize="16px"
                          minW={"800px"}
                          pb={"20px"}
                          maxH="70vh"
                          overflowY="scroll"
                          className="overflow-y"
                          onMouseEnter={() => setOpenMenu(true)}
                          onMouseLeave={() => {
                            setOpenMenu(false);
                            onClose();
                          }}
                        >
                          <MenuGroup>
                            <Flex alignItems={"center"}>
                              <H5 px={"24px"} py={"12px"} isTruncated>
                                {category.name}
                              </H5>
                              <ButtonBekeuLink
                                ml={2}
                                onClick={() => {
                                  handlePush(category, "category_id", onMainClose);
                                }}
                              >
                                Ver todo
                              </ButtonBekeuLink>
                            </Flex>
                            <MenuDivider />
                            <SimpleGrid minChildWidth="200px" spacing={4}>
                              {category.sub_categories &&
                                category?.sub_categories
                                  ?.sort(
                                    (a, b) =>
                                      a?.sub_category_items?.length -
                                      b?.sub_category_items?.length
                                  )
                                  ?.map((cat, id) => (
                                    <Flex
                                      key={id}
                                      flexDir={"column"}
                                      textAlign={"left"}
                                      alignItems={"flex-start"}
                                      maxW={"95%"}
                                      isTruncated
                                    >
                                      <Button
                                        variant={"link"}
                                        mx={"24px"}
                                        py={"12px"}
                                        fontFamily={"Open Sans"}
                                        fontWeight={600}
                                        fontSize={"16px"}
                                        color="brand.contentPrimary"
                                        _hover={{
                                          borderBottomWidth: 0,
                                          color: "brand.hover",
                                        }}
                                        _focus={{
                                          boxShadow: "rgba(0, 0, 0, 0)",
                                        }}
                                        textAlign={"start"}
                                        onClick={() => {
                                          handlePush(
                                            cat,
                                            "category_id",
                                            onMainClose
                                          );
                                        }}
                                        isTruncated
                                      >
                                        <div style={{ width: "202px" }}>
                                          {" "}
                                          <Text textAlign={"left"} isTruncated>
                                            {cat.name}
                                          </Text>
                                        </div>
                                      </Button>

                                      {cat.sub_category_items ? (
                                        cat.sub_category_items.map((sub, id) =>
                                          id < 5 ? (
                                            <Button
                                              variant={"link"}
                                              mx={"24px"}
                                              py={"8px"}
                                              fontFamily={"Open Sans"}
                                              fontWeight={400}
                                              fontSize={"16px"}
                                              color="brand.contentSecondary"
                                              _hover={{
                                                borderBottomWidth: 0,
                                                color: "brand.hover",
                                              }}
                                              _focus={{
                                                boxShadow: "rgba(0, 0, 0, 0)",
                                                color: "brand.hover",
                                              }}
                                              textAlign={"start"}
                                              onClick={() => {
                                                handlePush(
                                                  sub,
                                                  "category_id",
                                                  onMainClose
                                                );
                                              }}
                                              key={id}
                                              isTruncated
                                            >
                                              <div style={{ width: "202px" }}>
                                                {" "}
                                                <Text
                                                  textAlign={"left"}
                                                  isTruncated
                                                  title={sub.name}
                                                >
                                                  {sub.name}
                                                </Text>
                                              </div>
                                            </Button>
                                          ) : (
                                            id === 5 && (
                                              <ButtonBekeuLink
                                                mx={"24px"}
                                                onClick={() => {
                                                  handlePush(
                                                    cat,
                                                    "category_id",
                                                    onMainClose
                                                  );
                                                }}
                                                pb={"12px"}
                                                pt="6px"
                                              >
                                                Ver todo
                                              </ButtonBekeuLink>
                                            )
                                          )
                                        )
                                      ) : (
                                        <ButtonBekeuLink
                                          ml={2}
                                          mx={"24px"}
                                          onClick={() => {
                                            handlePush(
                                              cat,
                                              "category_id",
                                              onMainClose
                                            );
                                          }}
                                        >
                                          Ver todo
                                        </ButtonBekeuLink>
                                      )}
                                    </Flex>
                                  ))}
                            </SimpleGrid>
                          </MenuGroup>
                        </MenuList>
                      </>
                    )}
                  </Menu>
                </MenuItem>
              ))}
            </MenuList>
          </>
        );
      }}
    </Menu>
  );
};

export default injectIntl(
  connect(null, categories.actions)(MyQuotesMenuCategories)
);
