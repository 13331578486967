import React from "react";
import { Flex } from "@chakra-ui/react";
import { BekeuIcon } from "../../components/BekeuIcon/BekeuIcon";
import { ButtonBekeuLink } from "../../design-system/forms/ButtonBekeuLink";
import { useHistory } from "react-router-dom";
import { H5 } from "../../design-system/headings/H5";
import warning from "../../design-system/icons/Shapes/warning-2.svg";

export const NotFoundPage = () => {
  const history = useHistory();
  return (
    <Flex bg={"brand.background"} justifyContent="center">
      <Flex flexDir="column" alignItems={"center"}>
        <BekeuIcon src={warning} w={"75px"} mb={4} />
        {history.location.pathname.includes("/error/not-found") ? (
          <H5 mb={2}>Parece que este producto no se encuentra disponible</H5>
        ) : history.location.pathname.includes("/error/not-available") ? (
          <H5 mb={2}>El producto no se encuentra disponible</H5>
        ) : (
          <H5 mb={2}>Parece que esta página no existe</H5>
        )}
        <ButtonBekeuLink onClick={() => history.push("/")}>
          Volver al inicio
        </ButtonBekeuLink>
      </Flex>
    </Flex>
  );
};
