import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Img,
  SkeletonText,
  Text,
  useToast,
  useDisclosure,
} from "@chakra-ui/react";
import { ProductDetailModalCheckout } from "../ProductDetailModalCheckout/ProductDetailModalCheckout";
import { ProductDetailModalSeller } from "../ProductDetailModalSeller/ProductDetailModalSeller";
import { actions as cartActions } from "../../store/cart/cart.duck";
import { injectIntl } from "react-intl";
import { connect, useSelector } from "react-redux";
import { ButtonBekeuOutline } from "../../design-system/forms/ButtonBekeuOutline";
import { ButtonBekeu } from "../../design-system/forms/ButtonBekeu";
import { InputBekeu } from "../../design-system/inputs/InputBekeu";
import { useHistory } from "react-router-dom";
import tickCircle from "../../design-system/icons/Arrows/tick-circle.png";
import errCircle from "../../design-system/icons/Arrows/close-circle.svg";
import TagManager from "react-gtm-module";
import { BoxBekeu } from "../../design-system/layout/BoxBekeu";
import { ErrorModal } from "../ErrorModal/ErrorModal";
import { ShippingCostBadge } from "../ShippingCostBadge/ShippingCostBadge";
import useFormatCurrency from "../../hooks/useFormatCurrency";

const ProductDetailOrderCheckout = ({
  product,
  addItemToCart,
  provider,
  handleChangeDeliveryZone,
  selectedZone,
  skeletonLoading,
  cartBuyNow,
  ...props
}) => {
  //hooks
  const mainState = useSelector((state) => state);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const history = useHistory();
  const toast = useToast();

  //useState variables
  const [quantity, setQuantity] = useState(1);
  const [zones, setZones] = useState([]);
  const [buyNowResult, setBuyNowResult] = useState(false);
  const [buyNow, setBuyNow] = useState(false);
  const [result, setResult] = useState(false);
  const [quantityValidation, setQuantityValidation] = useState(false);
  const [cartLoading, setCartLoading] = useState(false);

  //variables
  const hasError = `${mainState.cart.result}`;
  // useEffect
  useEffect(() => {
    let keys = product && Object.keys(product?.prices);
    let zones = [];

    keys &&
      keys.forEach((key) => {
        for (let i = 0; i < product.prices[key].length; i++) {
          zones[product.prices[key][i].region_name] =
            product.prices[key][i].region_code;
        }
      });
    setZones(zones);
  }, [mainState.productsGrid.product]);

  useEffect(() => {
    if (mainState.cart.result === true) {
      handleScrollTo();
      setCartLoading(false);
      toast({
        title: "Producto agregado correctamente",
        position: "bottom-right",
        status: "success",
        duration: 2000,
        render: () => (
          <Box
            color="brand.primary"
            height={"48px"}
            p={3}
            bg="brand.acents"
            borderRadius={"2px"}
            borderTopLeftRadius={"12px"}
          >
            <Flex>
              <Img src={tickCircle} px={"8px"} />
              <Text px={"8px"}>Producto agregado correctamente</Text>
            </Flex>
          </Box>
        ),
      });
    } else if (hasError.includes("error")) {
      handleScrollTo();
      setCartLoading(false);
      onOpen();
    } else if (mainState.cart.result === "error") {
      handleScrollTo();
      setCartLoading(false);
      toast({
        title: "Error al agregar el producto",
        position: "bottom-right",
        status: "error",
        duration: 2000,
        render: () => (
          <Box
            color="brand.primary"
            height={"48px"}
            p={3}
            bg="#F44336"
            borderRadius={"2px"}
            borderTopLeftRadius={"12px"}
          >
            <Flex>
              <Img src={errCircle} px={"8px"} filter={"invert(100%)"} />
              <Text px={"8px"} color="white">
                Error al agregar el producto
              </Text>
            </Flex>
          </Box>
        ),
      });
    }
  }, [mainState.cart.result]);

  useEffect(() => {
    if (mainState.cart.result === true && buyNowResult === true) {
      history.push("/cart");
      setBuyNow(!buyNow);
    }
  }, [mainState.cart.result]);

  useEffect(() => {
    buyNowResult &&
      buyNow &&
      mainState.cart.result &&
      !hasError.includes("error") &&
      history.push("/cart");

    mainState.cart.result &&
      hasError.includes("error") &&
      setBuyNowResult(false);

    mainState.cart.result && hasError.includes("error") && setBuyNow(false);
  }, [mainState.cart.result]);

  useEffect(() => {
    mainState.cart.result && setResult(false);
  }, [mainState.cart.result]);

  // functions
  function handleQuantityChange(e) {
    if (
      e.target.value === "0" ||
      e.target.value === "" ||
      e.target.value === /^[0-9]*$/
    ) {
      setQuantityValidation(true);
    } else {
      setQuantityValidation(false);
    }
    setQuantity(e.target.value.replace(/[^0-9]/, ""));
  }

  function handleCartAddition() {
    setCartLoading(true);
    setResult(true);
    addItemToCart(product.prices[selectedZone][0], quantity);
    setQuantity(1);
    TagManager.dataLayer({
      dataLayer: { event: "orderType", path: "cartAddition" },
    });
  }

  function handleBuyNow() {
    TagManager.dataLayer({
      dataLayer: { event: "orderType", path: "buyNow" },
    });
    setBuyNow(true);
    setBuyNowResult(!buyNowResult);
    cartBuyNow(product.prices[selectedZone][0], quantity);
    if (hasError.includes("error")) {
      buyNowResult && buyNow && onOpen();
    }
    setBuyNowResult(!buyNowResult);
  }

  function handleScrollTo() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }

  const { ARS, USD } = useFormatCurrency();

  return (
    <BoxBekeu bg={"white"} minHeight="520px" {...props}>
      <ErrorModal isOpen={isOpen} onClose={onClose} codeError={hasError} />
      {skeletonLoading ? (
        <Flex flexDir={"column"} height="90%" justifyContent={"center"}>
          <SkeletonText mt="4" noOfLines={1} spacing="4" width={"40%"} />
          <SkeletonText noOfLines={1} spacing="4" width={"25%"} mt={"6"} />
          <SkeletonText noOfLines={1} spacing="4" width={"55%"} mt={"65px"} />
          <SkeletonText noOfLines={1} spacing="4" width={"20%"} mt={"8"} />
          <SkeletonText noOfLines={1} spacing="4" width={"25%"} mt={"4"} />
          <SkeletonText noOfLines={1} spacing="4" width={"100%"} mt={"65px"} />
          <SkeletonText noOfLines={1} spacing="4" width={"100%"} mt={"6"} />
        </Flex>
      ) : (
        <>
          <Flex flexDir={"column"} h={"100%"} justifyContent={"center"}>
            <Flex
              flexDir={"column"}
              justifyContent={"space-between"}
              h={"100%"}
            >
              <Flex flexDir={"column"}>
                <Text fontSize={"24px"} fontWeight={600} mb="24px">
                  {provider?.currency_code === "ARS" ? ARS.format(parseFloat(provider?.value)) : USD.format(parseFloat(provider?.value))}{" "} + Imp
                </Text>
                <Flex flexDir={"column"}>
                  <ProductDetailModalCheckout
                    deliveryZone={zones}
                    selectedZone={provider?.region_name}
                    handleChangeDeliveryZone={handleChangeDeliveryZone}
                  />
                  <ShippingCostBadge
                    currency={provider?.currency_code}
                    shippingCost={provider?.shipping_cost}
                    my="16px"
                  />
                  <Text>
                    Tiempo máximo de entrega{" "}
                    <strong> {provider?.delivery_time} días</strong>
                  </Text>
                </Flex>
                <Flex flexDir={"column"} mt="24px">
                  <Flex alignItems={"center"}>
                    <Text mr={2}>Vendido por</Text>
                    <ProductDetailModalSeller
                      sellerName={provider?.provider_fantasy_name}
                      email={provider?.provider_email}
                      phone={provider?.provider_phone}
                    />
                  </Flex>
                  {/*--------------- <POST MVP> ----------------*/}
                  {/*<Text>5.0 (10 opiniones)</Text>*/}
                  {/*--------------- </POST MVP> ----------------*/}
                </Flex>
              </Flex>
              <Flex flexDir={"column"}>
                <Text mb={"10px"}>Cantidad</Text>
                <InputBekeu
                  placeholder={1}
                  w={"110px"}
                  type="text"
                  value={quantity}
                  onChange={handleQuantityChange}
                />
              </Flex>
              <Flex flexDir={"column"} justifyContent="center">
                <ButtonBekeu
                  onClick={handleBuyNow}
                  isDisabled={quantityValidation}
                  my={0}
                  mb={"12px"}
                  isLoading={buyNowResult && buyNow}
                >
                  Comprar ahora
                </ButtonBekeu>
                <ButtonBekeuOutline
                  onClick={handleCartAddition}
                  isDisabled={quantityValidation}
                  isLoading={cartLoading}
                >
                  Agregar al carrito
                </ButtonBekeuOutline>
              </Flex>
            </Flex>
          </Flex>
        </>
      )}
    </BoxBekeu>
  );
};

export default injectIntl(
  connect(null, cartActions)(ProductDetailOrderCheckout)
);
